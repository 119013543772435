import { Padrao } from "../generic/padrao";

export class ImportacaoTipoDocumento extends Padrao{

    override idTab = 282;

    nome: string;
    sigla: string;
    ordem: string;
    rotulo: string;
    ativo: string;
    livre: string;
    fixo: string;
    calcCusto: string;
    rotuloCampo1: string;
    rotuloCampo2: string;
    rotuloCampo3: string;
    rotuloValor1: string;
    rotuloValor2: string;
    rotuloValor3: string;
    
}