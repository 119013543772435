import { ImportacaoInvoice } from './../../model/importacao/importacaoInvoice';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { Observable } from 'rxjs';
import { Arquivo } from 'src/app/model/importacao/arquivo';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoInvoiceService extends GenericService<ImportacaoInvoice>{

  constructor(http: HttpClient) {
    super(http);
    super.initResource("importacaoinvoice")
  }

}
