import { Component, Injector, OnInit } from '@angular/core';
import { Funcao } from 'src/app/model/geral/funcao';
import { FuncaoService } from 'src/app/services/geral/funcao.service';
import { GeralListaModule } from './gerallista.module';
import { ListaSimplesGenerica } from '../../generic/listagenerica/listasimplesgenerica';

@Component({
  selector: 'app-lista-funcao',
  templateUrl: '../../generic/listagenerica/listasimples.component.html',
  styleUrls: [ '../../generic/listagenerica/listasimples.component.css']
})

export class ListaFuncaoComponent extends ListaSimplesGenerica<Funcao> implements OnInit {

  override obj = new Funcao();

  constructor(
    injectorObj: Injector,
    api: FuncaoService) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override dataExtraList(res: any) {
}

  override getColorCell(valor: any) {   
  }

}
