import { ContasReceber } from './../../model/financeiro/contasReceber';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class ContasReceberService extends GenericService<ContasReceber>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("contasreceber")
  }
}
