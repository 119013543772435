import { Pessoa } from './../../model/pessoa/pessoa';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { DadosPesquisa } from 'src/app/model/system/dadosPesquisa';
import { PesDadosPj } from 'src/app/model/pessoa/pesDadosPj';
import { PesDadosPf } from 'src/app/model/pessoa/pesDadosPf';
import { Funcionario } from 'src/app/model/pessoa/funcionario';
import { GenericService } from '../generics/generic.service';
import { PessoaReceita } from 'src/app/model/pessoa/pessoReceita';
import { AutoRegisterService } from '../generics/servicesRegistry ';

@Injectable({
  providedIn: 'root'
})
@AutoRegisterService()
export class PessoaService extends GenericService<Pessoa> {

  constructor(http: HttpClient) {
    super(http);
    super.initResource("pessoa")
  }

  getHttpClient() {
      return this.http;
  }

  override insert(obj: Pessoa): Observable<Pessoa> {
    if (obj.tipo == 'J') {
      const urlLocal = `${this.url}/pj`;
      return this.http.post<PesDadosPj>(urlLocal, obj, this.httpOptions);
    }
    else {
      if (obj instanceof Funcionario) {
        const urlLocal = `${this.url}/funcionario`;
        return this.http.post<Funcionario>(urlLocal, obj, this.httpOptions);
      }
      else {
        const urlLocal = `${this.url}/pf`;
        return this.http.post<PesDadosPf>(urlLocal, obj, this.httpOptions);
      }
    }
  }

  override update(id: number, obj: Pessoa): Observable<Pessoa> {
    if (obj.tipo == 'J') {
      const urlLocal = `${this.url}/pj/${id}`;
      return this.http.put<PesDadosPj>(urlLocal, obj, this.httpOptions);
    }
    else {
      if (obj instanceof Funcionario) {
        const urlLocal = `${this.url}/funcionario/${id}`;
        return this.http.put<Funcionario>(urlLocal, obj, this.httpOptions);
      }
      else {
        const urlLocal = `${this.url}/pf/${id}`;
        return this.http.put<PesDadosPf>(urlLocal, obj, this.httpOptions);
      }
    }
  }

  consultarCnpj(cnpj: string): Observable<PessoaReceita> {
    const url = `${this.url}/api/cnpj/${cnpj}`;
    return this.http.get<PessoaReceita>(url);
  }

  // Método para consultar uma pessoa por ID
  findById(idEmpresa: number, id: number): Observable<Pessoa> {
    const url = `${this.url}/${idEmpresa}/${id}`;
    return this.http.get<Pessoa>(url, this.httpOptions);
  }

}    