import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formimportacao',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  
  constructor() {
  }

  ngOnInit(): void {
  }

}