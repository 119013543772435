export class DadosPesquisa {

    campo: string = '';
    valor: string = '';
    idCampoData: number = 0;
    campoData: string = '';
    dataInicial: Date = new Date();
    dataFinal: Date = new Date();
    camposFiltro: string[] = []
    valoresFiltro: string[] = []
}