<p-tree [value]="model" [filter]="true" selectionMode="single" [(selection)]="treeNodeSel">
    <ng-template pTemplate="header">
        <div class="grid">
            <div class="col-12" style="padding: 0%;">
                <div class="flex align-items-center h-3rem font-bold border-round m-0" [ngStyle]="{'backgroundColor':auth.getCorEmpresaSel()}">
                    <button pButton pRipple type="button" icon="pi pi-search" class="p-button-primary" style="margin-right: 5px; margin-left: 3px; height: 5px;" (click)="menuEmpresa.toggle($event)"></button>
                    {{ auth.empresa.nome }}
                </div>
            </div>
        </div>
        <h5>Menu</h5>
    </ng-template>
    <ng-template pTemplate="empty">
        {{ 'title_no_menu' | transloco }}
    </ng-template>
    <ng-template let-node pTemplate="url">
        <a (click)="updateIdMenu(node.data)">{{ constantsAero.PREFIX_MENU + node.data.menuAcesso.id | transloco }}</a>
    </ng-template>
    <!--<ng-template pTemplate="footer">
        <a href="https://www.infoaries.com.br">
            <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-aerosys-light' : 'logo-aerosys--dark'}}.png" alt="Aerosys"/>
        </a>
        </ng-template>-->
</p-tree>

<ul class="layout-menu">
    <ng-container *ngFor="let item of modelTemp; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
    <!--<li style="align-items: center;">
        <a href="https://www.infoaries.com.br">
            <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-aerosys-light' : 'logo-aerosys--dark'}}.png" alt="Aerosys"/>
        </a>
    </li>-->
</ul>

<p-overlayPanel #menuEmpresa [showCloseIcon]="true" [style]="{width: '75vw'}" [showCloseIcon]="false">
    <ng-template pTemplate>
        <p-table [value]="auth.listaEmpresa" [paginator]="true" [rows]="15" responsiveLayout="scroll" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th pSortableColumn="nome">{{constantsAero.PREFIX_FIELD + '2876' | transloco }}<p-sortIcon field="corWeb"></p-sortIcon></th>
                    <th pSortableColumn="id">{{constantsAero.PREFIX_FIELD + '10' | transloco }}<p-sortIcon field="id"></p-sortIcon></th>
                    <th pSortableColumn="nome">{{constantsAero.PREFIX_FIELD + '12' | transloco }}<p-sortIcon field="nome"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-empresa>
                <tr [pSelectableRow]="rowData">
                    <td style="min-width:15px; width:15px"><button pButton type="button" icon="pi pi-check" class="p-button-outlined p-button-success" (click)="mensEmpresaSelecionada(empresa); menuEmpresa.hide();"></button></td>
                    <td><!--<p-colorPicker [disabled]="true" [ngModel]="empresa.corWeb" (onChange)="updateCorEmpresa(empresa.id, empresa.corWeb, 614)"></p-colorPicker>--></td>
                    <td>{{empresa.id}}</td>
                    <td>{{empresa.nome}}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>


