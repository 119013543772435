<p-menubar>
    <ng-template pTemplate="start">
        <label class="fieldMandatory">
            {{'message_required_fields' | transloco }}
        </label>
    </ng-template>
    <ng-template pTemplate="end">
        <div class="flex">
            <button type="button" pButton (click)="save()" icon="pi pi-save"></button>
        </div>
    </ng-template>
</p-menubar>

<div class="grid p-fluid mt-2 pr-3 pl-1">
    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-12">
        <label for="inputDesc"> {{constantsAero.PREFIX_FIELD + '5920' | transloco }} </label>
        <textarea pInputTextarea id="inputDesc" name="inputDesc" [(ngModel)]="obj.descricao"
        maxlength="1024"></textarea>
    </div>
</div>

