export class Padrao {

    idTab: number = 0;

    id: number = 0;
    usuarioCad: number = 0;
    empresaCad: number = 0;
    dataCad: Date = new Date();

    listaItens: any[];
    listaItensExcluidos?: any[] = []; 

    isLoaded?: boolean;
}