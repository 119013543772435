import { ImportacaoStatus } from 'src/app/model/importacao/importacaoStatus';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { GenericService } from '../generics/generic.service';
import { ImportacaoHistStatus } from 'src/app/model/importacao/importacaoHistStatus';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoStatusService extends GenericService<ImportacaoStatus>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("importacaostatus")
  }

  findStatusPorResp(idCompany: number, idImp: number, resp: string): Observable<ImportacaoHistStatus[]> {
    const urlLocal = `${this.url}/resp/${idCompany}/${idImp}/${resp}`;
    return this.http.get<ImportacaoHistStatus[]>(urlLocal);
}

}
