import { Padrao } from "../generic/padrao";

export class ImportacaoStatus extends Padrao{

    override idTab = 286;

    nome: string;
    sigla: string;
    ordem: string;
    descricao: string;
    ativo: string;
    orientacoes: string;
    resp: string;
    icone: string;
}