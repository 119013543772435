import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { Observable } from 'rxjs';
import { ImpStatusImpTipoDoc } from 'src/app/model/importacao/impStatusImpTipoDoc';

@Injectable({
  providedIn: 'root'
})
export class ImpStatusImpTipoDocService extends GenericService<ImpStatusImpTipoDoc>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("impstatusimptipodoc")
  }

  findAllDocsByStatus(idStatus: number): Observable<ImpStatusImpTipoDoc[]> {
    const urlLocal = `${this.url}/tipodoc/${idStatus}`;
    return this.http.get<ImpStatusImpTipoDoc[]>(urlLocal);
  }

}
