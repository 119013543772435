import { Padrao } from "../generic/padrao";
import { AeroCategoria } from "./aeroCategoria";
import { ModeloAeronave } from "./modeloAeronave";

export class Aeronave extends Padrao{

    override idTab = 154;

    modeloAeronave: ModeloAeronave = new ModeloAeronave();
    aeroCategoria: AeroCategoria = new AeroCategoria();
    matricula: string;
    numSerie: string;
    anoFab: number;
    corPred: string;
    categHomo: string;
    categReg: string;
    tripMinAe: number;
    tripMinReg: number;
    maxAssentoPaxEa: number;
    maxAssentoEa: number;
    numAssentoIam: number;
    maxAssentoIam: number;
    pesoMax: number;
    tso: string;
    tsn: string;
    tsli: string;
    cso: string;
    csn: string;
    csli: string;
    numCa: string;
    validadeCa: Date;
    numLicEst: string;
    validadeLicEst: Date;
    rbhaOperacao: string;
    tipoVoo: string;
    tipoMotores: string;
    obs: string;
}