import { Component, Injector, OnInit } from '@angular/core';
import { ImportacaoListaModule } from './importacaolista.module';
import { ListaSimplesGenerica } from '../../generic/listagenerica/listasimplesgenerica';
import { ImportacaoInvoice } from 'src/app/model/importacao/importacaoInvoice';
import { ImportacaoInvoiceService } from 'src/app/services/importacao/importacao-invoice.service';

@Component({
  selector: 'app-lista-funcao',
  templateUrl: '../../generic/listagenerica/listasimples.component.html',
  styleUrls: [ '../../generic/listagenerica/listasimples.component.css']
})

export class ListaImportacaoInvoiceComponent extends ListaSimplesGenerica<ImportacaoInvoice> implements OnInit {

  override obj = new ImportacaoInvoice();

  constructor(
    injectorObj: Injector,
    api: ImportacaoInvoiceService) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  dataExtraList(res: any) {
  }

  override getColorCell(valor: any) {   
  }
  
}
