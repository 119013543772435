import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AcessosGrupo } from '../../model/security/acessosGrupo';
import { GenericService } from '../generics/generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AcessosGrupoService extends GenericService<AcessosGrupo>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("acessosgrupo")
  }

  findAllAcessosGrupo(idEmpresa: number, idGrupo: number): Observable<AcessosGrupo[]> {
    const urlLocal = `${this.url}/grupo/${idEmpresa}/${idGrupo}`;
    return this.http.get<AcessosGrupo[]>(urlLocal);
  }

  findByAcessosGrupoIdSysTabela(idEmpresa: number, idGrupo: number, idSysTabela: number): Observable<AcessosGrupo> {
    const urlLocal = `${this.url}/grupo/${idEmpresa}/${idGrupo}/${idSysTabela}`;
    return this.http.get<AcessosGrupo>(urlLocal);
  }

  findByAcessosGrupoIdMenu(idEmpresa: number, idGrupo: number, idMenu: number): Observable<AcessosGrupo> {
    const urlLocal = `${this.url}/menu/${idEmpresa}/${idGrupo}/${idMenu}`;
    return this.http.get<AcessosGrupo>(urlLocal);
  }

}
