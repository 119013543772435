import { ContasPagar } from '../financeiro/contasPagar';
import { ContasReceber } from '../financeiro/contasReceber';
import { Padrao } from '../generic/padrao';
import { Importacao } from './importacao';
import { ImportacaoTipoDocumento } from './importacaoTipoDocumento';

export class ImportacaoDocumento extends Padrao{

    override idTab = 280;

    importacao: Importacao = new Importacao();
    importacaoTipoDocumento: ImportacaoTipoDocumento = new ImportacaoTipoDocumento();
    contasPagar: ContasPagar | null;
    contasReceber: ContasReceber | null;
    numero: string;
    dataDocs: Date = new Date();
    descricao: string;
    tipo: string = 'D';
    campo1: string;
    campo2: string;
    campo3: string;
    valor1: number;
    valor2: number;
    valor3: number;
    valorFin: number;

    custoPerc: number = 0; // Apenas para cálculo do %
}