import { Padrao } from '../generic/padrao';

export class Manuais extends Padrao{

    override idTab = 122;

    nome: string;
    dataAtual: Date;
    local: string;
    link: string;
}
