import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, TreeNode } from 'primeng/api';
import { ConstantsAero } from '../demo/components/aerosys/generic/constantsAero';
import { Empresa } from '../model/empresa/empresa';
import { AcessosGrupo } from '../model/security/acessosGrupo';
import { AcessosGrupoService } from '../services/security/acessos-grupo.service';
import { AuthService } from '../services/security/auth.service';
import { LayoutService } from './service/app.layout.service';
import { AppConfig } from 'src/app.config';
import { EmpresaService } from '../services/empresa/empresa.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    protected constantsAero: ConstantsAero = new ConstantsAero();

    menuItens: AcessosGrupo[] = [];
    model: any[] = [];
    treeNodeSel: TreeNode;
    modelTemp: any[] = [];
    color: string;

    constructor(
        public layoutService: LayoutService,
        private router: Router,
        public auth: AuthService,
        private apiAcessosGrupoService: AcessosGrupoService,
        private http: HttpClient,
        private messageService: MessageService,
        private apiEmpresa: EmpresaService,
        private translocoService: TranslocoService
    ) { 
    }

    ngOnInit() {
        this.retornarTodasEmpresas(false);
        //this.criarMenuSuporte(); // provisório
    }

    retornarTodasEmpresas(primeiroAcesso: boolean) {
        const urlLocal = AppConfig.urlBasic + 'empresa/todas';
        this.http.get<Empresa[]>(urlLocal)
        .toPromise()
        .then((response: any) => {
            this.auth.listaEmpresa = response;
    
            this.auth.listaEmpresa.forEach(element => {
                element.corWeb = this.auth.getCorEmpresa(element);
                //console.log(`ID: ${element.id}, Empresa: ${element.nome}, Cor: ${element.corWeb}`);
            });
    
            // Exibe a lista completa de empresas, com id e cor
            //console.log(this.auth.listaEmpresa);
    
            this.selecionarEmpresa(primeiroAcesso);
            this.carregarMenuAcesso();
        });
    }    
  
    carregarMenuAcesso() {
        this.apiAcessosGrupoService.findAllAcessosGrupo(this.auth.empresa.id, this.auth.usuarioInfo.grupoId)
        .toPromise()
        .then((response: any) => {
          this.menuItens = response;
          //console.log(this.menuItens);
          
          this.separeListToTree();
          this.convertListToTree();
        });
    }
    
    private salvarEmpresa() {
    //localStorage.setItem('idEmpresa', this.empresa.id.toString()); //provisorio
    localStorage.setItem('idEmpresa', '1');
    //console.log(localStorage.getItem('idEmpresa'))
    }

    public selecionarEmpresa(primeiroAcesso: boolean) {
    if (this.auth.empresa.id == 0) {
        this.salvarEmpresa();
    }
    const idEmpresa = localStorage.getItem('idEmpresa');
    for (let e of this.auth.listaEmpresa) {
        if (e.id.toString() == idEmpresa) {
        this.auth.empresa = e;
        }
    }
    }
     
    mensEmpresaSelecionada(empresaSel: Empresa) {
        this.auth.empresa = empresaSel;
        this.messageService.add({severity: 'info', summary: 'Empresa Selecionada', detail: ''});
        this.carregarMenuAcesso()
        this.router.navigate(['/home']);
    }

    updateCorEmpresa(idEmpresa: number, cor: string, idRegra: number) {
        this.apiEmpresa.updateParcial(this.auth.empresa.id, idEmpresa, 'corWeb', cor.replace('#', ''), idRegra)
        .toPromise()
        .then(res => {
            //console.log('alterou cor');
            
          /*const message = this.translocoService.translate('message_update_sucess');
          this.messageService.add({severity:'success', summary: `${this.title} ${message}`, detail:''});*/
        })
    }
    
    convertListToTree() {
        //console.log(this.model);
        this.model = [];
        let indice;
        this.menuItens.forEach(item => {
            if (item.menuAcesso.nivel == 0) {
                this.model.push( 
                    {
                        key: item.menuAcesso.ordem,
                        label: item.menuAcesso.descricao, 
                        data: item,
                        children: [] = []
                    }
                )
                indice = this.model.length-1; 
            }
            else if (item.menuAcesso.nivel > 0) {
                //console.log(indice);
                this.model[indice].children.push(
                    {
                        key: item.menuAcesso.ordem,
                        label: item.menuAcesso.descricao, 
                        data: item,
                        type: 'url',
                        icon: 'pi pi-fw pi-list',
                    }
                )
            }
        });
    }

    separeListToTreeItems(itemMaster: AcessosGrupo) {
        this.menuItens.forEach(item => {
            if ((item.menuAcesso.nivel != 0) && (itemMaster.menuAcesso.ordem == item.menuAcesso.ordem.substr(0, 2))) {
                itemMaster.filhos.push(item);
            }
        });
    }

    separeListToTree() {
        //console.log(this.auth.menuItems)
        this.menuItens.forEach(item => {
            if (item.menuAcesso.nivel == 0) {
                item.filhos = [];
                this.separeListToMenuItems(item);
            }
        });
    }

    separeListToMenuItems(itemMaster: AcessosGrupo) {
        this.menuItens.forEach(item => {
            if ((item.menuAcesso.nivel != 0) && (itemMaster.menuAcesso.ordem == item.menuAcesso.ordem.substr(0, 2))) {
                itemMaster.filhos.push(item);
            }
        });
    }

    updateIdMenu(acessoGrupo: AcessosGrupo) {
        this.auth.menuItemSelecionado = acessoGrupo;
        //console.log(acessoGrupo);
        const recurso = this.auth.menuItemSelecionado.menuAcesso.sysTabelaPrincipal.nomeObj.toLowerCase();
        const linkLista = `/${this.auth.menuItemSelecionado.menuAcesso.moduloWeb}/listas/lista${recurso}`;
        //console.log(linkLista)
        this.router.navigate([linkLista]);
    }

    nodeSelect(event) {
        //console.log(this.treeNodeSel);
        this.updateIdMenu(this.treeNodeSel.data);
    }

    criarMenuSuporte() {
        this.modelTemp.push(
            {
                label: 'Suporte',
                items: [
                {
                    label: 'Home',
                    items: [
                        { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/dashboard'] }
                    ]
                },
                {
                    label: 'UI Components',
                    items: [
                        { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
                        { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
                        { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
                        { label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate'] },
                        { label: 'Button', icon: 'pi pi-fw pi-box', routerLink: ['/uikit/button'] },
                        { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
                        { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
                        { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
                        { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
                        { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
                        { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
                        { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
                        { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
                        { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
                        { label: 'Misc', icon: 'pi pi-fw pi-circle', routerLink: ['/uikit/misc'] }
                    ]
                },
                {
                    label: 'Prime Blocks',
                    items: [
                        { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/blocks'], badge: 'NEW' },
                        { label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: ['https://www.primefaces.org/primeblocks-ng'], target: '_blank' },
                    ]
                },
                {
                    label: 'Utilities',
                    items: [
                        { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['/utilities/icons'] },
                        { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank' },
                    ]
                },
                {
                    label: 'Pages',
                    icon: 'pi pi-fw pi-briefcase',
                    items: [
                        {
                            label: 'Landing',
                            icon: 'pi pi-fw pi-globe',
                            routerLink: ['/landing']
                        },
                        {
                            label: 'Auth',
                            icon: 'pi pi-fw pi-user',
                            items: [
                                {
                                    label: 'Login',
                                    icon: 'pi pi-fw pi-sign-in',
                                    routerLink: ['/auth/login']
                                },
                                {
                                    label: 'Error',
                                    icon: 'pi pi-fw pi-times-circle',
                                    routerLink: ['/auth/error']
                                },
                                {
                                    label: 'Access Denied',
                                    icon: 'pi pi-fw pi-lock',
                                    routerLink: ['/auth/access']
                                }
                            ]
                        },
                        {
                            label: 'Crud',
                            icon: 'pi pi-fw pi-pencil',
                            routerLink: ['/pages/crud']
                        },
                        {
                            label: 'Timeline',
                            icon: 'pi pi-fw pi-calendar',
                            routerLink: ['/pages/timeline']
                        },
                        {
                            label: 'Not Found',
                            icon: 'pi pi-fw pi-exclamation-circle',
                            routerLink: ['/notfound']
                        },
                        {
                            label: 'Empty',
                            icon: 'pi pi-fw pi-circle-off',
                            routerLink: ['/pages/empty']
                        },
                    ]
                },
                {
                    label: 'Hierarchy',
                    items: [
                        {
                            label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                {
                                    label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
                                        { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
                                        { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
                                    ]
                                },
                                {
                                    label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }
                                    ]
                                },
                            ]
                        },
                        {
                            label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                {
                                    label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
                                        { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
                                    ]
                                },
                                {
                                    label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
                                    ]
                                },
                            ]
                        }
                    ]
                },
                {
                    label: 'Get Started',
                    items: [
                        {
                            label: 'Documentation', icon: 'pi pi-fw pi-question', routerLink: ['/documentation']
                        },
                        {
                            label: 'View Source', icon: 'pi pi-fw pi-search', url: ['https://github.com/primefaces/sakai-ng'], target: '_blank'
                        }
                    ]
                }
            ]
        }
        );

    }

}
