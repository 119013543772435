import { ModeloEtiqueta } from './../../model/geral/modeloEtiqueta';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class ModeloEtiquetaService extends GenericService<ModeloEtiqueta>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("modeloetiqueta")
  }
}
