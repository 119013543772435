import { Component, OnInit, Injector } from '@angular/core';
import { FormGenerico } from '../../../generic/formgenerico/formgenerico';
import { ImportacaoStatusService } from 'src/app/services/importacao/importacao-status.service';
import { ImportacaoHistStatus } from 'src/app/model/importacao/importacaoHistStatus';
import { ImportacaoService } from 'src/app/services/importacao/importacao.service';
import { Importacao } from 'src/app/model/importacao/importacao';
import { ImpStatusImpTipoDocService } from 'src/app/services/importacao/imp-status-imp-tipo-doc';
import { ImportacaoDocumentoService } from 'src/app/services/importacao/importacao-documento.service';
import { ImpStatusImpTipoDoc } from 'src/app/model/importacao/impStatusImpTipoDoc';
import { MenuItem } from 'primeng/api';
import { FormImportacaoDocumentoComponent } from '../formimportacaodocumento/formimportacaodocumento.component';
import { ImportacaoHistStatusService } from 'src/app/services/importacao/importacao-hist-status.service';
import { FormImpHistStatusComponent } from '../formimphiststatus/formimphiststatus.component';
import { FormImportacaoHistoricoComponent } from '../formimportacaohistorico/formimportacaohistorico.component';
import { FormImportacaoHistoricoRespComponent } from '../formimportacaohistoricoresp/formimportacaohistoricoresp.component';
import { ImportacaoHistoricoService } from 'src/app/services/importacao/importacao-historico.service';
import { ImportacaoTimeLine } from 'src/app/model/importacao/importacaoTimeLine';
import { ListaArquivosComponent } from '../../../generic/listaarquivos/listaarquivos.component';
import { ImportacaoInvoiceService } from 'src/app/services/importacao/importacao-invoice.service';
import { ListaTraducaoComponent } from '../../listas/listatraducao/listatraducao.component';

@Component({
  selector: 'app-form-imp-timeline',
  templateUrl: './formimportacaotimeline.component.html',
  styleUrls: ['../../../generic/formgenerico/formgenerico.component.css']
})

export class FormImportacaoTimeLineComponent extends FormGenerico<Importacao> implements OnInit {

  idImportacao: number;

  objImpHistStatusSel: ImportacaoHistStatus;
  objImpStatusImpTipoDocSel: ImpStatusImpTipoDoc;

  displayDesc: boolean = false;
  displayOrie: boolean = false;
  tituloDesc: string = "";

  listaImpTimeLine: ImportacaoTimeLine[] = [];

  itemsFunctions: MenuItem[];
  itemsPrint: MenuItem[];
  itemsFunctionsDoc: MenuItem[];
  itemsFunctionsStatus: MenuItem[];

  qtdePerguntas: number = 0;
  qtdePerguntasNaoResp: number = 0;
  qtdeStatus: number = 0;
  qtdeStatusImp: number = 0;

  constructor(
    injectorObj: Injector,
    public api: ImportacaoService,
    public apiImpStatus: ImportacaoStatusService,
    public apiImportacaoDoc: ImportacaoDocumentoService,
    public apiImportacaoHistStatus: ImportacaoHistStatusService,
    public apiImpStatusImpTipoDocService: ImpStatusImpTipoDocService,
    public apiImportacaoHistorico: ImportacaoHistoricoService,
    public apiImportacaoInvoice: ImportacaoInvoiceService
  ) 
  {
    super(injectorObj, api);

    this.itemsFunctionsDoc = [
      { label: this.translocoService.translate('hint_button_view'), command: () => { this.showDocs('V', this.objImpStatusImpTipoDocSel, 614) } }, // Visualizar
      { separator: true },
      { label: this.translocoService.translate('button_insert'), command: () => { this.showDocs('I', this.objImpStatusImpTipoDocSel, 614) } }, // Inserir
      { label: this.translocoService.translate('hint_button_update'), command: () => { this.showDocs('A', this.objImpStatusImpTipoDocSel, 614) } }, // Alterar
    ];

    this.itemsFunctionsStatus = [
      { label: this.translocoService.translate('button_init'), command: () => { this.updateStatusEtapa('I', 614) } }, // Iniciar
      { separator: true },
      { label: this.translocoService.translate('button_finish'), command: () => { this.updateStatusEtapa('A', 614) } }, // Concluir
    ];

    this.itemsFunctions = [
      { label: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5716') ,
      items: [
        {label: 'Branco', command: () => { this.updateCanal('Branco'); } },
        {label: 'Cinza', command: () => { this.updateCanal('Cinza'); } },
        {label: 'Amarelo', command: () => { this.updateCanal('Amarelo'); } },
        {label: 'Verde', command: () => { this.updateCanal('Verde'); } },
        {label: 'Vermelho', command: () => { this.updateCanal('Vermelho'); } },
      ]},
      { separator: true },
    ];

    this.itemsPrint = [ 
      { label: this.translocoService.translate('translation'), command: () => { this.openTraducao() } },
    ]

    const alterar = this.translocoService.translate('hint_button_update');
    //if (this.acessosGrupo.alterar == 'Sim') {
      this.itemsFunctions.push({ label: alterar, command: () => { this.openForm('update'); } });
    //}

  }

  ngOnInit(): void {
    //this.loadDataPopup(); solução anterior através de popup
    this.loadDataQuery(); // através de página direta
  }

  dataExtraObj(res: any) {
  }

  loadDataQuery() {
    // se possivel, capture o parametro 
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['idImportacao']) {
        this.idImportacao = params['idImportacao'];
        this.loadImportacao();
      }
    })
  }

  loadImportacao() {
    this.api.findById(this.auth.empresa.id, this.idImportacao).toPromise()
    .then((res: any) => {
        this.obj = res;

        this.findItens();

        this.returnQtdeStatus();
        this.returnQtdeStatusPorImp();

        this.loadTimeline();

        this.returnQtdePerguntas();
        this.returnQtdePerguntasNaoResp();
    });
  }

  findItens() {
    this.api.findAllItens(this.auth.empresa.id, this.obj.id) 
      .toPromise()
      .then
      ((res: any) => {
        this.obj.listaItens = res;
      })
      .catch
      (( error ) => {
        this.errorHandler.handle(error);
      });
  }

  loadTimeline() {
    this.listaImpTimeLine = [];
    this.listaImpTimeLine.push(new ImportacaoTimeLine('Importador', this.auth.empresa, this.translocoService.translate('title_importer'), null));
    this.listaImpTimeLine.push(new ImportacaoTimeLine('Carga', this.obj.pessoaAgenteCarga, this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5780'), this.obj.pessoaAgenteCarga2));
    this.listaImpTimeLine.push(new ImportacaoTimeLine('Aduaneiro', this.obj.pessoaAgenteAduaneiro, this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5668'), null));
    this.listaImpTimeLine.push(new ImportacaoTimeLine('Alfandegado', this.obj.pessoaAgenteAlfandegado, this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5922'), null));

    this.loadTimelinePorResponsavel(this.listaImpTimeLine[0]);
    this.loadTimelinePorResponsavel(this.listaImpTimeLine[1]);
    this.loadTimelinePorResponsavel(this.listaImpTimeLine[2]);
    this.loadTimelinePorResponsavel(this.listaImpTimeLine[3]);
  }

  loadTimelinePorResponsavel(impTimeLine: ImportacaoTimeLine) {
      this.apiImpStatus.findStatusPorResp(this.auth.empresa.id, this.idImportacao, impTimeLine.responsavel)
        .toPromise()
        .then
        ((res: any) => {

          res.forEach(obj => {
            obj.listaItens = [];
            this.loadTipoDocs(obj);
          });
          impTimeLine.listaHistStatus = res;
          this.returnQtdeStatusPorResp(impTimeLine);
          this.returnQtdeStatusPorImpEResp(impTimeLine);          
        });
  }

  loadTipoDocs(obj: ImportacaoHistStatus) {
    return this.apiImpStatusImpTipoDocService.findAllDocsByStatus(obj.importacaoStatus.id).toPromise()
    .then((res: any) => {
      obj.listaItens = res;

      res.forEach(obj => {
        this.loadDocImportacao(obj);
      });      
    });
  }

  loadDocImportacao(obj: ImpStatusImpTipoDoc) {
    return this.apiImportacaoDoc.findImpDocPorIdTipoDoc(this.obj.id, obj.importacaoTipoDocumento.id).toPromise()
    .then((res: any) => {
      obj.importacaoDocumento = res;
    });
  }

  selectImpHistStatus(objSel) {
    this.objImpHistStatusSel = objSel;
    this.tituloDesc = this.objImpHistStatusSel.importacaoStatus.ordem + '.' + this.objImpHistStatusSel.importacaoStatus.nome
  }

  selectImpStatusImpTipoDoc(objSel) {
    this.objImpStatusImpTipoDocSel = objSel;
  }

  back() {
    this.location.back();
  }

  showDesc() {
    this.displayDesc = true;
  }

  showOrie() {
    this.displayOrie = true;
  }

  showDocs(acaoExec: string, objImpStatusImpTipoDoc: ImpStatusImpTipoDoc, idRegra: number) {
    let fn, idDoc, titulo;
    if (((acaoExec == 'I') && (!objImpStatusImpTipoDoc.importacaoDocumento)) || (((acaoExec == 'A') || (acaoExec == 'V') ) && (objImpStatusImpTipoDoc.importacaoDocumento))) {
      this.verificaPermissao(idRegra).then(res => {
        if (acaoExec == 'I') {
          idDoc = 0;
          titulo = ''
        }
        else {
          idDoc = objImpStatusImpTipoDoc.importacaoDocumento.id;
          titulo = objImpStatusImpTipoDoc.importacaoDocumento.importacaoTipoDocumento.nome + ' - ' + objImpStatusImpTipoDoc.importacaoDocumento.numero;
        }
        fn = () => this.loadDocImportacao(objImpStatusImpTipoDoc).then(res => {}); 
        this.showDialogDef(FormImportacaoDocumentoComponent, {tipo: objImpStatusImpTipoDoc.importacaoTipoDocumento.rotulo, acao: acaoExec, id: idDoc, idImportacao: this.obj.id, objImportacaoTipoDoc: this.objImpStatusImpTipoDocSel.importacaoTipoDocumento, fixo: 'NÃO' }, titulo, fn) 
      }).catch(err => {
        this.errorHandler.handle(this.translocoService.translate('message_process_failure'));
      });
    }
    else {
      if (acaoExec == 'I') {
          this.errorHandler.handle(this.translocoService.translate('message_doc_exists'));
      }
      else {
        this.errorHandler.handle(this.translocoService.translate('message_doc_not_exists'));
      }
    }
  }

  showPerguntas() {
    let fn = () => this.returnQtdePerguntasNaoResp();
    this.showDialogDef(FormImportacaoHistoricoComponent, {acao: 'P', id: 0, idImportacao: this.idImportacao, infoExtra: [this.idImportacao] }, 'Realizar Pergunta/Dúvida', fn);
  }

  showPergResp() {
    let fn = () => this.returnQtdePerguntasNaoResp();
    this.showDialogDef(FormImportacaoHistoricoRespComponent, {idImp: this.idImportacao }, 'Visualizar / Responder Perguntas', fn);
  }

  showListaArquivosInvoices(impInvoice) {
    let atualizarArq = false;
    let fn = undefined;
    this.showDialogDef(ListaArquivosComponent, {obj: impInvoice, api: this.apiImportacaoInvoice, atualizar: atualizarArq }, 'Arquivos Invoice ' + impInvoice.fiscalInvoice.numTitulo, fn);
    atualizarArq = true;
  }

  updateStatusEtapa(acao: string, idRegra: number) {
    let fn;
    if (((acao == 'I') && (!this.objImpHistStatusSel.id)) || ((acao == 'A') && (this.objImpHistStatusSel.id))) {
      this.verificaPermissao(idRegra).then(res => {
        if (res) {
          let id = 0;
          if (this.objImpHistStatusSel.id) {
            id = this.objImpHistStatusSel.id;
          }
          
          if (this.objImpHistStatusSel.importacaoStatus.resp == 'Importador')
            fn = () => this.loadTimelinePorResponsavel(this.listaImpTimeLine[0])
          else if (this.objImpHistStatusSel.importacaoStatus.resp == 'Carga')
            fn = () => this.loadTimelinePorResponsavel(this.listaImpTimeLine[1])
          else if (this.objImpHistStatusSel.importacaoStatus.resp == 'Aduaneiro')
            fn = () => this.loadTimelinePorResponsavel(this.listaImpTimeLine[2])
          else //if (this.objImpHistStatusSel.importacaoStatus.resp == 'Alfandegado')
            fn = () => this.loadTimelinePorResponsavel(this.listaImpTimeLine[3])
          
          let titulo = this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5924') + ' - ' + this.obj.numero;
          this.showDialogDef(FormImpHistStatusComponent, {'id': id, idImportacao: this.obj.id, idImportacaoStatus: this.objImpHistStatusSel.importacaoStatus.id}, titulo, fn);
        }
        else {
          this.errorHandler.handle(this.translocoService.translate('message_login_permission'));
        }
      }).catch(err => {
          this.errorHandler.handle(this.translocoService.translate('message_process_failure'));
      });
    }
    else {
      if (acao == 'I') {
        if (this.objImpHistStatusSel.id)
          this.errorHandler.handle(this.translocoService.translate('message_action_start_again'));
        else
          this.errorHandler.handle(this.translocoService.translate('message_action_finish'));
      }
      else {
        this.errorHandler.handle(this.translocoService.translate('message_action_start'));

      }
    }
  }

  updateCanal(canal: string) {
    this.updateParcial(this.api, this.obj, 'canal', canal, 628, 'Canal');
  }

  getColor(impHistStatus: ImportacaoHistStatus): string {
    //console.log(impHistStatus);
    
    if ((impHistStatus.id) && (impHistStatus.id > 0)) {
      if (impHistStatus.status == 'F')
        return 'var(--green-700)';
      else if (impHistStatus.status == 'P')
        return 'var(--yellow-500)';
      else 
        return 'var(--gray-600)';
    }
    else {
      return 'var(--gray-600)';
    }
  }

  getBkColor(impHistStatus: ImportacaoHistStatus): string {
    //console.log(impHistStatus);
    
    if ((impHistStatus.id) && (impHistStatus.id > 0)) {
      if (impHistStatus.status == 'F')
        return 'var(--green-300)';
      else if (impHistStatus.status == 'P')
        return 'var(--yellow-200)';
      else
        return 'var(--gray-200)';
    }
    else {
      return 'var(--gray-200)';
    }
  }

  returnQtdePerguntas() {
    return this.api.returnQtdePerguntas(this.auth.empresa.id, this.idImportacao).toPromise().then((res: any) => {this.qtdePerguntas = res;});
  }

  returnQtdePerguntasNaoResp() {
    return this.api.returnQtdePerguntasNaoResp(this.auth.empresa.id, this.idImportacao).toPromise().then((res: any) => {this.qtdePerguntasNaoResp = res;});
  }

  returnQtdeStatus() {
    return this.api.returnQtdeStatus(this.auth.empresa.id).toPromise().then((res: any) => {this.qtdeStatus = res;});
  }

  returnQtdeStatusPorImp() {
    return this.api.returnQtdeStatusPorImp(this.auth.empresa.id, this.idImportacao).toPromise().then((res: any) => {this.qtdeStatusImp = res;});
  }

  returnQtdeStatusPorResp(impTimeLine: ImportacaoTimeLine) {
    return this.api.returnQtdeStatusPorResp(this.auth.empresa.id, impTimeLine.responsavel).toPromise().then((res: any) => {impTimeLine.quantHistTot = res;});
  }

  returnQtdeStatusPorImpEResp(impTimeLine: ImportacaoTimeLine) {
    return this.api.returnQtdeStatusPorImpEResp(this.auth.empresa.id, impTimeLine.responsavel, this.idImportacao).toPromise().then((res: any) => {impTimeLine.quantHist = res;});
  }

  getStatusPercent(): number {
    return Math.round(this.qtdeStatusImp / this.qtdeStatus * 100);
  }

  getColorCanal(valor): string {
    if (valor == 'Verde')
        return 'var(--green-100)'
    else if (valor == 'Vermelho')
      return 'var(--red-100)'
    else if (valor == 'Cinza')
      return 'var(--gray-200)'
    else if (valor == 'Amarelo')
      return 'var(--yellow-100)'
    else
      return '';
  }  

  openForm(action) {
    let linkForm = `/importacao/forms/formimportacao`;
    this.router.navigate([`${linkForm}/`], { queryParams: { id: this.obj.id, action: action } });
  }

  openTraducao() {
    try {
      let listTraducao;
      (this.api as ImportacaoService).findTraducao(this.auth.empresa.id, this.obj.id).then(
        res => {
          listTraducao = res[0];
          let colsExt = res[1];
          this.showDialogDef(ListaTraducaoComponent, {list: listTraducao, cols: colsExt, id: this.obj.id }, this.translocoService.translate('value_translation') + ' - ' + this.obj.numero, undefined);
        }
      );
    } catch (error) {
      this.errorHandler.handle(error);
    }
  }


}