<div class="card p-1 m-0">

    <div class="grid m-0 p-0">
        <div class="col-12 m-0 p-0 field">
            <div class="block text-center h-3rem font-bold border-round m-0 p-2" [ngStyle]="{'backgroundColor':auth.getCorEmpresaSel()}">
                <h5>{{this.title | transloco }} - {{ obj.numero }}</h5>
            </div>
        </div>
    </div>

    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

    <p-menubar>
        <ng-template pTemplate="start">
            <label class="fieldMandatory">
                {{'message_required_fields' | transloco }}
            </label>
        </ng-template>
        <ng-template pTemplate="end">
            <button pButton type="button" icon="pi pi-save" title="{{action}}" class="buttonMargin"
            (click)="save()" *ngIf="paramAction == 'update' || paramAction == 'insert'"></button>
            <button pButton type="button" icon="pi pi-angle-double-left" title="{{'button_cancel' | transloco}}" class="buttonMargin"
            (click)="back()"></button>

            <p-tieredMenu  id="menuFunctions" #menuFunctions [popup]="true" [model]="itemsFunctions" appendTo = "body"></p-tieredMenu>
            <button type="button" pButton title="{{'button_functions' | transloco }}" icon="pi pi-fw pi-cog"   (click)="menuFunctions.toggle($event)" class="buttonMargin" style="float: right"></button>
        </ng-template>
    </p-menubar>
    <!-- Fim - Button de gerais do Formulario--> 

    <p-tabView>
        <!--Inicio - Primeiras informações da parte superior da tela de Importação-->
        <p-tabPanel header="{{ 'title_guia_import_data' | transloco }}">
            <div class="grid p-fluid mt-2 pr-3 pl-1 mb-2">
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputId"> {{ constantsAero.PREFIX_FIELD + '5667' | transloco }} </label>
                    <p-inputNumber id="inputId" name="inputId" [(ngModel)]="obj.id" [disabled]="true"></p-inputNumber>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputNumero"> {{constantsAero.PREFIX_FIELD + '5669' | transloco }} </label>
                    <input pInputText id="inputNumero" name="inputNumero" type="text" [(ngModel)]="obj.numero" [disabled]="true" maxlength="20">
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputStatus"> {{constantsAero.PREFIX_FIELD + '5670' | transloco }} </label>
                    <p-dropdown id="inputStatus" name="inputStatus" [options]="constantsAero.opItensStatusImpImportador" [(ngModel)]="obj.status" [disabled]="true"></p-dropdown>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputStatusCarga"> {{constantsAero.PREFIX_FIELD + '5845' | transloco }} </label>
                    <p-dropdown id="inputStatusCarga" name="inputStatusCarga" [options]="constantsAero.opItensStatusImpCarga" [(ngModel)]="obj.statusCarga" [disabled]="true"></p-dropdown>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputStatusAdua"> {{constantsAero.PREFIX_FIELD + '5846' | transloco }} </label>
                    <p-dropdown id="inputStatusAdua" name="inputStatusAdua" [options]="constantsAero.opItensStatusImpAduaneiro" [(ngModel)]="obj.statusAdua" [disabled]="true"></p-dropdown>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputCanal"> {{constantsAero.PREFIX_FIELD + '5716' | transloco }} </label>
                    <p-dropdown id="inputCanal" name="inputCanal" [options]="constantsAero.opItensCanais" [(ngModel)]="obj.canal" [disabled]="true"></p-dropdown>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputDataAB"> {{constantsAero.PREFIX_FIELD + '5671' | transloco }} </label>
                    <p-calendar id="inputDataAB" name="inputDataAB" 
                        [(ngModel)]="obj.dataAb" [showIcon]="true" [disabled]="true"
                        dateFormat="{{ 'date_short_format_form' | transloco }}"></p-calendar>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputDataFechamento"> {{constantsAero.PREFIX_FIELD + '5672' | transloco }} </label>
                    <p-calendar id="inputDataFechamento" name="inputDataFechamento" 
                        [(ngModel)]="obj.dataFech" [showIcon]="true" [disabled]="true"
                        dateFormat="{{ 'date_short_format_form' | transloco }}"></p-calendar>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputDataChegada"> {{constantsAero.PREFIX_FIELD + '5847' | transloco }} </label>
                    <p-calendar dateMask id="inputDataChegada" name="inputDataChegada" 
                        [(ngModel)]="obj.dataChegada" [showIcon]="true"
                        dateFormat="{{ 'date_short_format_form' | transloco }}"></p-calendar>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                    <label for="inputModal"> {{constantsAero.PREFIX_FIELD + '5838' | transloco }} </label>
                    <p-dropdown id="inputModal" name="inputModal" [options]="constantsAero.opItensModal" [(ngModel)]="obj.modal"></p-dropdown>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-6">
                    <app-lista-pesquisa [api]="apiPessoa" [obj]="pessoaTranspBr" [qtdProperties]="1" 
                    [properties]="['nome']" [labels]="['5844', '121']" [larguraCampos]="[3,9]"
                    [viewChek]="false" (atualizarObjPai)="atualizarPessoaTranspBr($event)">
                    </app-lista-pesquisa>
                </div>
                <div class="m-0 pb-0 pr-1 pt-3 field col-12 md:col-12">
                    <label for="inputObs"> {{constantsAero.PREFIX_FIELD + '5685' | transloco }} </label>
                    <textarea id="inputObs" name="inputObs" rows="3" pInputTextarea [(ngModel)]="obj.obs"></textarea>
                </div>
            </div>
            <!--Fim - Primeiras informações da parte superior da tela de Importação-->
        </p-tabPanel>

        <!--Inicio - Tabela e Seleção de invoices fiscais-->
        <p-tabPanel header="{{ constantsAero.PREFIX_TABLE + '281' | transloco }}">
            <p-table styleClass="p-datatable-striped p-datatable-sm" [value]="obj.listaItens" dataKey="id" editMode="row" 
            responsiveLayout="stack" [breakpoint]="'768px'">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between flex-wrap card-container">
                        <div class="flex align-items-center justify-content-center">
                            <button type="button" pButton pRipple pTooltip="Selecionar Invoices" (click)="findAllFiscalInvShowDialog()" icon="pi pi-plus" class="mr-2" ></button>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <label class="mr-1" for="inputValorUnitWr"> {{constantsAero.PREFIX_FIELD + '5684' | transloco }}:</label>
                            <p-inputNumber id="inputValorUnitWr" name="inputValorUnitWr" [(ngModel)]="obj.valorUnitWr"
                            mode="decimal" [minFractionDigits]="4" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}"></p-inputNumber>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3%;"></th>
                        <th style="width: 10%;">{{constantsAero.PREFIX_FIELD + '4113' | transloco }}</th>
                        <th style="width: 07%;">{{constantsAero.PREFIX_FIELD + '5824' | transloco }}</th>
                        <th style="width: 10%;">{{constantsAero.PREFIX_FIELD + '5825' | transloco }}</th>
                        <th style="width: 10%;">{{constantsAero.PREFIX_FIELD + '4119' | transloco }}</th>
                        <th style="width: 10%;">{{constantsAero.PREFIX_FIELD + '5447' | transloco }}</th>
                        <th style="width: 10%;">{{constantsAero.PREFIX_FIELD + '5701' | transloco }}</th>
                        <th style="width: 10%;">{{constantsAero.PREFIX_FIELD + '5702' | transloco }}</th>
                        <th style="width: 10%;">{{constantsAero.PREFIX_FIELD + '5703' | transloco }}</th>
                        <th style="width: 10%;">{{constantsAero.PREFIX_FIELD + '4118' | transloco }}</th>
                        <th style="width: 20%;">{{constantsAero.PREFIX_FIELD + '5704' | transloco }}</th>
                    </tr>
                </ng-template> 
                <ng-template pTemplate="body" let-impInvoice let-editing="editing" let-columns="columns" let-iLin="rowIndex">
                    <tr [pEditableRow]="impInvoice" [ngStyle]="{backgroundColor:'var(--gray-200)'}">
                        <td>
                            <div class="flex" #btnFuncInv>
                                <p-tieredMenu  id="menuFunctionsItemsInv" #menuFunctionsItemsInv [popup]="true" [model]="itemsFunctionsInvoice" appendTo = "body"></p-tieredMenu>
                                <button pButton type="button" icon="pi pi-cog" class="p-button-outlined buttonRow" (click)="selectImpInvoice(impInvoice, iLin); menuFunctionsItemsInv.toggle($event)"></button>
                            </div>     
                        </td>
                        <td>{{impInvoice.fiscalInvoice.numTitulo}}</td>
                        <td>{{impInvoice.fiscalInvoice.box}}</td>
                        <td>{{impInvoice.fiscalInvoice.info}}</td>
                        <td><span style="float: right;">{{ globalFunctions.getFormatNumber(impInvoice.fiscalInvoice.totFaturadoMoeda - impInvoice.fiscalInvoice.valorFreteInter) }}</span></td>
                        <td><span style="float: right;">{{ globalFunctions.getFormatNumber(impInvoice.fiscalInvoice.valorFreteInter) }}</span></td>
                        <td><input pInputText type="text" style="width: 8rem;" [(ngModel)]="impInvoice.wrNumero"></td>
                        <td><p-inputNumber [inputStyle]="{'width':'8rem'}" [(ngModel)]="impInvoice.wrPeso"   (onBlur)="handleOnInput($event)" (onInput)="handleOnInput($event)" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}"></p-inputNumber></td>
                        <td><span style="float: right;">{{ globalFunctions.getFormatNumber(obj.valorUnitWr * impInvoice.wrPeso) }}</span></td>
                        <td><span style="float: right;">{{ globalFunctions.getFormatNumber(impInvoice.fiscalInvoice.totFaturadoMoeda - impInvoice.fiscalInvoice.valorFreteInter + impInvoice.wrValor) }}</span></td>
                        <td><textarea rows="2" cols="35" pInputTextarea [(ngModel)]="impInvoice.observacao"></textarea>
                    </tr>
                    <tr>
                        <td colspan="11">
                            <app-lista-arquivos [obj]="impInvoice" [api]="apiImportacaoInvoice" [atualizar]="atualizarArqInv"></app-lista-arquivos>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="4"></td>
                        <td><span style="float: right">{{globalFunctions.getFormatNumber(totalImpInvoiceFat)}}</span></td>
                        <td><span style="float: right">{{globalFunctions.getFormatNumber(totalImpInvoiceAWB)}}</span></td>
                        <td></td>
                        <td><span style="float: right">{{globalFunctions.getFormatNumber(totalImpInvoicePesoWr)}}</span></td>
                        <td><span style="float: right">{{globalFunctions.getFormatNumber(totalImpInvoiceWr)}}</span></td>
                        <td><span style="float: right">{{globalFunctions.getFormatNumber(totalImpInvoiceFat - totalImpInvoiceAWB + totalImpInvoiceWr)}}</span></td>
                        <td></td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <!--Fim - Tabela e Seleção de invoices-->

        <!--Inicio - Primeiras informações da aba Agentes -->
        <p-tabPanel header="{{'title_guia_import_agents' | transloco }}">
            <div class="grid p-fluid mt-2 pr-3 pl-1">
                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-12">
                    <app-lista-pesquisa [api]="apiPessoa" [obj]="pessoaAgenteCarga" [qtdProperties]="2" 
                    [properties]="['nome', 'cpfCnpj']" [labels]="['5780', '121', '123']" [larguraCampos]="[2,6,4]"
                    [viewChek]="false" (atualizarObjPai)="atualizarPessoaAgenteCarga($event)">
                    </app-lista-pesquisa>
                </div>

                <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-12">
                    <app-lista-pesquisa [api]="apiPessoa" [obj]="pessoaAgenteCarga2" [qtdProperties]="2" 
                    [properties]="['nome', 'cpfCnpj']" [labels]="['5780', '121', '123']" [larguraCampos]="[2,6,4]"
                    [viewChek]="false" (atualizarObjPai)="atualizarPessoaAgenteCarga2($event)">
                    </app-lista-pesquisa>
                </div>

                <div class="m-0 pb-0 pr-1 pt-3 field col-12 md:col-12">
                    <app-lista-pesquisa [api]="apiPessoa" [obj]="pessoaAgenteAduaneiro" [qtdProperties]="2" 
                    [properties]="['nome', 'cpfCnpj']" [labels]="['5668', '121', '123']"  [larguraCampos]="[2,6,4]"
                    [viewChek]="false" (atualizarObjPai)="atualizarPessoaAgenteAduaneiro($event)">
                    </app-lista-pesquisa>
                </div>

                <div class="m-0 pb-0 pr-1 pt-3 field col-12 md:col-12">
                    <app-lista-pesquisa [api]="apiPessoa" [obj]="pessoaAgenteAlfandegado" [qtdProperties]="2" 
                    [properties]="['nome', 'cpfCnpj']" [labels]="['5922', '121', '123']"  [larguraCampos]="[2,6,4]"
                    [viewChek]="false" (atualizarObjPai)="atualizarPessoaAgenteAlfandegado($event)">
                    </app-lista-pesquisa>
                </div>
            </div>
        </p-tabPanel>
        <!--Fim - Primeiras informações da aba Agentes -->


        <!--Inicio - Aba Documentos-->  
        <p-tabPanel header="{{'title_guia_import_docs' | transloco }}">
            <p-table [value]="listaDocs" styleClass="p-datatable-striped p-datatable-sm"
            responsiveLayout="stack" [breakpoint]="'768px'" [resizableColumns]="true">
                <ng-template pTemplate="caption">
                    <div class="flex">
                        <button type="button" pButton pRipple (click)="showDocs('DOC', 'I')" icon="pi pi-fw pi-plus" class="mr-2" ></button>
                    </div>     
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>{{constantsAero.PREFIX_FIELD + '5691' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '5693' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '5694' | transloco }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-impDocs>
                    <tr>
                        <td>
                            <div class="flex" #btnFuncDoc>
                                <p-tieredMenu  id="menuFunctionsDoc" #menuFunctionsDoc [popup]="true" [model]="itemsFunctionsDoc" appendTo = "body"></p-tieredMenu>
                                <button pButton type="button" icon="pi pi-cog" class="p-button-outlined buttonRow" (click)="selectImpDoc(impDocs); menuFunctionsDoc.toggle($event)"></button>
                            </div>     
                        </td>
                        <td>{{impDocs.importacaoTipoDocumento.nome}}</td>
                        <td>{{impDocs.numero}}</td>
                        <td>{{impDocs.dataDocs | date: globalFunctions.getDataFormat() }}</td>    
                        <td>
                            <div *ngIf="impDocs.importacaoTipoDocumento.rotuloCampo1">
                                {{impDocs.importacaoTipoDocumento.rotuloCampo1}} <br>
                                {{impDocs.campo1}}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="impDocs.importacaoTipoDocumento.rotuloCampo2">
                                {{impDocs.importacaoTipoDocumento.rotuloCampo2}} <br>
                                {{impDocs.campo2}}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="impDocs.importacaoTipoDocumento.rotuloCampo3">
                                {{impDocs.importacaoTipoDocumento.rotuloCampo3}} <br>
                                {{impDocs.campo3}}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="impDocs.importacaoTipoDocumento.rotuloValor1">
                                {{impDocs.importacaoTipoDocumento.rotuloValor1}} <br>
                                {{ globalFunctions.getFormatNumber(impDocs.valor1) }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="impDocs.importacaoTipoDocumento.rotuloValor2">
                                {{impDocs.importacaoTipoDocumento.rotuloValor2}} <br>
                                {{ globalFunctions.getFormatNumber(impDocs.valor2) }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="impDocs.importacaoTipoDocumento.rotuloValor3">
                                {{impDocs.importacaoTipoDocumento.rotuloValor3}} <br>
                                {{ globalFunctions.getFormatNumber(impDocs.valor3) }}
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <!--Inicio - Aba Documentos-->

        <!--Inicio - Aba de Financeiro-->
        <p-tabPanel header="{{'title_guia_import_financial' | transloco }}">
            <p-table [value]="listaPgtos" styleClass="p-datatable-striped p-datatable-sm"
            responsiveLayout="stack" [breakpoint]="'768px'" [resizableColumns]="true">
                <ng-template pTemplate="caption">
                    <div class="grid">
                        <div class="col-1">
                            <button type="button" pButton pRipple (click)="showDocs('FIN', 'I')" icon="pi pi-fw pi-plus" class="mr-2" ></button>
                        </div>
                        <div class="col-4">
                            <span style="float: right">{{ 'total_invoice' | transloco }} {{ 'U$ ' + globalFunctions.getFormatNumber(totalImpInvoiceFat - totalImpInvoiceAWB)}}</span><br>
                            <span style="float: right">{{ 'total_invoice' | transloco }} {{ 'R$ ' + globalFunctions.getFormatNumber((totalImpInvoiceFat - totalImpInvoiceAWB) * cotacaoNfe)}}</span>
                        </div>
                        <div class="col-2">
                            <span style="float: right">{{ constantsAero.PREFIX_TABLE + '227' | transloco }} {{ ': R$ ' + globalFunctions.getFormatNumber(cotacaoNfe)}}</span>
                        </div>
                        <div class="col-3">
                            <span style="float: right">{{ 'total_nfe' | transloco }} {{ 'R$ ' + globalFunctions.getFormatNumber(valorNfe)}}</span>
                        </div>
                        <div class="col-1 flex align-items-center justify-content-center">
                            <p-knob [(ngModel)]="percCusto" valueTemplate="{{globalFunctions.getFormatNumber(percCusto,2)}}%" [size]="85" [readonly]="true" valueColor="var(--red-500, Black)"></p-knob>
                        </div>
                    </div>                    
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>{{constantsAero.PREFIX_FIELD + '5691' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '5693' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '5694' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '1638' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '5953' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '1637' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '1643' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '1757' | transloco }}</th>
                        <th>{{constantsAero.PREFIX_FIELD + '1640' | transloco }} R$</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-impDocs>
                    <tr>
                        <td>
                            <div class="flex" #btnFuncFin>
                                <p-tieredMenu  id="menuFunctionsFin" #menuFunctionsFin [popup]="true" [model]="itemsFunctionsFin" appendTo = "body"></p-tieredMenu>
                                <button pButton type="button" icon="pi pi-cog" class="p-button-outlined buttonRow" (click)="selectImpDoc(impDocs); menuFunctionsFin.toggle($event)"></button>
                            </div>     
                        </td>
                        <td>{{impDocs.importacaoTipoDocumento.nome}}</td>
                        <td>{{impDocs.numero}}</td>
                        <td>{{impDocs.dataDocs | date: globalFunctions.getDataFormat() }}</td>    
                        <td>
                            <span *ngIf="impDocs.contasPagar">{{impDocs.contasPagar?.nTitulo}}</span>  
                            <span *ngIf="impDocs.contasReceber">{{impDocs.contasReceber?.nTitulo}}</span>
                        </td>
                        <td [ngStyle]="{backgroundColor:getColorCell(impDocs.tipo)}">{{impDocs.tipo}}</td>
                        <td>
                            <span *ngIf="impDocs.contasPagar">{{impDocs.contasPagar?.dataVenc | date: globalFunctions.getDataFormat() }}</span>  
                            <span *ngIf="impDocs.contasReceber">{{impDocs.contasReceber?.dataVenc | date: globalFunctions.getDataFormat() }}</span>  
                        </td>
                        <td>
                            <span *ngIf="impDocs.contasPagar">{{impDocs.contasPagar?.status}}</span>
                            <span *ngIf="impDocs.contasReceber">{{impDocs.contasReceber?.status}}</span>
                        </td>
                        <td><span *ngIf="impDocs.importacaoTipoDocumento.calcCusto == 'SIM'" style="float: right">{{globalFunctions.getFormatNumber(impDocs.custoPerc)}}%</span></td>
                        <td>
                            <span *ngIf="impDocs.tipo == 'D'" style="float: right">{{globalFunctions.getFormatNumber(-1*impDocs.valorFin)}}</span>
                            <span *ngIf="impDocs.tipo == 'C'" style="float: right">{{globalFunctions.getFormatNumber(impDocs.valorFin)}}</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-impDocs let-columns>
                    <tr>
                        <td>{{ 'title_total_records' | transloco }} {{ globalFunctions.getTotalRecords(listaPgtos) }}</td>
                        <td colspan="7">
                        </td>
                        <td>
                            <span style="float: right">{{ globalFunctions.getFormatNumber(percCusto) }}%</span>
                        </td>
                        <td>
                            <span style="float: right">{{ globalFunctions.getFormatNumber(totalPgtos) }}</span>
                        </td>
                    </tr>
                </ng-template>        
            </p-table>
        </p-tabPanel>
        <!--Fim - Aba de Financeiro-->

        <!--Inicio - Historico-->
        <p-tabPanel header="{{'title_guia_import_historic' | transloco }}">
            <app-form-imphistoricoresp [idImp]="obj.id"></app-form-imphistoricoresp>
        </p-tabPanel>
        <!--Fim - Historico-->

    </p-tabView>
</div>


<p-dialog header="Lista Invoices" [(visible)]="displayAddInvoice" [maximizable]="true" [modal]="true" [breakpoints]="{'960px': '85vw', '640px': '100vw'}" [style]="{width: '85vw'}">
    <p-menubar>
        <ng-template pTemplate="start">
            <div class="flex">
                <button type="button" pButton (click)="adicionarInvoice()" icon="pi pi-save"></button>
            </div> 
        </ng-template>
    </p-menubar>
    <p-table #dtInv [value]="listaFiscalInvoices" (onRowSelect)="onRowSelect($event)" (click)="onRowSelect($event)" (onRowUnselect)="onRowSelect($event)" (selectAllChange)="onRowSelect($event)" [(selection)]="selectedFiscalInvoices" dataKey="numTitulo" [tableStyle]="{'min-width': '50rem'}"
        [globalFilterFields]="['numTitulo', 'box', 'info', 'status']" [paginator]="true" [rows]="5">
        <ng-template pTemplate="caption">
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dtInv.filterGlobal($any($event.target).value, 'contains')" />
            </span>
        </ng-template>
        <ng-template pTemplate="header">
        <tr>
            <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>{{constantsAero.PREFIX_FIELD + '4113' | transloco }}</th>
            <th>{{constantsAero.PREFIX_FIELD + '5824' | transloco }}</th>
            <th>{{constantsAero.PREFIX_FIELD + '5825' | transloco }}</th>
            <th>{{constantsAero.PREFIX_FIELD + '4109' | transloco }}</th>
            <th>{{constantsAero.PREFIX_FIELD + '4112' | transloco }}</th>
            <th>{{constantsAero.PREFIX_FIELD + '4119' | transloco }}</th>
            <th>{{constantsAero.PREFIX_FIELD + '4104' | transloco }}</th>
            <th>{{constantsAero.PREFIX_FIELD + '4091' | transloco }}</th>
        </tr>
        </ng-template>
        <ng-template pTemplate="body" let-fiscalInvoice>
        <tr>
            <td>
                <p-tableCheckbox [value]="fiscalInvoice"></p-tableCheckbox>
            </td>
            <td>{{fiscalInvoice.numTitulo}}</td>
            <td>{{fiscalInvoice.box}}</td>
            <td>{{fiscalInvoice.info}}</td>
            <td>{{fiscalInvoice.dataEmissao | date: globalFunctions.getDataFormat() }}</td>
            <td>{{fiscalInvoice.status}}</td>
            <td><span style="float: right">{{globalFunctions.getFormatNumber(fiscalInvoice.totFaturadoMoeda)}}</span></td>
            <td><span style="float: center">{{fiscalInvoice.transpVolumeNum}}</span></td>
            <td>{{fiscalInvoice.id}}</td>
        </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td colspan="6"></td>
                <td><span style="float: right">{{globalFunctions.getFormatNumber(totalFiscalInvoice)}}</span></td>
                <td><span style="float: center">{{totalVolumeFiscalInvoice}}</span></td>
                <td></td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>