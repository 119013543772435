import { RequisicaoClass } from './../../model/compra/requisicaoClass';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class RequisicaoClassService extends GenericService<RequisicaoClass>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("requisicaoclass")
  }
}
