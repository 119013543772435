import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { ChipsModule } from "primeng/chips";
import { ChipModule } from "primeng/chip";
import { DropdownModule } from "primeng/dropdown";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { MultiSelectModule } from "primeng/multiselect";
import { InputTextareaModule } from "primeng/inputtextarea";
import { InputTextModule } from "primeng/inputtext";
import { RatingModule } from 'primeng/rating';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TranslocoModule } from '@ngneat/transloco';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ImportacaoFormRoutingModule } from './importacaoform-routing.module';
import { FormImportacaoTipoDocumentoComponent } from './formimportacaotipodocumento';
import { GenericModule } from '../../generic/generic.module';
import { FormImportacaoDocumentoComponent } from './formimportacaodocumento/formimportacaodocumento.component';
import { FormImportacaoInvoiceComponent } from './formimportacaoinvoice';
import { FormImportacaoComponent } from './formimportacao/formimportacao.component';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { FormImportacaoHistoricoComponent } from './formimportacaohistorico/formimportacaohistorico.component';
import { BadgeModule } from 'primeng/badge';
import { DataViewModule } from 'primeng/dataview';
import { CarouselModule } from 'primeng/carousel';
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormImportacaoHistoricoRespComponent } from './formimportacaohistoricoresp/formimportacaohistoricoresp.component';
import { TagModule } from 'primeng/tag';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FormImportacaoStatusComponent } from './formimportacaostatus';
import { FormImportacaoTimeLineComponent } from './formimportacaotimeline/formimportacaotimeline.component';
import { TimelineModule } from 'primeng/timeline';
import { AccordionModule } from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DividerModule } from 'primeng/divider';
import { OverlayModule } from 'primeng/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { FormImpHistStatusComponent } from './formimphiststatus/formimphiststatus.component';
import { AvatarModule } from 'primeng/avatar';
import { DateMaskDirective } from 'src/app/diretivas/date-mask.directive';
import { ProgressBarModule } from 'primeng/progressbar';
import { ChartModule } from 'primeng/chart';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,

		ImportacaoFormRoutingModule,
		GenericModule,

		AutoCompleteModule,
		CalendarModule,
		ChipsModule,
		DropdownModule,
		InputMaskModule,
		InputNumberModule,
		ColorPickerModule,
		CascadeSelectModule,
		MultiSelectModule,
		ToggleButtonModule,
		SliderModule,
		InputTextareaModule,
		RadioButtonModule,
		InputTextModule,
		RatingModule,
		ChipModule,
		KnobModule,
		InputSwitchModule,
		ListboxModule,
		SelectButtonModule,
		CheckboxModule,
		ButtonModule,
		TableModule,
		InputNumberModule,
		DropdownModule,
		DialogModule,

		TranslocoModule,
		ConfirmDialogModule,
		MenuModule,
		MenubarModule,
		TabViewModule,
		BadgeModule,
		DataViewModule,
		CarouselModule,
		CardModule,
		ImageModule,
		PdfViewerModule,
		TagModule,
		TieredMenuModule,
		DynamicDialogModule,
		TimelineModule,
		AccordionModule,
		ScrollPanelModule,
		DividerModule,
		OverlayModule,
		OverlayPanelModule,
		SidebarModule,
		AvatarModule,
		BadgeModule,
		ProgressBarModule,
		ChartModule,
		KnobModule,


		CommonModule, ImportacaoFormRoutingModule, FileUploadModule, ToastModule, ButtonModule, TabViewModule,
	],
	declarations: [
		FormImportacaoTipoDocumentoComponent,
		FormImportacaoDocumentoComponent,
		FormImportacaoInvoiceComponent,
		FormImportacaoComponent,
		FormImportacaoHistoricoComponent,
		FormImportacaoHistoricoRespComponent,
		FormImportacaoStatusComponent,
		FormImportacaoTimeLineComponent,
		FormImpHistStatusComponent,
		DateMaskDirective
	]
})
export class ImportacaoFormModule { }
