import { ImportacaoTipoDocumento } from './../../model/importacao/importacaoTipoDocumento';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoTipoDocumentoService extends GenericService<ImportacaoTipoDocumento>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("importacaotipodocumento")
  }

  findFilterRotulo(idCompany: number, rotulo: string, fixo: string): Observable<ImportacaoTipoDocumento[]> {
    const urlLocal = `${this.url}/rotulo/${idCompany}/${rotulo}/${fixo}`;
    return this.http.get<ImportacaoTipoDocumento[]>(urlLocal);
  }
}
