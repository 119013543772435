import { Padrao } from '../generic/padrao';
import { FiscalGrupoCfop } from './fiscalGrupoCfop';

export class FiscalCfop extends Padrao{

    override idTab = 160;

    fiscalGrupoCfop: FiscalGrupoCfop = new FiscalGrupoCfop();
    descricao: string;
    resumo: string;
    movEstoque: string;
    combust: string;
    tipo: string;
}