import { Padrao } from '../generic/padrao';

export class FiscalGrupoCst extends Padrao{

    override idTab = 171;

    descricao: string;
    valor: string;
    origem: string;
    redAliqImportacao: string;
}