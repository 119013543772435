import { Injectable } from "@angular/core";
import { Padrao } from "src/app/model/generic/padrao";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { GenericService } from "./generic.service";
import { DadosPesquisa } from "src/app/model/system/dadosPesquisa";

@Injectable({
    providedIn: 'root'
})

export class GenericDuploService<T extends Padrao, TItens extends Padrao> extends GenericService<T> {

    constructor(protected http: HttpClient) {
      super(http);
    }

    findByIdDuplo(idCompany: number, id: number): Observable<T> {
      const urlLocal = `${this.url}/duplo/${idCompany}/${id}`;
      let obj = this.http.get<T>(urlLocal);
    
      obj.subscribe(res => {
        //console.log("Dados recebidos do servidor:", res); // Loga todos os dados recebidos
        Object.getOwnPropertyNames(res).forEach(val => {
          //console.log(val + ' -> ' + res[val]); // Loga cada propriedade e seu valor
          if (val === 'dataCad' && res[val]) {
            res[val] = new Date(res[val]); // Converte 'dataCad' para objeto Date, se existir
          }
        });
      });
    
      return obj;
    }

    findFilterAllDuplo(idCompany: number, dadosPesquisa: DadosPesquisa): Observable<T[]> {
      const urlLocal = `${this.url}/list/items/${idCompany}`;
      //console.log(`Chamando findFilterAllDuplo com URL: ${urlLocal} e dados:`, dadosPesquisa);
      return this.http.post<T[]>(urlLocal, dadosPesquisa, this.httpOptions);
    }

    findFilterAllDuploExtra(urlBase: string, idCompany: number, dadosPesquisa: DadosPesquisa): Observable<T[]> {
      const urlLocal = `${urlBase}/list/items/${idCompany}`;
      //console.log(`Chamando findFilterAllDuplo com URL: ${urlLocal} e dados:`, dadosPesquisa);
      return this.http.post<T[]>(urlLocal, dadosPesquisa, this.httpOptions);
  }
  

    findAllItens(idCompany: number, idMaster: number): Observable<TItens[]> {
        const urlLocal = `${this.url}/item/${idCompany}/${idMaster}`;
        return this.http.get<TItens[]>(urlLocal);
      }
    
    insertItem(objItem: TItens): Observable<TItens> {
      const urlLocal = `${this.url}/item/add/`;
      return this.http.post<TItens>(urlLocal, objItem, this.httpOptions);
    }
    
    deleteItem(idCompany: number, idItem: number): Observable<TItens> {
      const urlLocal = `${this.url}/item/remove/${idCompany}/${idItem}`;
      return this.http.delete<TItens>(urlLocal, this.httpOptions);
    }

    delete(idCompany: number, id: number): Observable<T> {
      const urlLocal = `${this.url}/${idCompany}/${id}`;
      return this.http.delete<T>(urlLocal, this.httpOptions);
    }
    
}    