import { Funcao } from '../geral/funcao';
import { ConceitoPessoa } from './../geral/conceitoPessoa';
import { CentroCusto } from './../financeiro/centroCusto';
import { Padrao } from '../generic/padrao';
import { PesEndereco } from './pesEndereco';
import { PesEmail } from './pesEmail';
import { PesFone } from './pesFone';
import { PesRefBancaria } from './pesRefBancaria';
import { TipoPessoa } from './tipoPessoa';
import { PessoaCnae } from './pessoaCnae';
import { AutoRegisterClass } from 'src/app/services/generics/classRegistry';

@AutoRegisterClass()
export class Pessoa extends Padrao{

    override idTab = 17;

    centroCusto: CentroCusto | null = new CentroCusto();
    conceitoPessoa: ConceitoPessoa | null = new ConceitoPessoa();
    funcao: Funcao | null = new Funcao();
    pessoaTransp: Pessoa | null;
    pessoaVend: Pessoa | null;
    nome: string;
    tipo: string = 'J';
    cpfCnpj: string;
    dataAtua: Date = new Date();
    status: string = 'Ativa';
    statusPend: string = 'OK';
    selecao: string;
    email: string;
    site: string;
    conceito: string;
    ficha: string;
    agencia: string;
    conta: string;
    banco: string;
    receitaSituacao: string;
    receitaChave: string;
    receitaDataCons: Date = new Date();
    certHomo: string;
    crc: string;
    policiaCfc: string;
    policiaClf: string;
    policiaValidade: Date = new Date();
    limiteCredVenda: number = 0;
    limiteCredOs: number = 0;
    antt: string;
    multaGeral: string = 'SIM';
    percMulta: number = 0;
    percJuros: number = 0;
    protestar: string = 'SIM';
    consumidorFinal: string = 'SIM';
    contrib: string = 'NÃO';
    categCompraVenda: string = 'RECEITA/DESPESA';
    obs: string;
    percComissao: number = 0;
    prazoEntrega: number = 0;
    limiteMaxHoras: string;
    assinatura: string;
    dataContrato: Date = new Date();
    etapa: string = 'COMPLETO';
    vendedorTipo: string = 'FIXO';

    listaEndereco: PesEndereco[] = [];	
    listaEmail: PesEmail[] = [];	
    listaFone: PesFone[] = [];	
    listaRefBancaria: PesRefBancaria[] = [];	
    listaTipoPessoa: TipoPessoa[] = [];
    listaPessoaCnae: PessoaCnae[] = [];

    pessoaEmail: Pessoa;
    
}