<p-menubar>
    <ng-template pTemplate="start">
        <div class="grid p-fluid m-0 p-0">
            <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-4">
                <label> {{ constantsAero.PREFIX_FIELD + '5555' | transloco }} </label>
                <p-inputNumber [(ngModel)]="pesoAwbFinal" [disabled]="true" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}"></p-inputNumber>
            </div>
            <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-4">
                <label> {{ 'adjust_weight' | transloco }} </label>
                <p-inputNumber [(ngModel)]="percAjuste" [disabled]="true" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}"></p-inputNumber>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="end">
        <div class="flex">
            <p-tieredMenu  id="menuFunctions" #menuFunctions [popup]="true" [model]="itemsFunctions" appendTo = "body"></p-tieredMenu>
            <button type="button" pButton title="{{'button_functions' | transloco }}" icon="pi pi-fw pi-cog"   (click)="menuFunctions.toggle($event)" class="buttonMargin" style="float: right"></button>

            <p-tieredMenu  id="menuPrint"     #menuPrint     [popup]="true" [model]="itemsPrint"     appendTo = "body"></p-tieredMenu>
            <button type="button" pButton title="{{'button_print' | transloco }}"     icon="pi pi-fw pi-print" (click)="menuPrint.toggle($event)" class="buttonMargin" style="float: right"></button>
        </div>
    </ng-template>
</p-menubar>

<p-table #dt id="dt" [columns]="cols" [value]="list" [resizableColumns]="true"
    [rowHover]="true" styleClass="p-datatable-striped p-datatable-sm"
    responsiveLayout="stack" [breakpoint]="'768px'"
    [scrollable]="true" scrollHeight="{{viewportHeight*0.70 + 'px'}}"
    (onRowSelect)="onRowSelectObj($event)" [selectionMode]="'single'">

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th>Ord</th>
            <th *ngFor="let col of columns" [pSortableColumn]="col" pResizableColumn>
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-iLin="rowIndex">
        <tr [pSelectableRow]="rowData">
            <td style="min-width:30px; width:30px">{{iLin+1 | number:'3.0-0'}}</td>
            <td *ngFor="let col of columns; let iCol = index" [ngStyle]="{backgroundColor:getColorCell(rowData[col.field])}" >
                <span *ngIf="rowData[col.field] && col.type == 'N° Inteiro' && col.field == 'id'" style="float: center">{{ globalFunctions.getIdFormat(rowData[col.field]) }}</span>                        
                <span *ngIf="rowData[col.field] && col.type == 'N° Real'" style="float: right">{{ globalFunctions.getFormatNumber(rowData[col.field]) }}</span>
                <span *ngIf="rowData[col.field] && col.type == 'Data'" style="float: center">{{ rowData[col.field] | date: globalFunctions.getDataFormat() }}</span>                        
                <span *ngIf="rowData[col.field] && col.field != 'id' && col.type != 'N° Real' && col.type != 'N° Inteiro' && col.type != 'Data' && col.type != 'DataHora' && col.type != 'Obj' && col.type != 'Rel' && col.type != 'Badge'">
                    {{rowData[col.field]}} 
                </span>
                <p-inplace #inplacePeso *ngIf="!rowData[col.field] && col.field == 'peso'">
                    <ng-template pTemplate="display">
                        <span style="float: center"><i *ngIf="!rowData[col.field]" class="pi pi-question-circle cursor-pointer" style="font-size: 1rem; color: red;" (click)="clickUpdate(rowData,col.field)"></i></span>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p-inputNumber [(ngModel)]="peso" [size]="10" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}"></p-inputNumber>
                        <i class="pi pi-check cursor-pointer ml-2" style="font-size: 1rem; color: green;" (click)="updatePeso(rowData)"></i>
                        <i class="pi pi-times cursor-pointer ml-2" style="font-size: 1rem; color: red;" (click)="inplacePeso.deactivate()"></i>
                    </ng-template>
                </p-inplace>                
                <span style="float: center"><i *ngIf="!rowData[col.field] && col.field != 'peso'" class="pi pi-question-circle cursor-pointer" style="font-size: 1rem; color: red;" (click)="clickUpdate(rowData,col.field)"></i></span>
        </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="footer" let-columns>
        <tr>
            <td></td>
            <td *ngFor="let col of columns">
                <span *ngIf="col.type == 'N° Real' && col.calcRodape == 'SOMA'" style="float: right">{{ globalFunctions.getTotalColuna(col.field, list, undefined) }}</span>
            </td>
        </tr>
    </ng-template>        
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8" style="text-align:left">{{'title_no_records' | transloco }}</td>
        </tr>
    </ng-template>
</p-table>