import { OnInit, Component, Injector} from '@angular/core';
import { ListaVisualComponent } from '../../../generic/listavisual/listavisual.component';
import { AdendoPecasAplicacaoService } from 'src/app/services/adendo/adendo-pecas-aplicacao.service';
import { AdendoPecasService } from 'src/app/services/adendo/adendo-pecas.service';
import { ListaComponent } from '../../../generic/listapesquisa/lista.component';
import { AdendoPecasAplicacao } from 'src/app/model/adendo/adendoPecasAplicacao';
import { AdendoPecas } from 'src/app/model/adendo/adendoPecas';
import { AdendoPecasConstituicao } from 'src/app/model/adendo/adendoPecasConstituicao';
import { AdendoPecasConstituicaoService } from 'src/app/services/adendo/adendo-pecas-constituicao.service';
import { AdendoPecasAeronave } from 'src/app/model/adendo/adendoPecasAeronave';
import { AdendoPecasAeronaveService } from 'src/app/services/adendo/adendo-pecas-aeronave.service';
import { Aeronave } from 'src/app/model/aeronave/aeronave';
import { AeronaveService } from 'src/app/services/aeronave/aeronave.service';
import { ImportacaoService } from 'src/app/services/importacao/importacao.service';

@Component({
  selector: 'app-lista-traducao',
  templateUrl: './listatraducao.component.html',
  styleUrls: [ '../../../generic/listagenerica/listasimples.component.css']
})

export class ListaTraducaoComponent extends ListaVisualComponent implements OnInit {

  objAdendoPecasAplicacao = new AdendoPecasAplicacao();
  objAdendoPecasConstituicao = new AdendoPecasConstituicao();
  objAdendoPecasAeronave = new AdendoPecasAeronave();
  objAeronave: Aeronave = new Aeronave();
  objAdendoPeca: AdendoPecas;

  pesoAwbFinal: number = 0;
  percAjuste: number = 0;
  pesoTotal: number = 0;

  peso: number = 0;

  constructor(
    protected injectorObj: Injector,
    private apiAdendoPecas: AdendoPecasService,
    private apiAdendoPecasAplicacao: AdendoPecasAplicacaoService,
    private apiAdendoPecasConstituicao: AdendoPecasConstituicaoService,
    private apiAdendoPecasAeronave: AdendoPecasAeronaveService,
    private apiAeronave: AeronaveService,
    private apiImportacao: ImportacaoService
  ) {
    super(injectorObj);
  }


  override ngOnInit(): void {
    super.ngOnInit();
    this.itemsFunctions.push({ separator: true });
    this.itemsFunctions.push({label: this.translocoService.translate('action_update') + ' - ' +  this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5862'), command: () => { this.showAplicacao(this.objSel.id, this.objSel); } });
    this.itemsFunctions.push({ separator: true });
    this.itemsFunctions.push({label: this.translocoService.translate('action_update') + ' - ' +  this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5863'), command: () => { this.showConstituicao(this.objSel.id, this.objSel); } });
  
    this.apiImportacao.returnValorAwbFinal(this.auth.empresa.id, this.id).toPromise().then((res: any) => { this.pesoAwbFinal = res; });
    this.apiImportacao.returnValueRule(this.auth.empresa.id, 653).toPromise().then((res: any) => { this.percAjuste = res; });

    this.pesoTotal = this.globalFunctions.calcularTotais('pesoTotal', this.list, undefined);
  }

  clickUpdate(linha: any, field: string) {
    let idPeca = linha['id'];
    if (field == 'aplicacao')
      this.showAplicacao(idPeca, linha);
    else if (field == 'constituicao')
      this.showConstituicao(idPeca, linha);
    else if (field == 'prefixo')
      this.showAeronave(idPeca, linha);
  }

  showAplicacao(idPeca: number, linha: any) {
      let fn = () => this.updateAplicacao(idPeca, linha);
      this.showDialogDef(ListaComponent, {objSel: this.objAdendoPecasAplicacao, api: this.apiAdendoPecasAplicacao, viewChek: false }, 
        this.translocoService.translate('action_pesquisar') + ' - ' +  this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5862') + ' / ' + linha['partNumber'] + ' - ' + linha['traducao'], fn);
  }

  showConstituicao(idPeca: number, linha: any) {
    let fn = () => this.updateConstituicao(idPeca, linha);
    this.showDialogDef(ListaComponent, {objSel: this.objAdendoPecasConstituicao, api: this.apiAdendoPecasConstituicao, viewChek: false }, 
      this.translocoService.translate('action_pesquisar') + ' - ' +  this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5863') + ' / ' + linha['partNumber'] + ' - ' + linha['traducao'], fn);
  }

  showAeronave(idPeca: number, linha: any) {
    let fn = () => this.updateAeronave(idPeca, linha);
    this.showDialogDef(ListaComponent, {objSel: this.objAeronave, api: this.apiAeronave, viewChek: false }, 
      this.translocoService.translate('action_pesquisar') + ' - ' +  this.translocoService.translate(this.constantsAero.PREFIX_TABLE + '154') + ' / ' + linha['partNumber'] + ' - ' + linha['traducao'], fn);
  }

  updateAplicacao(idPeca: number, linha: any) {
    if (this.retId) {
      this.apiAdendoPecas.updateAplicacao(this.auth.empresa.id, idPeca, this.retId, 628)
      .toPromise()
      .then
      ((res: any) => {
        this.objAdendoPeca = res;
        this.apiAdendoPecasAplicacao.findById(this.auth.empresa.id, this.retId).toPromise()
        .then
        ((res: any) => {
          this.objAdendoPecasAplicacao = res;
          linha['aplicacao'] = this.objAdendoPecasAplicacao.nome;
          const message = this.translocoService.translate('message_update_sucess');
          this.messageService.add({severity:'success', summary: message, detail:''});
        });
      })
      .catch(res => {
        if ((!res) || (res.status === 400)) {
          this.errorHandler.handle(this.translocoService.translate('message_update_failure_general'));
        }
        else {
          this.errorHandler.handle(res);
        }
      });
    }
  }

  updateConstituicao(idPeca: number, linha: any) {
    if (this.retId) {
      this.apiAdendoPecas.updateConstituicao(this.auth.empresa.id, idPeca, this.retId, 628)
      .toPromise()
      .then
      ((res: any) => {
        this.objAdendoPeca = res;
        this.apiAdendoPecasConstituicao.findById(this.auth.empresa.id, this.retId).toPromise()
        .then
        ((res: any) => {
          this.objAdendoPecasConstituicao = res;
          linha['constituicao'] = this.objAdendoPecasConstituicao.nome;
          const message = this.translocoService.translate('message_update_sucess');
          this.messageService.add({severity:'success', summary: message, detail:''});
        });
      })
      .catch(res => {
        if ((!res) || (res.status === 400)) {
          this.errorHandler.handle(this.translocoService.translate('message_update_failure_general'));
        }
        else {
          this.errorHandler.handle(res);
        }
      });
    }
  }

  updateAeronave(idPeca: number, linha: any) {
    if (this.retId) {
      
      this.objAdendoPecasAeronave.adendoPecas.id = idPeca;
      this.objAdendoPecasAeronave.aeronave.id = this.retId;
      this.objAdendoPecasAeronave.empresaCad = this.auth.empresa.id;
      this.objAdendoPecasAeronave.usuarioCad = this.auth.usuarioInfo.usuarioId;
      this.objAdendoPecasAeronave.dataCad = new Date();

      this.apiAdendoPecasAeronave.insert(this.objAdendoPecasAeronave)
      .toPromise()
      .then
      ((res: any) => {
        this.objAdendoPecasAeronave = res;
        this.apiAdendoPecasAeronave.findById(this.auth.empresa.id, this.objAdendoPecasAeronave.id).toPromise()
        .then
        ((res: any) => {
          this.objAdendoPecasAeronave = res;
          linha['prefixo'] = this.objAdendoPecasAeronave.aeronave.matricula;
          const message = this.translocoService.translate('message_update_sucess');
          this.messageService.add({severity:'success', summary: message, detail:''});
        });
      })
      .catch(res => {
        if ((!res) || (res.status === 400)) {
          this.errorHandler.handle(this.translocoService.translate('message_update_failure_general'));
        }
        else {
          this.errorHandler.handle(res);
        }
      });
    }
  }

  updatePeso(linha: any) {
    let idPeca = linha['id'];
    if ((idPeca) && (idPeca > 0)) {
      
      this.apiAdendoPecas.findById(this.auth.empresa.id, idPeca)
      .toPromise()
      .then
      ((res: any) => {
        this.objAdendoPeca = res;
        this.objAdendoPeca.peso = this.peso;
        this.apiAdendoPecas.update(this.objAdendoPeca.id, this.objAdendoPeca).toPromise()
        .then
        ((res: any) => {
          linha['peso'] = this.peso;
          linha['pesoTotal'] = linha['peso'] * linha['quantidade'];

          this.pesoTotal = this.globalFunctions.calcularTotais('pesoTotal', this.list, undefined);
          let fator = this.pesoAwbFinal/this.pesoTotal;

          this.list.forEach(element => {
            element['pesoAjust'] = element['peso'] * fator * (this.percAjuste/100);
            element['pesoTotalAjust'] = element['pesoTotal'] * fator * (this.percAjuste/100);
          });
          const message = this.translocoService.translate('message_update_sucess');
          this.messageService.add({severity:'success', summary: message, detail:''});
        });
      })
      .catch(res => {
        if ((!res) || (res.status === 400)) {
          this.errorHandler.handle(this.translocoService.translate('message_update_failure_general'));
        }
        else {
          this.errorHandler.handle(res);
        }
      });
    }
  }


  override getColorCell(valor: any) {  
    if (!valor)
      return 'var(--yellow-100)'
    else
      return undefined;
  }


}