import { Padrao } from '../generic/padrao';
import { AdendoPecas } from './adendoPecas';
import { Fabricante } from '../estoque/fabricante';
import { ModeloAdendo } from './modeloAdendo';
import { Teste } from '../documento/teste';

export class Adendo extends Padrao{

     override idTab = 101;

     fabricante: Fabricante = new Fabricante();
     teste: Teste = new Teste();
     adendoPecas: AdendoPecas = new AdendoPecas();
     modeloAdendo: ModeloAdendo = new ModeloAdendo();
     s: string;
     p: string;
     c: string;
     partNumber: string;
     descricao: string;
     modelo: string;
     status: string;
     ativo: string;
     impressao: string;
     impModelo: string;
     impPN: string;
     ordem: number;
     fabricanteTemp: string;
     imagem: string;
     variavel: string;
     label: string;
     controlado: string;
}