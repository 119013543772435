import { AdendoPecasAplicacao } from './../../model/adendo/adendoPecasAplicacao';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class AdendoPecasAplicacaoService extends GenericService<AdendoPecasAplicacao>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("adendopecasaplicacao")
  }
}
