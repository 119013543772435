<div class="card p-1 m-0">

    <div class="grid m-0 p-0">
        <div class="col-12 m-0 p-0 field">
            <div class="block text-center h-3rem font-bold border-round m-0 p-2" [ngStyle]="{'backgroundColor':auth.getCorEmpresaSel()}">
                <h5>{{this.title | transloco }}</h5>
            </div>
        </div>
    </div>

    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

    <p-menubar>
        <ng-template pTemplate="start">
            <label class="fieldMandatory">
                {{'message_required_fields' | transloco }}
            </label>
        </ng-template>
        <ng-template pTemplate="end">
            <button pButton type="button" icon="pi pi-save" title="{{action}}" class="buttonMargin"
            (click)="save(true)" *ngIf="paramAction == 'update' || paramAction == 'insert'"></button>
            <button pButton type="button" icon="pi pi-angle-double-left" title="{{'button_cancel' | transloco}}" class="buttonMargin"
            (click)="back()"></button>
            <button pButton type="button" icon="pi pi-fw pi-cog" title="{{'button_functions' | transloco}}" class="buttonMargin"></button>
        </ng-template>
    </p-menubar>
    <div class="grid p-fluid ml-0 mt-2 pr-3 pl-1">
        <ng-template ngFor let-objSysCampo [ngForOf]="listSysFields" let-i="index">

            <div class="m-0 pb-0 pl-1 pr-1 pt-1 field col-12 md:col-{{objSysCampo.tamHorzWeb}}" *ngIf="!(objSysCampo.nomeProp == 'usuarioCad' || objSysCampo.nomeProp == 'empresaCad' || objSysCampo.nomeProp == 'dataCad')">
                <label for="input{{objSysCampo.nomeProp}}" [class]="(objSysCampo.habilitado == 'S' && objSysCampo.obrigatorio == 'S') ? 'fieldMandatory' : objSysCampo.habilitado == 'S' ? '' : 'fieldDisable'">
                    {{constantsAero.PREFIX_FIELD + objSysCampo.id | transloco }}
                </label>

                <p-inputNumber *ngIf="objSysCampo.tipoWeb == 'N° Inteiro'" id="input{{objSysCampo.nomeProp}}"
                    name="input{{objSysCampo.nomeProp}}" [(ngModel)]="obj[objSysCampo.nomeProp]"
                    [disabled]="objSysCampo.habilitado == 'N'">
                </p-inputNumber>

                <p-inputNumber *ngIf="objSysCampo.tipoWeb == 'N° Real'" id="input{{objSysCampo.nomeProp}}"
                    name="input{{objSysCampo.nomeProp}}" [(ngModel)]="obj[objSysCampo.nomeProp]"
                    [disabled]="objSysCampo.habilitado == 'N'"
                    mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}">
                </p-inputNumber>

                <input *ngIf="objSysCampo.tipoWeb == 'Texto'" pInputText id="input{{objSysCampo.nomeProp}}"
                    name="input{{objSysCampo.nomeProp}}" type="text" [(ngModel)]="obj[objSysCampo.nomeProp]"
                    [disabled]="objSysCampo.habilitado == 'N'" maxlength="{{objSysCampo.maxCarac}}">

                <textarea *ngIf="objSysCampo.tipoWeb == 'Memo'" pInputTextarea id="input{{objSysCampo.nomeProp}}"
                    name="input{{objSysCampo.nomeProp}}" [(ngModel)]="obj[objSysCampo.nomeProp]"
                    [disabled]="objSysCampo.habilitado == 'N'" maxlength="{{objSysCampo.maxCarac}}">
                </textarea>

                <p-calendar *ngIf="objSysCampo.tipoWeb == 'Data'" id="input{{objSysCampo.nomeProp}}"
                    name="input{{objSysCampo.nomeProp}}" [(ngModel)]="obj[objSysCampo.nomeProp]"
                    [disabled]="objSysCampo.habilitado == 'N'" dateFormat="{{ 'date_short_format_form' | transloco }}"
                    [showIcon]="true">
                </p-calendar>

                <p-selectButton *ngIf="objSysCampo.tipoWeb == 'TYPE_BOOLEAN'" id="input{{objSysCampo.nomeProp}}"
                    name="input{{objSysCampo.nomeProp}}" [(ngModel)]="obj[objSysCampo.nomeProp]"
                    [disabled]="objSysCampo.habilitado == 'N'" [options]="optionsYesNo" optionValue="value">
                </p-selectButton>

                <p-dropdown *ngIf="objSysCampo.tipoWeb == 'ComboFixo'" id="input{{objSysCampo.nomeProp}}"
                    name="input{{objSysCampo.nomeProp}}" [(ngModel)]="obj[objSysCampo.nomeProp]"
                    [disabled]="objSysCampo.habilitado == 'N'" [options]="globalFunctions.extrairOpcoes(objSysCampo.opcoes)"  
                    [filter]="true" autoWidth="false">
                </p-dropdown>

                <p-dropdown *ngIf="objSysCampo.tipoWeb == 'Obj'" id="input{{objSysCampo.nomeProp}}"
                    name="input{{objSysCampo.nomeProp}}" [(ngModel)]="obj[objSysCampo.nomeProp]"
                    [disabled]="objSysCampo.habilitado == 'N'" [options]="mapObjsDependent.get(objSysCampo.id)" 
                    optionLabel="{{objSysCampo.nomePropVisao}}"
                    [filter]="true" autoWidth="false">
                </p-dropdown>
            </div>
        </ng-template>
    </div>
    <app-form-campos-padroes [obj]="obj"></app-form-campos-padroes>
</div>