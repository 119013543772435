import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { AuthService } from './services/security/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from './services/system/error-handler.service';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthGuard } from './services/security/auth.guard';
import { AppHttpInterceptor } from './services/security/app-http-interceptor';
import { GeralFormModule } from './demo/components/aerosys/geral/forms/geralform.module';
import { GeralListaModule } from './demo/components/aerosys/geral/listas/gerallista.module';
import { ImportacaoFormModule } from './demo/components/aerosys/importacao/forms/importacaoform.module';
import { ImportacaoListaModule } from './demo/components/aerosys/importacao/listas/importacaolista.module';
import { GenericModule } from './demo/components/aerosys/generic/generic.module';
import { AppConfig } from 'src/app.config';
import { HomeComponent } from './demo/components/aerosys/home/home.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DateMaskDirective } from './diretivas/date-mask.directive';
import { ToastModule } from 'primeng/toast';
import { DialogGenericoComponent } from './demo/components/aerosys/generic/formdialog/formgenericdialog.component';

export function initializeApp(appConfig: AppConfig) {
    return () => appConfig.load();
}

@NgModule({
    declarations: [
        AppComponent, NotfoundComponent, HomeComponent
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        HttpClientModule,
        TranslocoRootModule,

        GenericModule,
        GeralFormModule,
        GeralListaModule,
        ImportacaoFormModule,
        ImportacaoListaModule,

        JwtModule.forRoot({
            config: {
              tokenGetter:() => {
                 return localStorage.getItem('token'); 
              },
              //allowedDomains: [AppConfig.dominioPadrao],
              //disallowedRoutes: [AppConfig.urlBasic + 'oauth/token']
            },
        })
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, 
        
        MessageService, 
        DialogService,
        ConfirmationService, 

        DynamicDialogRef,

        DialogGenericoComponent,
        
        AuthService, 
        AuthGuard, 
        TranslocoService, 
        JwtHelperService,
        ErrorHandlerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true
        },
        AppConfig,
        { 
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig], multi: true 
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
