import { Component, Injector, OnInit } from '@angular/core';
import { FormGenerico } from '../../generic/formgenerico/formgenerico';
import { GeralFormModule } from './geralform.module';
import { Funcao } from 'src/app/model/geral/funcao';
import { FuncaoService } from 'src/app/services/geral/funcao.service';

@Component({
  selector: 'app-form-funcao',
  templateUrl: '../../generic/formgenerico/formgenerico.component.html',
  styleUrls: [ '../../generic/formgenerico/formgenerico.component.css']
})

export class FormFuncaoComponent extends FormGenerico<Funcao> implements OnInit {

  constructor(
    injectorObj: Injector,
    api: FuncaoService) 
  {
    super(injectorObj, api);
    this.obj = new Funcao();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override dataExtraObj(res: any) {
  }

}
