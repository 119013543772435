import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import { Padrao } from 'src/app/model/generic/padrao';
import { SysCampo } from 'src/app/model/system/sysCampo';
import { SysTabela } from 'src/app/model/system/sysTabela';
import { SysCampoService } from 'src/app/services/system/sys-campo.service';
import { SysTabelaService } from 'src/app/services/system/sys-tabela.service';
import { AuthService } from 'src/app/services/security/auth.service';
import { ErrorHandlerService } from 'src/app/services/system/error-handler.service';
import { GenericService } from 'src/app/services/generics/generic.service';
import { ConstantsAero, QueryGeneric } from '../constantsAero';
import { AcessosGrupoService } from 'src/app/services/security/acessos-grupo.service';
import { AcessosGrupo } from 'src/app/model/security/acessosGrupo';
import { GlobalFunctions } from '../global-functions';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Arquivo } from 'src/app/model/importacao/arquivo';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable()
export abstract class FormGenerico<T extends Padrao> implements OnInit, OnDestroy {

  obj: T;
  objItemSel: Padrao;
  indiceItemSel: number;

  optionsYesNo: any[];

  listSysFields: SysCampo[] = [];
  objSysTable: SysTabela;
  acessosGrupo: AcessosGrupo

  title: string;
  id: number;
  action: string;
  paramAction: string;
  newRecordMulti: boolean = false; // Inserir Vários Cadastros em sequencia. Não volta para a tela de Listagem. Apenas ao clicar no botão cancelar.
  confirmCancel: boolean = false; // Pergunta se deseja cancelar o cadastro.

  itemsFunctions: MenuItem[] = []; 
  itemsFunctionsItems: MenuItem[] = [];
  mapObjsDependent = new Map();

  itemsFunctionsPreComra: MenuItem[];
  itemsFunctionsItemsPreCompra: MenuItem[] = [];

  location: Location;
  messageService: MessageService;
  confirmationService: ConfirmationService;
  activatedRoute: ActivatedRoute;
  translocoService: TranslocoService;

  apiSysFields: SysCampoService;
  apiSysTable: SysTabelaService;
  apiAcessosGrupo: AcessosGrupoService;
  sanitizer: DomSanitizer;

  signalFieldsMandatory: boolean = false;

  protected router: Router;
  
  auth: AuthService;

  errorHandler: ErrorHandlerService;

  protected constantsAero: ConstantsAero = new ConstantsAero();
  public globalFunctions: GlobalFunctions;

  public viewportWidth: number = 0;
  public viewportHeight: number = 0;

  //Dialog
  ref: DynamicDialogRef;
  dialogService: DialogService;

  titleDialog: string = '';
  componentDialog: any = null;

  //Download e Upload
  fileStatus = { status: '', requestType: '', percent: 0 };
  filenames: string[] = [];
  files: any[] = []; // upload obj + files - insertMult

  abstract dataExtraObj(res: any);
  
  constructor(protected injectorObj: Injector, public api: GenericService<T>) { 
    this.messageService = this.injectorObj.get(MessageService);
    this.confirmationService = this.injectorObj.get(ConfirmationService);
    this.translocoService = this.injectorObj.get(TranslocoService);

    this.activatedRoute = this.injectorObj.get(ActivatedRoute);
    this.location = this.injectorObj.get(Location);

    this.apiSysFields = this.injectorObj.get(SysCampoService);
    this.apiSysTable = this.injectorObj.get(SysTabelaService);
    
    this.auth = this.injectorObj.get(AuthService);
    this.errorHandler = this.injectorObj.get(ErrorHandlerService);
    this.sanitizer = this.injectorObj.get(DomSanitizer);

    this.dialogService = this.injectorObj.get(DialogService);
  
    this.globalFunctions = new GlobalFunctions(this.translocoService, this.injectorObj);

    this.router = this.injectorObj.get(Router);

    const yes = this.translocoService.translate("yes");
    const no = this.translocoService.translate("no");
    this.optionsYesNo = [{label: yes, value: true}, {label: no, value: false}];

    this.viewportWidth = window.innerWidth;
    this.viewportHeight = window.innerHeight;        

  }

  ngOnInit(): void {

    this.loadData();
    this.findTable();

    this.api.returnValueRule(this.auth.empresa.id, 402).toPromise().then((res: any) => { this.newRecordMulti = res == 'VÁRIOS REGISTROS' });
    this.api.returnValueRule(this.auth.empresa.id, 233).toPromise().then((res: any) => { this.confirmCancel = res == 'SIM' });
  }

  loadData() {
        // se possivel, capture o parametro 
        this.activatedRoute.queryParams.subscribe(params => {
          if (params['id']) {
            this.id = params['id'];
            this.findById(this.id);
            this.paramAction = params['action'];
            if (this.paramAction == 'update') {
              this.action = this.translocoService.translate('action_update');
            }
            else {
              this.action = this.translocoService.translate('action_view');
            }
          }
          else {
            this.paramAction = 'insert';
            this.action = this.translocoService.translate('action_insert');
            this.obj.empresaCad = this.auth.empresa.id;
            this.obj.usuarioCad = this.auth.usuarioInfo.usuarioId;
          }
        })
        //console.log(JSON.stringify(this.obj));
  }

  back() {
    this.location.back();
  }

  validateFielsMandatory(): boolean {
    let res = true;
    if (this.listSysFields) {
      for (let i = 0; i < this.listSysFields.length; i++) {
        const sysField = this.listSysFields[i];
        if ((sysField.obrigatorio == 'S') && ((this.obj[sysField.nomeProp] == undefined) || (this.obj[sysField.nomeProp].length == 0))) {
          this.signalFieldsMandatory = true;
          res = false;
        }
      }
    }
    return res;
  }

  save(backListUpdate: boolean) {
    //console.log(this.obj);
    let res;

    if (this.obj.empresaCad == 0) {
      this.obj.empresaCad = this.auth.empresa.id;
    }
    this.obj.dataCad = new Date();
    this.obj.usuarioCad = this.auth.usuarioInfo.usuarioId;

    if (this.validateFielsMandatory()) {
      if (this.obj.id == 0) {
        res = this.insert();
        if (this.newRecordMulti) {
          // criar método de inicialização - this.obj = new
        }
        else if (backListUpdate) {
          this.back();
        }
      }
      else {
        res = this.update();
        if (backListUpdate) {
          this.back();
        }
      }
      this.back();
      return res;
    }
    else {
      this.errorHandler.handle(this.translocoService.translate('message_required_fields'));
      return undefined;
    }
  }

  insert() {
    return this.api.insert(this.obj)
      .toPromise()
      .then(res => {
        const message = this.translocoService.translate('message_insert_sucess');
        this.messageService.add({severity:'success', summary: `${this.title} ${message}`, detail:''});
        return res; 
      })
      .catch(res => {
        if ((!res) || (res.status === 400)) {
          this.errorHandler.handle(this.translocoService.translate('message_insert_failure') + ' ' + this.title);
        }
        else {
          this.errorHandler.handle(res);
        }
        return undefined;
      });
  }

  insertMult(dialog: boolean, inserir: boolean): any {
    return this.api.insertMult(this.obj, this.files)
      .toPromise()
      .then(res => {
        let message;
        if (inserir) {
          message = this.translocoService.translate('message_insert_sucess');
        }
        else {
          message = this.translocoService.translate('message_update_sucess');
        }
        this.messageService.add({severity:'success', summary: `${this.title} ${message}`, detail:''});
        this.files = [];
        if (dialog) {
          
        } else {
          this.back();
        }
        return res;        
      })
      .catch(res => {
        if ((!res) || (res.status === 400)) {
            let message;
            if (inserir) {
              message = this.translocoService.translate('message_insert_failure');
            }
            else {
              message = this.translocoService.translate('message_update_failure')
            }
            this.errorHandler.handle(message + ' ' + this.title);
        }
        else {
          this.errorHandler.handle(res);
        }
        return undefined;
      });
  }

  update() {
    return this.api.update(this.obj.id, this.obj)
      .toPromise()
      .then(res => {
        const message = this.translocoService.translate('message_update_sucess');
        this.messageService.add({severity:'success', summary: `${this.title} ${message}`, detail:''});
        return res;
      })
      .catch(res => {
        if ((!res) || (res.status === 400)) {
          this.errorHandler.handle(this.translocoService.translate('message_update_failure') + ' ' + this.title);
        }
        else {
          this.errorHandler.handle(res);      
        }
        return undefined;
      });
  }

  findById(id: number) {
    this.api.findById(this.auth.empresa.id, id)
      .toPromise()
      .then
      ((res: any) => {
        this.obj = res;
        this.obj.listaItensExcluidos = [];
        this.obj.dataCad = new Date(res.dataCad);
        this.dataExtraObj(res);
      })
  }

  findTable() {
    //console.log(this.obj.idTab);
    const idTab = this.obj.idTab;
    if (idTab) {
      this.apiSysTable.findListById(this.obj.idTab)
      .toPromise()
      .then
      ((res: any) => {
        this.objSysTable = res;
        this.title = this.translocoService.translate( this.constantsAero.PREFIX_TABLE + idTab);
        this.findFields(idTab);
      });
    }
  }

  findFields(idTab: number) {
    //console.log(this.obj.idTab);
    this.apiSysFields.findListFormByIdTable(idTab)
      .toPromise()
      .then
      ((res: any) => {
        this.listSysFields = res;
        //console.log(res);

        this.listSysFields.map(c => {

          if (c.tipoWeb == 'Obj') {
            //console.log(c);
            
            let listTemp = new QueryGeneric(c.classeNomePesq, this.api.getHttpClient(), this.injectorObj).query();
            listTemp.toPromise()
              .then
              ((res: any) => {
                listTemp = res;
                this.mapObjsDependent.set(c.id, listTemp);
              });
          }
        });

        //console.log(this.listObjsDependent)
      });
  }

  findAccess() {
    //console.log(this.auth.empresa.id + ' - '+ this.auth.usuarioInfo.grupoId + '-' +  this.auth.menuItemSelecionado.id)
    this.apiAcessosGrupo.findByAcessosGrupoIdMenu(this.auth.empresa.id, this.auth.usuarioInfo.grupoId, this.auth.menuItemSelecionado.menuAcesso.id)
      .toPromise()
      .then
      ((res: any) => {
        this.acessosGrupo = res;
        //console.log(this.acessosGrupo);
      });
  }

  findAllGeneric(apiTemp: any) {
    return apiTemp.findAll(this.auth.empresa.id)
      .toPromise()
      .then
      ((res: any) => {
        return res;
      });
  }

  deleteItens(objFilho: Padrao, index: number) {
    const mens = this.translocoService.translate('mens_delete_pre_confirm_item');
    const header = this.translocoService.translate('mens_confirm');
    const btYes = this.translocoService.translate('yes');
    const btNo = this.translocoService.translate('no');
    const mensConfirm = this.translocoService.translate('mens_delete_pos_confirm_item');

    this.confirmationService.confirm({
      message: mens,
      header: header,
      icon: 'pi pi-question-circle',
      acceptLabel: btYes,
      rejectLabel: btNo,
      accept: () => {
        if (objFilho.id > 0) {
          this.obj.listaItensExcluidos?.push(objFilho);
        }
        this.obj.listaItens.splice(index, 1);
        this.messageService.add({ severity: 'success', summary: mensConfirm, detail: '' });
      },
      reject: () => {
      }
    });
  }

  barraProgresso(httpEvent: HttpEvent<string[] | Blob>, nome: string, arq: Arquivo): void {
    switch (httpEvent.type) {
      case HttpEventType.Response:
        if (httpEvent.body instanceof Array) {
          this.fileStatus.status = 'Finalizado';
          for (const filename of httpEvent.body) {
            this.filenames.unshift(filename);
          }
        } else {
            saveAs(new File([httpEvent.body!], nome,
              { type: `${httpEvent.headers.get('Content-Type')};charset=utf-8` }));
        }
    }
  }

  delete(api: any, obj: T, title: string): Promise<boolean> {

    return new Promise(resolve => {

        const mens = this.translocoService.translate('mens_delete_pre_confirm') + title;
        const header = this.translocoService.translate('mens_confirm');
        const btYes = this.translocoService.translate('yes');
        const btNo = this.translocoService.translate('no');
        const mensConfirm = title + this.translocoService.translate('mens_delete_pos_confirm');

        this.confirmationService.confirm({
          message: mens,
          header: header,
          icon: 'pi pi-question-circle',
          acceptLabel: btYes,
          rejectLabel: btNo,
          accept: () => {
            api.delete(this.auth.empresa.id, obj.id)
              .toPromise()
              .then((res: any) => {
                this.messageService.add({ severity: 'success', summary: mensConfirm, detail: '' });
                resolve(true);
              })
              .catch((res: any) => {
                if ((!res) || (res.status === 400)) {
                  this.errorHandler.handle(this.translocoService.translate('message_delete_failure') + ' ' + this.title);
                  resolve(false);
                }
                else {
                  this.errorHandler.handle(res);      
                  resolve(false);
                }
              })
          },
          reject: () => {
            resolve(false);
          }
        });

    });
  }

  atualizarListaArquivos(event) {
    for (let file of event.files) {
      this.files.push(file);
    }
  }

  showDialogDef(component: any, data: any, titulo: string, fn: any, width ='100%', height ='100%') {
    this.ref = this.dialogService.open(component, {
        header: titulo,
        //style: {width: '85vw'},
        width: width,
        height: height,
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: true,
        draggable: true,
        modal: true,
        data: data
      });
    
      this.ref.onClose.subscribe((objRes: any) => {
        if ((fn) /*&& objRes*/) {
          fn();
        }
      });
  }  

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  updateParcialDireto(apiUpdate: any, objUpdate: Padrao, propriedade: string, valor: string, idRegra: number, title: string) {
    const mens = this.translocoService.translate('mens_update_pre_confirm') + this.title;
    const header = this.translocoService.translate('mens_confirm');
    const btYes = this.translocoService.translate('yes');
    const btNo = this.translocoService.translate('no');

    //console.log('URL da requisição de atualização:', apiUpdate.url); // Adicionar um log para a URL da requisição de atualização
    
/*     console.log('Dados para atualização:', {
      empresaId: this.auth.empresa.id,
      objUpdateId: objUpdate.id,
      propriedade: propriedade,
      valor: valor,
      idRegra: idRegra
    }); */


    apiUpdate.updateParcial(this.auth.empresa.id, objUpdate.id, propriedade, valor, idRegra)
              .toPromise()
              .then(res => {
                const message = this.translocoService.translate('message_update_sucess');
                this.messageService.add({severity:'success', summary: `${title} ${message}`, detail:''});
                objUpdate[propriedade] = valor;
              })
              .catch(res => {
                if ((!res) || (res.status === 400)) {
                  this.errorHandler.handle(this.translocoService.translate('message_update_failure') + ' ' + this.title);
                }
                else {
                  this.errorHandler.handle(res);      
                }
              });

  }

  updateParcialMult(apiUpdate: any, objUpdate: Padrao, camposParaAtualizar: { [key: string]: string }, idRegra: number, title: string) {
    const mens = this.translocoService.translate('mens_update_pre_confirm') + this.title;
    const header = this.translocoService.translate('mens_confirm');
    const btYes = this.translocoService.translate('yes');
    const btNo = this.translocoService.translate('no');
  
    //console.log('URL da requisição de atualização:', apiUpdate.url); // Log da URL de atualização
/*     console.log('Dados para atualização:', {
      empresaId: this.auth.empresa.id,
      objUpdateId: objUpdate.id,
      camposParaAtualizar: camposParaAtualizar,
      idRegra: idRegra
    });
   */
    // Agora a requisição envia múltiplos campos para o backend
    apiUpdate.updateParcialMult(this.auth.empresa.id, objUpdate.id, camposParaAtualizar, idRegra)
      .toPromise()
      .then(res => {
        const message = this.translocoService.translate('message_update_sucess');
        this.messageService.add({ severity: 'success', summary: `${title} ${message}`, detail: '' });
  
        // Atualiza os campos localmente no objeto
        Object.keys(camposParaAtualizar).forEach(propriedade => {
          objUpdate[propriedade] = camposParaAtualizar[propriedade];
        });
      })
      .catch(res => {
        if ((!res) || (res.status === 400)) {
          this.errorHandler.handle(this.translocoService.translate('message_update_failure') + ' ' + this.title);
        } else {
          this.errorHandler.handle(res);      
        }
      });
  }
  
  updateParcial(apiUpdate: any, objUpdate: Padrao, propriedade: string, valor: string, idRegra: number, title: string) {
    const mens = this.translocoService.translate('mens_update_pre_confirm') + this.title;
    const header = this.translocoService.translate('mens_confirm');
    const btYes = this.translocoService.translate('yes');
    const btNo = this.translocoService.translate('no');

    //console.log('URL da requisição de atualização:', apiUpdate.url); // Adicionar um log para a URL da requisição de atualização
    
/*     console.log('Dados para atualização:', {
      empresaId: this.auth.empresa.id,
      objUpdateId: objUpdate.id,
      propriedade: propriedade,
      valor: valor,
      idRegra: idRegra
    }); */

      this.confirmationService.confirm({
      message: mens,
      header: header,
      icon: 'pi pi-question-circle',
      acceptLabel: btYes,
      rejectLabel: btNo,
      accept: () => {
        
          apiUpdate.updateParcial(this.auth.empresa.id, objUpdate.id, propriedade, valor, idRegra)
                    .toPromise()
                    .then(res => {
                      const message = this.translocoService.translate('message_update_sucess');
                      this.messageService.add({severity:'success', summary: `${title} ${message}`, detail:''});
                      objUpdate[propriedade] = valor;
                    })
                    .catch(res => {
                      if ((!res) || (res.status === 400)) {
                        this.errorHandler.handle(this.translocoService.translate('message_update_failure') + ' ' + this.title);
                      }
                      else {
                        this.errorHandler.handle(res);      
                      }
                    });
                },
                
                  reject: () => {
                }
      });
      
  }

  verificaPermissao(idRegra: number) {
    return this.api.getPermissao(this.auth.empresa.id, idRegra)
      .toPromise()
      .then
      ((res: any) => {
        return res;
      })
      .catch(() => {
        return false;
      })
  }

  getColorFieldMandatory(sysCampo: SysCampo, value: any) {
    if ((this.signalFieldsMandatory) && (sysCampo.obrigatorio) && (sysCampo.obrigatorio == 'S') && (sysCampo.habilitado == 'S') && (sysCampo.visivel == 'S')) {
      if (!value) {
        return 'var(--red-50)';
      }
    }
    return undefined;
  }

  selectItem(objItemSel: Padrao, indice: number) {
    this.objItemSel = objItemSel;
    //this.idImpSel = objImpInvoice.id;
    this.indiceItemSel = indice;
  }

  addEmptyRowGeneric<T extends object>(tableData: T[]): void {
    //console.log('addEmptyRowGeneric called');
    
    if (tableData.length === 0) return;

    const emptyRow = Object.keys(tableData[0]).reduce((acc, key) => {
        const value = tableData[0][key];
        if (typeof value === 'string') {
            acc[key] = '';
        } else if (typeof value === 'number') {
            acc[key] = 0;
        } else if (typeof value === 'boolean') {
            acc[key] = false;
        } else {
            acc[key] = null; // Default case for other types
        }
        return acc;
    }, {} as T);

    tableData.push(emptyRow);
    //console.log('Empty row added:', emptyRow);
  }

}
