import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormGenerico } from '../../../generic/formgenerico/formgenerico';
import { ImportacaoDocumento } from 'src/app/model/importacao/importacaoDocumento';
import { ImportacaoDocumentoService } from 'src/app/services/importacao/importacao-documento.service';
import { ImportacaoTipoDocumento } from 'src/app/model/importacao/importacaoTipoDocumento';
import { ImportacaoTipoDocumentoService } from 'src/app/services/importacao/importacao-tipo-documento.service';
import { ContasPagarService } from 'src/app/services/financeiro/contas-pagar.service';
import { ContasPagar } from 'src/app/model/financeiro/contasPagar';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContasReceber } from 'src/app/model/financeiro/contasReceber';
import { ContasReceberService } from 'src/app/services/financeiro/contas-receber.service';

@Component({
  selector: 'app-form-imp-docs',
  templateUrl: './formimportacaodocumento.component.html',
  styleUrls: ['../../../generic/formgenerico/formgenerico.component.css']
})

export class FormImportacaoDocumentoComponent extends FormGenerico<ImportacaoDocumento> implements OnInit {

  acao: string;
  tipo: string;
  fixo: string;
  idImportacao: number;
  id: number;
  objImportacaoTipoDoc: ImportacaoTipoDocumento;

  objContasPagar: ContasPagar = new ContasPagar();
  objContasReceber: ContasReceber = new ContasReceber();
  listaImpTipoDoc: ImportacaoTipoDocumento[];

  constructor(
    injectorObj: Injector,
    public api: ImportacaoDocumentoService,
    private apiImportacaoTipoDocumento: ImportacaoTipoDocumentoService,
    public apiContasPagar: ContasPagarService,
    public apiContasReceber: ContasReceberService,
    public refDialog: DynamicDialogRef,
    public configDialog: DynamicDialogConfig) 
  {
    super(injectorObj, api);
    this.obj = new ImportacaoDocumento();
  }

  override ngOnInit(): void {
    //super.ngOnInit();
    if (this.configDialog.data) {
      this.tipo = this.configDialog.data.tipo;
      this.id   = this.configDialog.data.id;
      this.acao = this.configDialog.data.acao;
      this.fixo = this.configDialog.data.fixo;
      this.idImportacao = this.configDialog.data.idImportacao;
      this.objImportacaoTipoDoc = this.configDialog.data.objImportacaoTipoDoc;

      this.findAllImpTipoDocs(this.tipo)
        .then(res => {
          this.listaImpTipoDoc = res;

          if (this.id > 0) {
            this.findById(this.id);
          } 
          else {
            this.obj = new ImportacaoDocumento();

            if (this.objImportacaoTipoDoc) {
              this.obj.importacaoTipoDocumento = this.objImportacaoTipoDoc;
              this.tipo = this.obj.importacaoTipoDocumento.rotulo;
            }
          }
        });
      this.title = this.translocoService.translate( this.constantsAero.PREFIX_TABLE + this.obj.idTab); 
    }
  }

  override dataExtraObj(res: any) {
    if (this.obj.dataDocs) {
      this.obj.dataDocs = new Date(this.obj.dataDocs);
    }
    if ((this.obj.contasPagar) && (this.obj.contasPagar.id > 0)) {
      this.objContasPagar = this.obj.contasPagar;
    }    
    if ((this.obj.contasReceber) && (this.obj.contasReceber.id > 0)) {
      this.objContasReceber = this.obj.contasReceber;
    }    
  }

  override insertMult(dialog: boolean, inserir: boolean): any {
    this.obj.contasPagar = null;
    if ((this.tipo == 'FIN') && (this.obj.tipo == 'D')) {
      if ((this.objContasPagar) && (this.objContasPagar.id > 0)) 
        this.obj.contasPagar = this.objContasPagar;
    }

    this.obj.contasReceber = null;
    if ((this.tipo == 'FIN') && (this.obj.tipo == 'C')) {
      if ((this.objContasReceber) && (this.objContasReceber.id > 0)) 
        this.obj.contasReceber = this.objContasReceber;
    }

    this.obj.importacao.id = this.idImportacao;
    this.obj.empresaCad = this.auth.empresa.id;
    this.obj.usuarioCad = this.auth.usuarioInfo.usuarioId;
    this.obj.dataCad = new Date();
    return super.insertMult(dialog, inserir).then(() => this.refDialog.close(true));
  }

  //Lista Tipo Doc / Financeiro
  findAllImpTipoDocs(tipo: string) {
    return this.apiImportacaoTipoDocumento.findFilterRotulo(this.auth.empresa.id, tipo, this.fixo)
      .toPromise()
      .then
      ((res: any) => {
        return res;
      });
  }

  atualizarContasPagar(objSelFilho) {
    this.objContasPagar = objSelFilho;
    this.obj.valorFin = this.objContasPagar.valor;
  }

  atualizarContasReceber(objSelFilho) {
    this.objContasReceber = objSelFilho;
    this.obj.valorFin = this.objContasReceber.valor;
  }

}