import { FiscalNfe } from '../fiscal/fiscalNfe';
import { Padrao } from '../generic/padrao';
import { OrdemServico } from '../os/ordemServico';
import { Pessoa } from '../pessoa/pessoa';
import { Venda } from '../venda/venda';
import { Conta } from './conta';
import { TipoCondPagto } from './tipoCondPagto';

export class ContasReceber extends Padrao{

    override idTab = 110;

    pessoa: Pessoa = new Pessoa();
    tipoCondPagto: TipoCondPagto = new TipoCondPagto();
    idNfe: number;
    idOs: number;
    idVenda: number;
    dataEmissao: Date;
    dataVenc: Date;
    nTitulo: string;
    parcela: string;
    valor: number;
    status: string;
    juros: number;
    multa: number;
    valorAtual: number;
    envBanco: string;
    tipo: string;
    receita: string;
    categ: string;
    valorCartorio: number;
    dias: number;
    obs: string;
    boletosGer: string;
    dataImpBol: Date;
    dataEnvBanco: Date;
    impBol: string;  
}