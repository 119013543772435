import { AutoRegisterClass } from 'src/app/services/generics/classRegistry';
import { Padrao } from '../generic/padrao';

@AutoRegisterClass()
export class TipoCondPagto extends Padrao{

    override idTab = 98;

    descricao: string;
    taxa: number;
    desconto: number;
    tipo: string;
    sistema: string;
    ativa: string;
    maxParcelas: number;
    ordem: number;
    intervalo: number;
    nParcelaPadrao: number;
    classificacao: string;
    descIngles: string;
    variavel: string;
    nfForma: string;
    regraPrazos: string;
}