import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/security/auth.guard';
import { ListaAgenciaComponent } from './listaagencia';
import { ListaDepartamentoComponent } from './listadepartamento';
import { ListaFormaEnvioComponent } from './listaformaenvio';
import { ListaFuncaoComponent } from './listafuncao';
import { ListaModeloEtiquetaComponent } from './listamodeloetiqueta';
import { ListaVeiculoComponent } from './listaveiculo';


@NgModule({
    imports: [RouterModule.forChild([
        { path: 'listaagencia', component: ListaAgenciaComponent, canActivate: [AuthGuard] },
        { path: 'listadepartamento', component: ListaDepartamentoComponent, canActivate: [AuthGuard] },
        { path: 'listaformaenvio', component: ListaFormaEnvioComponent, canActivate: [AuthGuard] },
        { path: 'listafuncao', component: ListaFuncaoComponent, canActivate: [AuthGuard] },
        { path: 'listaveiculo', component: ListaVeiculoComponent, canActivate: [AuthGuard] },
        { path: 'listamodeloetiqueta', component: ListaModeloEtiquetaComponent, canActivate: [AuthGuard] }
    ])],
    exports: [RouterModule]
})
export class GeralListaRoutingModule { }
