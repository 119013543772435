import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { ChipsModule } from "primeng/chips";
import { ChipModule } from "primeng/chip";
import { DropdownModule } from "primeng/dropdown";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { MultiSelectModule } from "primeng/multiselect";
import { InputTextareaModule } from "primeng/inputtextarea";
import { InputTextModule } from "primeng/inputtext";
import { RatingModule } from 'primeng/rating';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TranslocoModule } from '@ngneat/transloco';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { FormFuncaoComponent } from './formfuncao';
import { GeralFormRoutingModule } from './geralform-routing.module';
import { MenubarModule } from 'primeng/menubar';
import { FormAgenciaComponent } from './formagencia';
import { FormDepartamentoComponent } from './formdepartamento';
import { GenericModule } from '../../generic/generic.module';
import { FormFormaEnvioComponent } from './formformaenvio';
import { FormVeiculoComponent } from './formveiculo';
import { FormModeloEtiquetaComponent } from './formmodeloetiqueta';
import { DialogModule } from 'primeng/dialog';
import { BadgeModule } from 'primeng/badge';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,

		GenericModule,
		GeralFormRoutingModule,

		AutoCompleteModule,
		CalendarModule,
		ChipsModule,
		DropdownModule,
		InputMaskModule,
		InputNumberModule,
		ColorPickerModule,
		CascadeSelectModule,
		MultiSelectModule,
		ToggleButtonModule,
		SliderModule,
		InputTextareaModule,
		RadioButtonModule,
		InputTextModule,
		RatingModule,
		ChipModule,
		KnobModule,
		InputSwitchModule,
		ListboxModule,
		SelectButtonModule,
		CheckboxModule,
		ButtonModule,
		TableModule,
		InputNumberModule,
		DropdownModule,

		TranslocoModule,
		ConfirmDialogModule,
		MenuModule,
		MenubarModule,
		DialogModule,
		BadgeModule
	],
	declarations: [
		FormAgenciaComponent,
		FormDepartamentoComponent,
		FormFuncaoComponent,
		FormFormaEnvioComponent,
		FormVeiculoComponent,
		FormModeloEtiquetaComponent
	]
})
export class GeralFormModule { }
