import { Funcao } from './../../model/geral/funcao';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class FuncaoService extends GenericService<Funcao>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("funcao")
  }
}
