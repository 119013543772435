import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/security/auth.guard';
import { FormTipoPessoaComponent } from '../../pessoa/forms/formtipopessoa';
import { FormAgenciaComponent } from './formagencia';
import { FormConceitoPessoaComponent } from '../../pessoa/forms/formconceitopessoa';
import { FormDepartamentoComponent } from './formdepartamento';
import { FormFormaEnvioComponent } from './formformaenvio';
import { FormFuncaoComponent } from './formfuncao';
import { FormVeiculoComponent } from './formveiculo';
import { FormModeloEtiquetaComponent } from './formmodeloetiqueta';


@NgModule({
    imports: [RouterModule.forChild([
        { path: 'formagencia', component: FormAgenciaComponent, canActivate: [AuthGuard] },
        { path: 'formconceitopessoa', component: FormConceitoPessoaComponent, canActivate: [AuthGuard] },
        { path: 'formdepartamento', component: FormDepartamentoComponent, canActivate: [AuthGuard] },
        { path: 'formformaenvio', component: FormFormaEnvioComponent, canActivate: [AuthGuard] },
        { path: 'formfuncao', component: FormFuncaoComponent, canActivate: [AuthGuard] },
        { path: 'formveiculo', component: FormVeiculoComponent, canActivate: [AuthGuard] },
        { path: 'formmodeloetiqueta', component: FormModeloEtiquetaComponent, canActivate: [AuthGuard] },
    ])],
    exports: [RouterModule]
})
export class GeralFormRoutingModule { }
