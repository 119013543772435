import { Padrao } from '../generic/padrao';
import { Pessoa } from '../pessoa/pessoa';
import { Arquivo } from './arquivo';

export class Importacao extends Padrao{

    override idTab = 279;

    pessoaAgenteCarga: Pessoa | null = new Pessoa();
    pessoaAgenteCarga2: Pessoa | null = new Pessoa();
    pessoaAgenteAduaneiro: Pessoa | null = new Pessoa();
    pessoaAgenteAlfandegado: Pessoa | null = new Pessoa();
    pessoaTranspBr: Pessoa | null = new Pessoa();
    numero: string;
    status: string;
    
    statusCarga: string; // subcolnsulta
    statusAdua: string; // subcolnsulta
    statusAlfandegado: string; // subcolnsulta

    dataAb: Date = new Date();
    dataFech: Date;
    dataChegada: Date;
    valorUnitWr: number = 0;
    obs: string;  
    canal: string;
    modal: string;

    quantHist: number = 0; // subcolnsulta
    quantHistTot: number = 0; // subcolnsulta

    totFatInv: number = 0;  // subcolnsulta
    totNfe: number = 0;  // subcolnsulta

    listaDownload: Arquivo[] = [];
}