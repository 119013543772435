<p-table #dt id="dt" [value]="list" [resizableColumns]="true" [rowHover]="true" dataKey="id" editMode="row"
styleClass="p-datatable-striped p-datatable-sm" responsiveLayout="stack" [breakpoint]="'768px'">

    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>{{constantsAero.PREFIX_FIELD + '5791' | transloco }}</th>
            <th></th>
            <th>{{'file' | transloco }}</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-expanded="expanded">
        <tr [pEditableRow]="rowData">
            <td><button type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-plain buttonRow" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" pTooltip="{{'click_view_answer' | transloco}}"></button></td>    
            <td style="width: 50%;">
                <div class="grid p-fluid mt-2 pr-3 pl-1 mb-2">
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                        {{constantsAero.PREFIX_FIELD + '5821' | transloco }}
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-2">
                        <span class="font-bold">{{rowData?.fiscalInvoiceItens?.adendoPecas?.pn}}</span>
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-7">
                        <span class="font-bold">{{rowData?.fiscalInvoiceItens?.adendoPecas?.descricao}}</span>
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-3">
                        <span class="font-bold">{{rowData?.fiscalInvoiceItens?.adendoPecas?.fabricante?.nome}}</span>
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                        <textarea pInputTextarea [(ngModel)]="rowData.pergunta" cols="70" rows="5" [disabled]="true"></textarea>
                    </div>
                </div>
            </td>
            <td style="width: 20%;">
                <div class="grid p-fluid mt-2 pr-3 pl-1 mb-2">
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                        {{constantsAero.PREFIX_FIELD + '5793' | transloco }}
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                        <p-tag styleClass="mr-2" severity="{{getOptionTag(rowData.status)}}"><span class="font-bold">{{rowData.status}}</span></p-tag>
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                        {{constantsAero.PREFIX_FIELD + '5795' | transloco }}
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                        <span class="font-bold">{{rowData.dataPergunta  | date: globalFunctions.getDataFormat() }}</span>
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                        {{constantsAero.PREFIX_FIELD + '5789' | transloco }}
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                        <span class="font-bold">{{rowData.pessoaPergunta.nome }}</span>
                    </div>
                </div>
            </td>
            <td><div style="max-width: 220px;">
                    <app-lista-arquivos [obj]="rowData" [api]="api" [infoExtra]="[idImp.toString(), '0']" [atualizar]="true" [numLinhas]="1" [paginacao]="true" [largMd]="12" [largLg]="12" [excluirFile]="rowData.status=='Aberto'">
                    </app-lista-arquivos>
                </div>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-rowData>
        <tr [ngStyle]="{backgroundColor:'var(--green-50)'}">
            <td></td>
            <td colspan="7">
                <div class="grid p-fluid mt-2 pr-3 pl-1 mb-2">
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-1">
                        <label> {{ 'button_functions' | transloco }} </label><br/>
                        <p-tieredMenu  id="menuFunctionsHistory" #menuFunctionsHistory [popup]="true" [model]="itemsFunctionsHistory" appendTo = "body"></p-tieredMenu>
                        <button pButton type="button" icon="pi pi-cog" class="p-button-outlined buttonRow" title="{{ 'button_functions_items' | transloco }}" (click)="selectObj(rowData); menuFunctionsHistory.toggle($event)"></button>
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-6">
                        <label> {{constantsAero.PREFIX_FIELD + '5792' | transloco }} </label><br/>
                        <textarea pInputTextarea [(ngModel)]="rowData.resposta" rows="5" [disabled]="true"></textarea>
                    </div>
                    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
                        <div class="grid p-fluid mt-2 pr-3 pl-1 mb-2">
                            <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                                <span *ngIf="rowData.dataResposta">{{constantsAero.PREFIX_FIELD + '5796' | transloco }}</span>
                            </div>
                            <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                                <span *ngIf="rowData.dataResposta" class="font-bold">{{rowData.dataResposta  | date: globalFunctions.getDataFormat() }}</span>
                            </div>
                            <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                                <span *ngIf="rowData.pessoaResposta">{{constantsAero.PREFIX_FIELD + '5790' | transloco }}</span>
                            </div>
                            <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                                <span *ngIf="rowData.pessoaResposta" class="font-bold">{{rowData.pessoaResposta.nome}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="max-width: 230px;" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-3">
                        <app-lista-arquivos [obj]="rowData" [api]="api" [infoExtra]="[idImp.toString(), '1']" [atualizar]="false" [numLinhas]="1" [paginacao]="true" [largMd]="12" [largLg]="12" [excluirFile]="rowData.status=='Aberto'">
                        </app-lista-arquivos>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>                        
            
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8" style="text-align:left">{{'title_no_records' | transloco }}</td>
        </tr>
    </ng-template>

</p-table>