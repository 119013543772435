import { Padrao } from '../generic/padrao';

export class FiscalGrupoCfop extends Padrao{

    override idTab = 161;

    descricao: string;
    tipoMov: string;
    internaExterna: string;
    resumo: string;
}