import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { FormGenerico } from '../../../generic/formgenerico/formgenerico';
import { ImportacaoHistorico } from 'src/app/model/importacao/importacaoHistorico';
import { ImportacaoHistoricoService } from 'src/app/services/importacao/importacao-historico.service';
import { ConstantsAero } from '../../../generic/constantsAero';
import { GlobalFunctions } from '../../../generic/global-functions';
import { MenuItem } from "primeng/api/menuitem";
import { FormImportacaoHistoricoComponent } from '../formimportacaohistorico/formimportacaohistorico.component';
import { Padrao } from 'src/app/model/generic/padrao';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-form-imphistoricoresp',
  templateUrl: './formimportacaohistoricoresp.component.html',
  styleUrls: ['../../../generic/formgenerico/formgenerico.component.css']
})

export class FormImportacaoHistoricoRespComponent extends FormGenerico<ImportacaoHistorico> implements OnInit, OnChanges {

  @Input() idImp: number;

  list: ImportacaoHistorico[] = [];
  files: any[];
  objTemp = new ImportacaoHistorico();

  idSel: number;

  itemsFunctionsHistory: MenuItem[];
  
  public refDialog: DynamicDialogRef;
  public configDialog: DynamicDialogConfig;

  constructor(
    injectorObj: Injector,
    public api: ImportacaoHistoricoService
    ) {
      super(injectorObj, api);

      try {
        this.refDialog    = this.injectorObj.get(DynamicDialogRef);
        this.configDialog = this.injectorObj.get(DynamicDialogConfig);
      } catch (error) {
      }    
  }

  ngOnInit(): void {
    this.itemsFunctionsHistory = [
      { label: this.translocoService.translate('to_respond'), command: () => { this.showHistory('R') } },
      { separator: true },
      { label: this.translocoService.translate('update_answered'), command: () => { this.showHistory('A') } },
    ];

    if ((this.configDialog) && (this.configDialog.data)) {
      this.idImp = this.configDialog.data.idImp;
      this.list  = this.configDialog.data.list;
    }
    this.findAllImpHistorico();
  }

  ngOnChanges() {
      this.findAllImpHistorico();
  } 

  dataExtraObj(res: any) {
  }

  //Lista Importação Historico
  findAllImpHistorico() {
    if ((this.idImp) && (this.idImp > 0)) {
      this.api.findAllByImportacao(this.idImp)
        .toPromise()
        .then
        ((res: any) => {
          this.list = res;
        });
    }
  }

  getOptionTag(valor: any) {  
    if (valor == 'Respondido')
        return 'success'
    else 
      return 'danger'
  }

  showHistory(acaoExec: string) {
    if (((acaoExec == 'R') && (!this.verificarAltResp())) || (acaoExec == 'A') || (acaoExec == 'P')) {
      let idHist, fn;
      if (acaoExec == 'P') {
        idHist = 0;
        fn = undefined;
      }
      else {
        idHist = this.obj.id
        fn = () => this.atualizarObjHist(idHist);
      }
      this.showDialogDef(FormImportacaoHistoricoComponent, {acao: acaoExec, id: idHist, idImportacao: this.idImp, infoExtra: [this.idImp] }, '', fn);
    }
    else {
      this.errorHandler.handle(this.translocoService.translate('question_answered'));
    }
  }

  selectObj(objImpHist: ImportacaoHistorico) {
    this.obj = objImpHist;
  }

  atualizarObjHist (id: number) {
    this.api.findById(this.auth.empresa.id, id)
    .toPromise()
    .then
    ((res: any) => {
      this.objTemp = res;
      this.obj.status = this.objTemp.status;
      this.obj.resposta = this.objTemp.resposta;
      this.obj.dataResposta = this.objTemp.dataResposta;
      this.obj.pessoaResposta = this.objTemp.pessoaResposta;   
    })
  }

  verificarAltResp(): boolean {
    return ((this.obj) && (this.obj.status == 'Respondido'));
  }

}
