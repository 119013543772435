import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { Agencia } from '../../model/geral/agencia';

@Injectable({
  providedIn: 'root'
})
export class AgenciaService extends GenericService<Agencia>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("agencia")
  }
}
