import { Padrao } from '../generic/padrao';
import { Fabricante } from './fabricante';

export class FabricanteDesconto extends Padrao{

    override idTab = 145;

    fabricante: Fabricante = new Fabricante();
    letra: string;
    perc: number;
}