<!-- <p-dialog header="{{ titleDialog | transloco }}" [(visible)]="display" (onHide)="closeDialog()" [modal]="true"
  [maximizable]="true" [style]="{width: '100vw'}" [breakpoints]="{'960px': '85vw', '640px': '100vw'}"> -->
<p-menubar *ngIf="showMenubar">
  <ng-template pTemplate="start">
    <div class="flex">
      <button type="button" pButton icon="pi pi-save" (click)="saveButtonClick()"></button>
    </div>
  </ng-template>
</p-menubar>
<p-table #dt id="dt" [resizableColumns]="true" [value]="data" [(selection)]="selectedItems" dataKey="id"
  [rowHover]="true" styleClass="p-datatable-striped p-datatable-sm" [globalFilterFields]="columnsToShow"
  responsiveLayout="stack" [breakpoint]="'768px'" [rows]="17" [paginator]="true" [scrollable]="true"
  [selectionMode]="'single'">
  <ng-template pTemplate="caption">
    <span class="p-input-icon-left ml-auto">
      <i class="pi pi-search"></i>
      <input pInputText type="text" (input)="onFilterInput($event)" />
    </span>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem" *ngIf="showCheckboxes">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <!-- Adiciona uma coluna extra vazia se useButton for verdadeiro -->
      <th *ngIf="useButton">
      </th>
      <th *ngFor="let titleKey of headerColumns; let i = index"
        [style.text-align]="shouldAlignRightTitle(titleKey, i) ? 'right' : 'left'">
        {{ translateKey(titleKey) }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    <tr>
      <td *ngIf="showCheckboxes">
        <p-tableCheckbox [value]="row" (onSelect)="onRowSelect($event)"
          (onUnselect)="onRowUnselect($event)"></p-tableCheckbox>
      </td>
      <ng-container *ngIf="useButton">
        <td>
          <ng-container *ngTemplateOutlet="buttonTemplate; context: { $implicit: row }"></ng-container>
        </td>
      </ng-container>
      <ng-template #buttonTemplate let-row>
        <td>
          <button pButton pRipple icon="pi pi-check" class="p-button-outlined p-button-secondary p-button-success "
            style="width: 30px; height: 30px !important;" (click)="buttonClickHandler(row)"></button>
        </td>
      </ng-template>

      <td *ngFor="let column of columnsToShow"
        [ngStyle]="{ backgroundColor: getColorForCell(column, row[column]), color: shouldApplyTextColor(column) ? 'black' : 'inherit'  }">
        <div [style.text-align]="shouldAlignRight(column) ? 'right' : 'left'">
          {{ formatDateOrNumberValue(column, getValueWithCheck(row, column)) }}

        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="loading-overlay" *ngIf="loading"
  [ngStyle]="{'position': 'fixed', 'top': '0', 'left': '0', 'width': '100%', 'height': '100%', 'background-color': 'rgba(22, 17, 17, 0.5)', 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'z-index': '9999'}">
  <div class="loading-content"
    [ngStyle]="{'display': 'flex', 'flex-direction': 'column', 'align-items': 'center', 'justify-content': 'center'}">
    <p-progressSpinner></p-progressSpinner>
    <p style="color: white;">{{ progressMessage }}</p>
  </div>
</div>
<!-- </p-dialog> -->