import { Padrao } from '../generic/padrao';

export class FiscalSimplesNacional extends Padrao{

    override idTab = 189;

    receitaIni: number;
    receitaFim: number;
    aliqTotal: number;
    aliqTotMenosIss: number;
    irpj: number;
    csll: number;
    cofins: number;
    pis: number;
    cpp: number;
    icms: number;
    ipi: number;
    iss: number;
    areaAtuacao: string;
    ativa: string;
    validade: Date;
}