import { Veiculo } from './../../model/geral/veiculo';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class VeiculoService extends GenericService<Veiculo>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("veiculo")
  }
}
