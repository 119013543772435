import { MessageService } from 'primeng/api';
import { Injector, OnInit, Component, Input, Output, EventEmitter} from '@angular/core';
import { SysCampoService } from "src/app/services/system/sys-campo.service";
import { SysTabelaService } from "src/app/services/system/sys-tabela.service";
import { TranslocoService } from "@ngneat/transloco";
import { ErrorHandlerService } from "src/app/services/system/error-handler.service";
import { AuthService } from "src/app/services/security/auth.service";
import { Padrao } from "src/app/model/generic/padrao";
import { SysCampo } from "src/app/model/system/sysCampo";
import { SysTabela } from "src/app/model/system/sysTabela";
import { DadosPesquisa } from "src/app/model/system/dadosPesquisa";
import { GlobalFunctions } from '../global-functions';
import { ConstantsAero } from '../constantsAero';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: [ '../listagenerica/listasimples.component.css']
})
export class ListaComponent implements OnInit {

  title = "";

  @Input() objSel: Padrao;
  @Input() api: any;
  @Input() viewChek: boolean = false;

  list: Padrao[] = [];
  listSel: Padrao[];

  cols: any[];

  colsFilter: any[] = [];
  colsDataFilter: any[] = [];

  dadosPesquisa = new DadosPesquisa();
    
  listSysCampo: SysCampo[] = [];
  objSysTabela: SysTabela;
  
  messageService: MessageService;
  translocoService: TranslocoService;

  apiSysFiels: SysCampoService;
  apiSysTabela: SysTabelaService;

  errorHandler: ErrorHandlerService;

  auth: AuthService;

  protected constantsAero: ConstantsAero = new ConstantsAero();
  protected globalFunctions: GlobalFunctions;

  constructor(
    protected injectorObj: Injector,
    public refDialog: DynamicDialogRef,
    public configDialog: DynamicDialogConfig
    ) 
  {
    this.messageService = this.injectorObj.get(MessageService);
    this.translocoService = this.injectorObj.get(TranslocoService);
    this.apiSysFiels = this.injectorObj.get(SysCampoService);
    this.apiSysTabela = this.injectorObj.get(SysTabelaService);
    this.errorHandler = this.injectorObj.get(ErrorHandlerService);
    this.auth = this.injectorObj.get(AuthService);
  }

  ngOnInit(): void {
    this.globalFunctions = new GlobalFunctions(this.translocoService, this.injectorObj);

    if (this.configDialog.data) {
      this.objSel = this.configDialog.data.objSel;
      this.api    = this.configDialog.data.api;
      this.viewChek = this.configDialog.data.viewChek;

      this.findTable();
    }

  }

  selectedAndClose(objSel: any) {
    if (this.viewChek) {
      this.refDialog.close(this.listSel);
    }
    else {
      this.refDialog.close(objSel.id);
      //console.log("objSel lista: ", objSel.id);
      
    }
  }

/*   selectedAndClose(objSel: any) {
    this.atualizarObjPai.emit(objSel); // Emitir o evento com o objeto selecionado
    this.refDialog.close(objSel); // Fechar o diálogo com o objeto selecionado
  } */

  find() {
    const dynamicService = typeof this.api === 'string' ? this.globalFunctions.returnApi(this.api) : this.api;

    if (!dynamicService) {
      this.errorHandler.handle(this.translocoService.translate('message_api_not_found'));
      return;
    }

    if (this.dadosPesquisa.campo === 'id') {
      let cod: number = parseInt(this.dadosPesquisa.valor);
      if (Number.isInteger(cod)) {
        this.findByIdList(cod, dynamicService);
      } else {
        this.errorHandler.handle(this.translocoService.translate('message_enter_only_numbers_integer'));
      }
    } else {
      dynamicService.findFilterAll(this.auth.empresa.id, this.dadosPesquisa)
        .toPromise()
        .then((res: any) => {
          this.list = res;
        })
        .catch((error) => {
          this.errorHandler.handle(error);
        });
    }
  }

  findByIdList(id: number, dynamicService: any) {
    dynamicService.findById(this.auth.empresa.id, id)
      .toPromise()
      .then((res: any) => {
        this.list = [];
        if (res) {
          this.objSel = res;
          this.list.push(this.objSel);
        }
      })
      .catch((error) => {
        this.errorHandler.handle(error);
      });
  }

  findById(id: number) {
    this.api.findById(this.auth.empresa.id, id)
      .toPromise()
      .then
      ((res: any) => {
        this.objSel = res;
        this.objSel.dataCad = new Date(res.dataCad);
      })
  }

  findTable() {
    this.apiSysTabela.findListById(this.objSel.idTab)
      .toPromise()
      .then
      ((res: any) => {
        this.objSysTabela = res;
        const t = this.constantsAero.PREFIX_TABLE + this.objSysTabela.id;
        this.title = this.translocoService.translate(t);

        this.findFields();
      });
  }

  findFields() {
    this.apiSysFiels.findListDefaultByIdTable(this.objSel.idTab)
      .toPromise()
      .then
      ((res: any) => {
        this.listSysCampo = res;

        this.cols = this.convertFieldsToColsTable(this.listSysCampo);
        this.convertFieldsToColsFilter();

        if (this.objSysTabela.pesqAuto == 'SIM') {
            this.find();
        }
      });
  }

  convertFieldsToColsTable(listSysCampoTemp: SysCampo[]) {
    return listSysCampoTemp.map(c => {
      const idField = c.id;
      let label = this.constantsAero.PREFIX_FIELD + idField;

      if (c.nomeProp == 'dataCad') {
        label = this.translocoService.translate('field_default_dateIn');
      }
      if (c.nomeProp == 'usuarioCad') {
        label = this.translocoService.translate('field_default_user');
      }
      if (c.nomeProp == 'empresaCad') {
        label = this.translocoService.translate('field_company');
      }

      return { field: c.nomeProp, header: label, width: c.tamHorzWeb + '%', type: c.tipoWeb,
               fieldId: c.nomePropId, fieldView: c.nomePropVisao, widthList: c.tamHorz}
    })    
  }

  convertFieldsToColsFilter() {
    let i = 0;
    this.colsFilter = [];
    this.colsDataFilter = [];

    let label = this.translocoService.translate('all_items');
    this.colsFilter.push({ 'value': '*', 'label': label});
    
    label = this.translocoService.translate('bar_code');
    this.colsFilter.push({ 'value': 'id', 'label': label});

    return this.listSysCampo.map(c => {
      const idField = c.id;
      label = this.translocoService.translate(this.constantsAero.PREFIX_FIELD + idField);
      if (c.prefixo != 'CALCULADO') {
        if (c.tipoWeb == 'Data') {
          if (c.nomeProp == 'dataCad') {
            label = this.translocoService.translate('field_default_dateIn');
          }
          this.colsDataFilter.push({ 'value': c.nomeProp, 'label': label});
          i++;
          if (i == 1) {
            this.dadosPesquisa.dataInicial.setDate(new Date().getDate() - c.intervaloDataInicio);
            this.dadosPesquisa.dataFinal.setDate(new Date().getDate() + c.intervaloDataTermino);
            this.dadosPesquisa.campoData = c.nomeProp;
          }
        }
        else {
          if (c.nomeProp == 'usuarioCad') {
            label = this.translocoService.translate('field_default_user');
          }
          if (c.nomeProp == 'empresaCad') {
            label = this.translocoService.translate('field_company');
          }
          this.colsFilter.push({ 'value': c.nomeProp, 'label': label});
        }
      }
    })    
  }
  
}