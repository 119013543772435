import { EndCidade } from '../endereco/endCidade';
import { FiscalSimplesNacional } from '../fiscal/fiscalSimplesNacional';
import { Padrao } from '../generic/padrao';
import { Pessoa } from '../pessoa/pessoa';

export class Empresa extends Padrao{

    override idTab = 3;

    endCidade: EndCidade = new EndCidade();
    pessoaCont: Pessoa = new Pessoa();
    pessoaDir: Pessoa = new Pessoa();
    pessoa: Pessoa = new Pessoa();
    fiscalSimplesNacional: FiscalSimplesNacional = new FiscalSimplesNacional();
    razao: string;
    nome: string;
    cnpj: string;
    iest: string;
    imun: string;
    endereco: string;
    bairro: string;
    cidade: string;
    uf: string;
    cep: string;
    email: string;
    site: string;
    status: string;
    logoPrincipal: string;
    logoRelatorio: string;
    fone: string;
    fone2: string;
    numHomologado: string;
    regimeTributario: string;
    substitutoTributario: string;
    cor: string;
    corWeb: string;
    tipo: string;
}