import { AdendoPecas } from '../adendo/adendoPecas';
import { Fabricante } from '../estoque/fabricante';
import { ContasPagar } from '../financeiro/contasPagar';
import { FiscalInvoiceItens } from '../fiscal/fiscalInvoiceItens';
import { Padrao } from '../generic/padrao';
import { Pessoa } from '../pessoa/pessoa';

export class ImportacaoHistorico extends Padrao{

    override idTab = 283;

    fiscalInvoiceItens: FiscalInvoiceItens | null;
    pessoaPergunta: Pessoa;
    pessoaResposta: Pessoa;
    idImportacao: number;//Variavel Auxiliar
    pergunta: string;
    resposta: string;
    status: string = 'Aberto';
    ordem: number = 0;
    dataPergunta: Date = new Date();
    dataResposta: Date = new Date();
}