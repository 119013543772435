import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dialog-generico',
  templateUrl: './formgenericdialog.component.html',
  styleUrls: ['../../generic/formgenerico/formgenerico.component.css']
})
export class DialogGenericoComponent implements OnInit {

  @Input() display: boolean;
  @ViewChild('dt') dt: Table;
  @Input() titleDialog: string;
  @Input() headerKeys: number[];
  @Input() columnsToShow: string[];
  @Output() onButtonClick = new EventEmitter<any>();
  @Input() useButton: boolean = false;
  @Input() cellColorColumns: string[];
  @Input() valueFormatHeader: string[];
  @Input() showMenubar: boolean = true;
  @Input() valueFormatColumns: string[];
  @Input() showCheckboxes: boolean = true;
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() selectedItemsChange = new EventEmitter<any[]>();
  @Output() onSaveButtonClick = new EventEmitter<any[]>(); // Certifique-se de que isso é um EventEmitter
  @Input() fetchDataFn: () => Observable<any[]>;
  @Input() dateFormatColumns: string[] = [];
  @Input() numberFormatColumns: string[] = [];

  estoqueEmpAtiva: string;
  data: any[] = [];
  headerColumns: string[] = [];
  selectedItems: any[] = [];
  loading: boolean = false; 
  progressMessage: string = '';

  public viewportHeight: number = 0;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private translocoService: TranslocoService
  ) { }

  ngOnInit() {
    const dialogData = this.config.data;

    this.titleDialog = dialogData.title;
    this.headerKeys = dialogData.headerKeys;
    this.columnsToShow = dialogData.columnsToShow;
    this.cellColorColumns = dialogData.cellColorColumns;
    this.valueFormatHeader = dialogData.valueFormatHeader;
    this.valueFormatColumns = dialogData.valueFormatColumns;
    this.dateFormatColumns = dialogData.dateFormatColumns;
    this.numberFormatColumns = dialogData.numberFormatColumns;
    this.showCheckboxes = dialogData.showCheckboxes;
    this.useButton = dialogData.useButton;
    this.showMenubar = dialogData.showMenubar;
    this.fetchDataFn = dialogData.fetchDataFn;

    this.headerColumns = this.headerKeys.map(key => `label_sysfield_${key}`);

    if (this.fetchDataFn) {
      this.loadData(this.fetchDataFn);
    } else {
      //console.error('fetchDataFn is not defined.');
    }

    if (dialogData.onSaveButtonClick) {
      this.onSaveButtonClick.subscribe(dialogData.onSaveButtonClick); // Inscrever a função passada no EventEmitter
    }
  }

  onFilterInput(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const inputValue = event.target.value;
      this.dt.filterGlobal(inputValue, 'contains');
    }
  }

  closeDialog() {
    this.display = false;
    this.selectedItemsChange.emit(this.selectedItems);
    this.displayChange.emit(this.display);
    this.ref.close();
  }

  translateKey(key: string): string {
    return this.translocoService.translate(key);
  }

  loadData(fetchDataFn: () => Observable<any[]>) {
    this.loading = true; 
    this.display = true;

    fetchDataFn().subscribe(
      (res: any) => {
        this.data = res;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  onRowSelect(event: any) {
    const selectedRow = this.getSelectedRow(event.data, this.columnsToShow);
    this.selectedItems.push(selectedRow);
  }

  getSelectedRow(row: any, columns: string[]): any {
    const selectedRow: any = {};

    columns.forEach(column => {
      const keys = column.split('.');
      let value = this.getValueForKey(row, keys);

      if (typeof value === 'object' && value !== null) {
        const nestedValue = this.getValueForKey(value, ['nome']);
        if (nestedValue !== undefined) {
          value = nestedValue;
        }
      }

      selectedRow[column] = value;
    });

    return selectedRow;
  }

  getValueWithCheck(row: any, column: string): any {
    const keys = column.split('.');
    let value = this.getValueForKey(row, keys);

    if (typeof value === 'object' && value !== null) {
      const nestedValue = this.getValueForKey(value, ['nome']);
      if (nestedValue !== undefined) {
        value = nestedValue;
      }
    }

    return (value !== null && value !== undefined && value !== 'N/A') ? value : '';
  }

  getValueForKey(obj: any, keys: string[]): any {
    let value = obj;

    for (const key of keys) {
      if (value && typeof value === 'object' && key in value) {
        value = value[key];
      } else {
        return 'N/A';
      }
    }

    return value !== null && value !== undefined ? value : 'N/A';
  }

  onRowUnselect(event: any) {
    this.selectedItems = this.selectedItems.filter(item => item.id !== event.data.id);
  }

  saveButtonClick() {
    this.onSaveButtonClick.emit(this.selectedItems); // Emitindo os itens selecionados
    this.ref.close();
  }
  
  buttonClickHandler(row: any) {
    this.onButtonClick.emit(row);
    this.ref.close();
  }

  getColorForCell(column: string, valor: any): string | undefined {
    if (this.cellColorColumns && this.cellColorColumns.includes(column)) {
      return this.getGenericColor(valor);
    }
    return undefined;
  }

  private getGenericColor(valor: any): string | undefined {
    if (valor == 'FATURADO' || valor == 'Faturado')
      return '#3e9dc1';
    else if (valor == 'FINALIZADO' || valor == 'Finalizado')
      return '#bbff9d';
    else if (valor == 'PRONTO' || valor == 'Pronto')
      return '#0a8c0a';
    else if (valor == 'PARCIAL' || valor == 'Parcial')
      return '#8eb0d7';
    else if (valor == 'AUTORIZADO' || valor == 'Autorizado')
      return '#ffff80';
    else if (valor == 'ESTOQUE' || valor == 'Estoque')
      return '#ced7ff';
    else if (valor == 'ENTREGUE' || valor == 'Entregue')
      return '#ced7ff';
    else if (valor == 'CANCELADO' || valor == 'Cancelado')
      return '#fec58b';
    else if (valor == 'IMPORTAÇÃO' || valor == 'Importação')
      return '#8080c0';
    else if (valor == 'BACKORDERS' || valor == 'BackOrders')
      return '#8eb0d7';
    else if (valor == 'RECEITA' || valor == 'Receita')
      return '#fec58b';
    else if (valor == 'TRANSFERÊNCIA' || valor == 'Transferência')
      return '#3e9dc1';
    else if (valor == 'DESPESA' || valor == 'Despesa')
      return '#fec58b';
    else
      return undefined;
  }

  formatDateValue(value: any): string {
    if (value instanceof Date) {
      return this.formatDate(value);
    } else if (typeof value === 'string' && !isNaN(Date.parse(value))) {
      return this.formatDate(new Date(value));
    } else {
      return value;
    }
  }

  private formatDate(value: any): string {
    const date = new Date(value);
    return date.toLocaleDateString('pt-BR');
  }

  formatNumberValue(value: any, includeCurrencySymbol: boolean = true, alignRight: boolean = false): string {
    if (typeof value === 'number') {
      const formattedValue = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

      const alignedValue = alignRight ? this.alignValue(formattedValue) : formattedValue;

      return includeCurrencySymbol ? `${alignedValue}` : alignedValue;
    } else {
      return value;
    }
  }

  private alignValue(value: string): string {
    const maxLength = 15;

    if (value.length < maxLength) {
      const spacesToAdd = maxLength - value.length;
      return ' '.repeat(spacesToAdd) + value;
    } else {
      return value;
    }
  }

formatDateOrNumberValue(column: string, value: any): string {
  // Verifica se a coluna está na lista de formatação de data
  if (this.dateFormatColumns.includes(column) && this.isDate(value)) {
    return this.formatDate(value);
  }
  // Verifica se a coluna está na lista de formatação de número
  else if (this.numberFormatColumns.includes(column) && this.isNumber(value)) {
    return this.formatNumberValue(value);
  }
  // Retorna o valor sem formatação caso não se aplique
  return value;
}

  private isDate(value: any): boolean {
    return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)));
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  shouldAlignRight(column: string): boolean {
    return this.numberFormatColumns && this.numberFormatColumns.includes(column);
  }
  
  shouldAlignRightTitle(titleKey: string, index: number): boolean {
    const columnKey = this.columnsToShow[index];
    return this.numberFormatColumns && this.numberFormatColumns.includes(columnKey);
  }
  
  formatValue(column: string, value: any): string {
    if (this.shouldAlignRight(column)) {
      return this.formatNumberValue(value, false);
    } else {
      return value;
    }
  }

  shouldApplyTextColor(column: string): boolean {
    return this.cellColorColumns && this.cellColorColumns.includes(column);
  }

}
