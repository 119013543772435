import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { ChipsModule } from "primeng/chips";
import { ChipModule } from "primeng/chip";
import { DropdownModule } from "primeng/dropdown";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { MultiSelectModule } from "primeng/multiselect";
import { InputTextareaModule } from "primeng/inputtextarea";
import { InputTextModule } from "primeng/inputtext";
import { RatingModule } from 'primeng/rating';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TranslocoModule } from '@ngneat/transloco';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { GeralListaRoutingModule } from './gerallista-routing.module';
import { ListaFuncaoComponent } from './listafuncao';
import { ListaDepartamentoComponent } from './listadepartamento';
import { ListaAgenciaComponent } from './listaagencia';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { GenericModule } from '../../generic/generic.module';
import { ListaFormaEnvioComponent } from './listaformaenvio';
import { ListaVeiculoComponent } from './listaveiculo';
import { ListaModeloEtiquetaComponent } from './listamodeloetiqueta';
import { DialogModule } from 'primeng/dialog';
import { BadgeModule } from 'primeng/badge';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TimelineModule } from 'primeng/timeline';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,

		GenericModule,
		GeralListaRoutingModule,

		AutoCompleteModule,
		CalendarModule,
		ChipsModule,
		DropdownModule,
		InputMaskModule,
		InputNumberModule,
		ColorPickerModule,
		CascadeSelectModule,
		MultiSelectModule,
		ToggleButtonModule,
		SliderModule,
		InputTextareaModule,
		RadioButtonModule,
		InputTextModule,
		RatingModule,
		ChipModule,
		KnobModule,
		InputSwitchModule,
		ListboxModule,
		SelectButtonModule,
		CheckboxModule,
		ButtonModule,
		TableModule,
		TieredMenuModule,

		TranslocoModule,
		ConfirmDialogModule,
		MenuModule,
		DialogModule,
		BadgeModule,
		SplitButtonModule,
		MultiSelectModule,
		TimelineModule

	],
	declarations: [
		ListaAgenciaComponent,
		ListaDepartamentoComponent,
		ListaFuncaoComponent,
		ListaFormaEnvioComponent,
		ListaVeiculoComponent,
		ListaModeloEtiquetaComponent
	]
})
export class GeralListaModule { }
