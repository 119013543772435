import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { SysCampo } from 'src/app/model/system/sysCampo';
import { AppConfig } from 'src/app.config';
import { AutoRegisterService } from '../generics/servicesRegistry ';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

//@AutoRegisterService()
@Injectable({
  providedIn: 'root'
})
export class SysCampoService {

  constructor(private http: HttpClient) { 
  }

  findAll (): Observable<SysCampo[]> {
    return this.http.get<SysCampo[]>(AppConfig.urlBasic + 'syscampo');
  }

  findById(id: number): Observable<SysCampo> {
    const urlLocal = `${AppConfig.urlBasic}syscampo/${id}`;
    return this.http.get<SysCampo>(urlLocal);
  }
  
  findListDefaultByIdTable(idTab: number): Observable<SysCampo[]> {
    const urlLocal = `${AppConfig.urlBasic}syscampo/tabela/listapadrao/${idTab}`;
    return this.http.get<SysCampo[]>(urlLocal);
  }

  findListFastByIdTable(idTab: number): Observable<SysCampo[]> {
    const urlLocal = `${AppConfig.urlBasic}syscampo/tabela/listapesq/${idTab}`;
    return this.http.get<SysCampo[]>(urlLocal);
  }

  findListFormByIdTable(idTab: number): Observable<SysCampo[]> {
    const urlLocal = `${AppConfig.urlBasic}syscampo/tabela/form/${idTab}`;
    return this.http.get<SysCampo[]>(urlLocal);
  }

  //NOVO
  findListFormByIdTableDuplo(idTabCabecalho: number, idTabItens: number): Observable<{cabecalho: SysCampo[], itens: SysCampo[]}> {
    const urlLocal = `${AppConfig.urlBasic}syscampo/tabela/form/${idTabCabecalho}/${idTabItens}`;
    //console.log(urlLocal);
    return this.http.get<{cabecalho: SysCampo[], itens: SysCampo[]}>(urlLocal)/* .pipe(
      tap(data => console.log('Dados recebidos pelo serviço:', data))
    ) */;
  }
  
  insert (obj: SysCampo): Observable<SysCampo> {
    return this.http.post<SysCampo>(AppConfig.urlBasic + 'syscampo', SysCampo, httpOptions);
  }
  
  update (id: number, obj: SysCampo): Observable<any> {
    const urlLocal = `${AppConfig.urlBasic}syscampo/${id}`;
    return this.http.put(urlLocal, SysCampo, httpOptions);
  }
  
  delete (id: number): Observable<SysCampo> {
    const urlLocal = `${AppConfig.urlBasic}syscampo/${id}`;
    return this.http.delete<SysCampo>(urlLocal, httpOptions);
  }

}
