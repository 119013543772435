import { Injectable } from '@angular/core';

export const servicesRegistry = new Map<string, any>();

export function AutoRegisterService() {
  return function (constructor: any) {
    const serviceName = constructor.name;
    servicesRegistry.set(serviceName, constructor);
  };
}
