<!--<p-toast position="center"></p-toast>-->

<p-dataView [value]="listaArquivos" [rows]="numLinhas" layout="grid" [paginator]="paginacao" [showPageLinks]="false"
emptyMessage="{{'title_no_files' | transloco }}">
    <ng-template let-arq pTemplate="gridItem">
        <div class="col-12 md:col-{{largMd}} lg:col-{{largLg}} p-3 m-1">
                <div class="grid p-fluid p-1 card">
                    <div class="col-9 md:col-9 text-sm p-1 m-0">
                        {{arq.nome}}
                    </div>
                    <div class="col-3 md:col-3 p-1 m-0 flex justify-content-end">
                        <p-tieredMenu  id="menuFunctionsFiles" #menuFunctionsFiles [popup]="true" [model]="itemsFunctionsFiles" appendTo = "body"></p-tieredMenu>
                        <button pButton type="button" icon="pi pi-bars" class="p-button-outlined buttonRow" (click)="selectedFile(arq); menuFunctionsFiles.toggle($event)"></button>
                    </div>
                    <div class="col-12 md:col-12 m-0 p-0" style="height: 80px;">
                        <div *ngIf="getIconeTipoArquivo(arq.nome, true)!='bmp' && getIconeTipoArquivo(arq.nome, true)!='pdf'" 
                                class='m-1 mt-0 fi fi-round-md fi-size-lg fi-{{ getIconeTipoArquivo(arq.nome, true) }}'><div class='fi-content'>{{getTipoArquivo(arq.nome)}}</div>
                        </div>
                        <div *ngIf="getIconeTipoArquivo(arq.nome, true)=='pdf'" 
                                class='m-1 mt-0 fi fi-round-md fi-size-lg fi-{{ getIconeTipoArquivo(arq.nome, true) }}'>
                                <a  *ngIf="getIconeTipoArquivo(arq.nome, true)=='pdf'" (click)="selectedFile(arq); showDialogPdf()" style="cursor: pointer;">
                                    <div class='fi-content'>{{getTipoArquivo(arq.nome)}}</div>
                                </a>
                        </div>
                        <p-image class='m-1 mt-0' height="75" *ngIf="getIconeTipoArquivo(arq.nome, true)=='bmp'" [src]="arq.caminho" [preview]="true"></p-image>
                    </div>
                </div>
        </div>
    </ng-template>
</p-dataView>
