import { Component, Input, OnInit, ViewChild, Injector } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-form-viewpdf',
  templateUrl: './formviewpdf.component.html',
  styleUrls: [ '../formgenerico/formgenerico.component.css']
})
export class FormViewPdfComponent implements OnInit {

  @ViewChild('fileUploadGeral') fileUploadGeral: FileUpload;

  @Input() caminhoArqSel: string;
  
  public refDialog: DynamicDialogRef;
  public configDialog: DynamicDialogConfig;

  constructor(
    private injectorObj: Injector,
    ) {
  }

  ngOnInit(): void {
    if (this.fileUploadGeral) 
      this.fileUploadGeral.clear();

      try {
        this.refDialog    = this.injectorObj.get(DynamicDialogRef);
        this.configDialog = this.injectorObj.get(DynamicDialogConfig);
      } catch (error) {
      }    

      if (this.configDialog.data) {
        this.caminhoArqSel   = this.configDialog.data.caminhoArqSel;
      }
  } 
}