import { VeiculoService } from 'src/app/services/geral/veiculo.service';
import { Component, Injector, OnInit } from '@angular/core';
import { GeralListaModule } from './gerallista.module';
import { ListaSimplesGenerica } from '../../generic/listagenerica/listasimplesgenerica';
import { Veiculo } from 'src/app/model/geral/veiculo';

@Component({
  selector: 'app-lista-veiculo',
  templateUrl: '../../generic/listagenerica/listasimples.component.html',
  styleUrls: [ '../../generic/listagenerica/listasimples.component.css']
})

export class ListaVeiculoComponent extends ListaSimplesGenerica<Veiculo> implements OnInit {

  override obj = new Veiculo();

  constructor(
    injectorObj: Injector,
    api: VeiculoService) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override dataExtraList(res: any) {
}

  override getColorCell(valor: any) {   
  }

}
