import { EndEstado } from '../endereco/endEstado';
import { Padrao } from '../generic/padrao';
import { Pessoa } from '../pessoa/pessoa';

export class Veiculo extends Padrao{

    override idTab = 274;

    endEstado: EndEstado = new EndEstado();
    pessoa: Pessoa = new Pessoa();
    veiculo: Veiculo = new Veiculo();
    tipoTransp: string;
    tipoTracao: string;
    tipoCarroc: string;
    descricao: string;
    placa: string;
    renavan: string;
    tara: number;
    capKg: number;
    capM: number;
    antt: string;

}

