import { FiscalInvoiceItens } from './../../model/fiscal/fiscalInvoiceItens';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FiscalInvoiceItensService extends GenericService<FiscalInvoiceItens>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("fiscalinvoiceitens")
  }

  findItensPorInvoice(idCompany: number, idImp: number): Observable<FiscalInvoiceItens[]> {
    const urlLocal = `${this.url}/invoices/${idCompany}/${idImp}`;
    return this.http.get<FiscalInvoiceItens[]>(urlLocal);
  }

  findItensPorInvoiceId(idCompany: number, idInvoice: number): Observable<FiscalInvoiceItens[]> {
    const urlLocal = `${this.url}/itens/${idCompany}/${idInvoice}`;
    return this.http.get<FiscalInvoiceItens[]>(urlLocal);
  }
}  
