import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { ImportacaoHistorico } from 'src/app/model/importacao/importacaoHistorico';
import { Observable } from 'rxjs';
import { Arquivo } from 'src/app/model/importacao/arquivo';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoHistoricoService extends GenericService<ImportacaoHistorico>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("importacaohistorico")
  }

  findAllByImportacao(idImportacao: number): Observable<ImportacaoHistorico[]> {
    const urlLocal = `${this.url}/imp/${idImportacao}`;
    return this.http.get<ImportacaoHistorico[]>(urlLocal);
  }
}
