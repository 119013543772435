import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/security/auth.guard';
import { FormImportacaoComponent } from './formimportacao/formimportacao.component';
import { FormImportacaoDocumentoComponent } from './formimportacaodocumento/formimportacaodocumento.component';
import { FormImportacaoInvoiceComponent } from './formimportacaoinvoice';
import { FormImportacaoTipoDocumentoComponent } from './formimportacaotipodocumento';
import { FormImportacaoStatusComponent } from './formimportacaostatus';
import { FormImportacaoTimeLineComponent } from './formimportacaotimeline/formimportacaotimeline.component';


@NgModule({
    imports: [RouterModule.forChild([
        { path: 'formimportacaotipodocumento', component: FormImportacaoTipoDocumentoComponent, canActivate: [AuthGuard] },
        { path: 'formimportacaodocumento', component: FormImportacaoDocumentoComponent, canActivate: [AuthGuard] },
        { path: 'formimportacaoinvoice', component: FormImportacaoInvoiceComponent, canActivate: [AuthGuard] },
        { path: 'formimportacao', component: FormImportacaoComponent, canActivate: [AuthGuard] },
        { path: 'formimportacaostatus', component: FormImportacaoStatusComponent, canActivate: [AuthGuard] },
        { path: 'formimportacaotimeline', component: FormImportacaoTimeLineComponent, canActivate: [AuthGuard] }
    ])],
    exports: [RouterModule]
})
export class ImportacaoFormRoutingModule { }
