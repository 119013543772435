import { ModeloAeronave } from './../../model/aeronave/modeloAeronave';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class ModeloAeronaveService extends GenericService<ModeloAeronave>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("modeloaeronave")
  }
}
