import { ImportacaoStatus } from 'src/app/model/importacao/importacaoStatus';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { GenericService } from '../generics/generic.service';
import { ImportacaoHistStatus } from 'src/app/model/importacao/importacaoHistStatus';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoHistStatusService extends GenericService<ImportacaoHistStatus>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("importacaohiststatus")
  }

}
