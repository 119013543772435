import { SysTabela } from './sysTabela';
import { Padrao } from '../generic/padrao';

export class MenuAcesso extends Padrao{

    override idTab = 8;

    sysTabelaPrincipal: SysTabela = new SysTabela();
    sysTabelaSecundario: SysTabela = new SysTabela();
    nivel: number;
    ordem: string;
    tipo: string;
    pai: number;
    descricao: string;
    objetivo: string;
    parametros: string;
    moduloWeb: string;
}