import { Padrao } from '../generic/padrao';
import { MenuAcesso } from '../system/menuAcesso';
import { GrupoUsuario } from './grupoUsuario';

export class AcessosGrupo extends Padrao{

    override idTab = 20; 
    
    menuAcesso: MenuAcesso = new MenuAcesso();
    grupoUsuario:  GrupoUsuario = new GrupoUsuario();
    inserir: string;
    alterar: string;
    excluir: string;
    imprimir: string;
    filtrar: string;
    filtrarData: string;
    visualizar: string;

    titulo: string; // local
    filhos: AcessosGrupo[]; // local    
}