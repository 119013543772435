import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AuthGuard } from './services/security/auth.guard';
import { HomeComponent } from './demo/components/aerosys/home/home.component';

@NgModule({
    imports: [
        RouterModule.forRoot([

            { path: '', redirectTo: 'auth', pathMatch: 'full'},
            { path: '', component: AppLayoutComponent,
                children: [
                    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
//                    { path: '', loadChildren: () => import('./demo/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
                    { path: 'uikit', loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
                    { path: 'utilities', loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
                    { path: 'documentation', loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
                    { path: 'blocks', loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
                    { path: 'pages', loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) },

                    { path: 'geral', loadChildren: () => import('./demo/components/aerosys/geral/geral.module').then(m => m.GeralModule) },
                    { path: 'importacao', loadChildren: () => import('./demo/components/aerosys/importacao/importacao.module').then(m => m.ImportacaoModule) },
                    { path: 'adendo', loadChildren: () => import('./demo/components/aerosys/adendo/adendo.module').then(m => m.AdendoModule) },
                    { path: 'aeronave', loadChildren: () => import('./demo/components/aerosys/aeronave/aeronave.module').then(m => m.AeronaveModule) },
                    { path: 'compra', loadChildren: () => import('./demo/components/aerosys/compra/compra.module').then(m => m.CompraModule) },
                    { path: 'controle', loadChildren: () => import('./demo/components/aerosys/controle/controle.module').then(m => m.ControleModule) },
                    { path: 'documento', loadChildren: () => import('./demo/components/aerosys/documento/documento.module').then(m => m.DocumentoModule) },
                    { path: 'empresa', loadChildren: () => import('./demo/components/aerosys/empresa/empresa.module').then(m => m.EmpresaModule) },
                    { path: 'endereco', loadChildren: () => import('./demo/components/aerosys/endereco/endereco.module').then(m => m.EnderecoModule) },
                    { path: 'especifico', loadChildren: () => import('./demo/components/aerosys/especifico/especifico.module').then(m => m.EspecificocoModule) },
                    { path: 'estoque', loadChildren: () => import('./demo/components/aerosys/estoque/estoque.module').then(m => m.EstoquecoModule) },
                    { path: 'financeiro', loadChildren: () => import('./demo/components/aerosys/financeiro/financeiro.module').then(m => m.FinanceirocoModule) },
                    { path: 'fiscal', loadChildren: () => import('./demo/components/aerosys/fiscal/fiscal.module').then(m => m.FiscalModule) },
                    { path: 'os', loadChildren: () => import('./demo/components/aerosys/os/os.module').then(m => m.OsModule) },
                    { path: 'pessoa', loadChildren: () => import('./demo/components/aerosys/pessoa/pessoa.module').then(m => m.PessoaModule) },
                    { path: 'venda', loadChildren: () => import('./demo/components/aerosys/venda/venda.module').then(m => m.VendaModule) },
                    { path: 'email', loadChildren: () => import('./demo/components/aerosys/email/email.module').then(m => m.EmailModule) },

                ]
            },
            { path: 'auth', loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
            { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
