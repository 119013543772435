import { Component, Injector, OnInit } from '@angular/core';
import { FormGenerico } from '../../../generic/formgenerico/formgenerico';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImportacaoHistStatus } from 'src/app/model/importacao/importacaoHistStatus';
import { ImportacaoHistStatusService } from 'src/app/services/importacao/importacao-hist-status.service';
import { Importacao } from 'src/app/model/importacao/importacao';
import { ImportacaoStatus } from 'src/app/model/importacao/importacaoStatus';

@Component({
  selector: 'app-form-imp-docs',
  templateUrl: './formimphiststatus.component.html',
  styleUrls: ['../../../generic/formgenerico/formgenerico.component.css']
})

export class FormImpHistStatusComponent extends FormGenerico<ImportacaoHistStatus> implements OnInit {

  idImportacaoStatus: number;
  idImportacao: number;
  id: number;

  constructor(
    injectorObj: Injector,
    public api: ImportacaoHistStatusService,
    public refDialog: DynamicDialogRef,
    public configDialog: DynamicDialogConfig) 
  {
    super(injectorObj, api);
    this.obj = new ImportacaoHistStatus();
  }

  override ngOnInit(): void {
    //super.ngOnInit();
    if (this.configDialog.data) {
      this.idImportacaoStatus = this.configDialog.data.idImportacaoStatus;
      this.idImportacao = this.configDialog.data.idImportacao;
      this.id   = this.configDialog.data.id;

      if (this.id == 0) {
        this.obj.id = this.id;
        this.obj.importacaoStatus = new ImportacaoStatus();
        this.obj.importacaoStatus.id = this.idImportacaoStatus;
        this.obj.importacao = new Importacao();
        this.obj.importacao.id = this.idImportacao;

        this.obj.status = 'P';
        this.obj.data = new Date();
      }
      else {
        this.findById(this.id);
      }

      this.title = this.translocoService.translate( this.constantsAero.PREFIX_TABLE + this.obj.idTab); 
    }
  }

  override save(): void {
    return super.save(false).then(() => this.refDialog.close(this.obj));
  }

  override dataExtraObj(res: any) {
    if (this.obj.id > 0) {
      this.obj.status = 'F';
      this.obj.dataFim = new Date();
    } 
  }

}