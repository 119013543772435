import { AgenciaService } from 'src/app/services/geral/agencia.service';
import { Agencia } from 'src/app/model/geral/agencia';
import { Component, Injector, OnInit } from '@angular/core';
import { FormGenerico } from '../../generic/formgenerico/formgenerico';
import { GeralFormModule } from './geralform.module';

@Component({
  selector: 'app-form-agencia',
  templateUrl: '../../generic/formgenerico/formgenerico.component.html',
  styleUrls: [ '../../generic/formgenerico/formgenerico.component.css']
})

export class FormAgenciaComponent extends FormGenerico<Agencia> implements OnInit {

  constructor(
    injectorObj: Injector,
    api: AgenciaService) 
  {
    super(injectorObj, api);
    this.obj = new Agencia();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override dataExtraObj(res: any) {
  }

}
