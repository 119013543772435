import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormGenerico } from '../../../generic/formgenerico/formgenerico';
import { ImportacaoHistorico } from 'src/app/model/importacao/importacaoHistorico';
import { ImportacaoHistoricoService } from 'src/app/services/importacao/importacao-historico.service';
import { Pessoa } from 'src/app/model/pessoa/pessoa';
import { FiscalInvoiceItensService } from 'src/app/services/fiscal/fiscal-invoice-itens.service';
import { FiscalInvoiceItens } from 'src/app/model/fiscal/fiscalInvoiceItens';
import { Padrao } from 'src/app/model/generic/padrao';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-form-imphistorico',
  templateUrl: './formimportacaohistorico.component.html',
  styleUrls: ['../../../generic/formgenerico/formgenerico.component.css']
})

export class FormImportacaoHistoricoComponent extends FormGenerico<ImportacaoHistorico> implements OnInit {

  acao: string;
  idImportacao: number;
  imgPergunta: number;

  listaFiscalInvoiceItens: FiscalInvoiceItens[];
  objFiscalInvoiceItens: FiscalInvoiceItens;
  objImpHistAll: ImportacaoHistorico = new ImportacaoHistorico();

  @Output() atualizarObjPai = new EventEmitter<Padrao>();
  
  constructor(
    injectorObj: Injector,
    api: ImportacaoHistoricoService,
    public apiFiscalInvoiceItens: FiscalInvoiceItensService,
    public refDialog: DynamicDialogRef,
    public configDialog: DynamicDialogConfig
    ) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    this.obj = new ImportacaoHistorico();
    if (this.configDialog.data) {
      this.id   = this.configDialog.data.id;
      this.acao = this.configDialog.data.acao;

      if (this.acao == 'P')
        this.imgPergunta = 0;
      else
        this.imgPergunta = 1;

      this.idImportacao = this.configDialog.data.idImportacao;
      if (this.id > 0) {
        this.findById(this.id);
      } 
      else {
        this.obj.pessoaPergunta = new Pessoa();
        this.obj.pessoaPergunta.id = this.auth.usuarioInfo.usuarioId;
        }
      this.findAllItensDasInvoices();
      this.title = this.translocoService.translate( this.constantsAero.PREFIX_TABLE + this.obj.idTab); 

    }
  }

  dataExtraObj(res: any) {
    this.obj.idImportacao = this.idImportacao;
    this.obj.dataPergunta = new Date(this.obj.dataPergunta);
    this.obj.dataResposta = new Date(this.obj.dataResposta);
    
    if ((this.obj.fiscalInvoiceItens) && (this.obj.fiscalInvoiceItens.id > 0))
      this.objFiscalInvoiceItens = this.obj.fiscalInvoiceItens;
  }

  override insertMult(dialog: boolean, inserir: boolean) {
    if (this.acao == 'P') {
      if ((this.objFiscalInvoiceItens) && (this.objFiscalInvoiceItens.id > 0)) 
        this.obj.fiscalInvoiceItens = this.objFiscalInvoiceItens;
      else
        this.obj.fiscalInvoiceItens = null;
    }
    else {
      this.obj.status = 'Respondido';
      this.obj.dataResposta = new Date();
      this.obj.pessoaResposta = new Pessoa();
      this.obj.pessoaResposta.id = this.auth.usuarioInfo.usuarioId;
    }

    this.obj.idImportacao = this.idImportacao;
    this.obj.empresaCad = this.auth.empresa.id;
    this.obj.usuarioCad = this.auth.usuarioInfo.usuarioId;
    this.obj.dataCad = new Date();

    super.insertMult(dialog, inserir).then(() => this.refDialog.close(true));
    this.atualizarObjPai.emit(this.obj);
  }

  //Lista Itens das Invoices
  findAllItensDasInvoices() {
    this.apiFiscalInvoiceItens.findItensPorInvoice(this.auth.empresa.id, this.idImportacao)
      .toPromise()
      .then
      ((res: any) => {
        this.listaFiscalInvoiceItens = res;
      });
  }

  atualizarHistAll(objSelFilho) {
    this.objImpHistAll = objSelFilho;
  }
   
}
