<div class="grid p-fluid m-0 p-0">
    <div class="grid p-fluid m-0 p-0 col-6 md:col-{{larguraCampos[0]}}">
        <label *ngIf="textButton.length == 0" for="inputIdPesq">{{ constantsAero.PREFIX_FIELD + labels[0] | transloco }}</label>
        <div class="p-inputgroup">
            <button type="button" pButton pRipple icon="pi pi-search" (click)="showDialog()" [disabled]="disabled" >{{ ' . ' + textButton}}</button>
            <input type="text" [(ngModel)]="obj.id" inputId="integeronly" id="inputIdPesq" name="inputIdPesq" 
            (input)="onIdInput()" style="width: 85%; height: 47px; border: 1px solid #ccc; padding: 0.375rem 0.75rem; border-radius: 0.25rem; font-size: 1rem; outline: none; transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;" 
            onfocus="this.style.borderColor='#007bff'; this.style.boxShadow='0 0 0 0.2rem rgba(0, 123, 255, 0.25)';" 
            onblur="this.style.borderColor='#ccc'; this.style.boxShadow='none';">
           <button *ngIf="limpar" type="button" pButton pRipple icon="pi pi-times" (click)="clearObj()" [disabled]="disabled" class="p-button-outlined p-button-secondary"></button> 
        </div>
    </div>
    <div *ngIf="qtdProperties >= 1" class="m-0 pb-0 p-0 field col-12 md:col-{{larguraCampos[1]}}">
        <label *ngIf="labels[1]" for="inputValor1Pesq">{{ constantsAero.PREFIX_FIELD + labels[1] | transloco }}</label>
        <label *ngIf="!labels[1]" for="inputValor1Pesq">&nbsp;</label>
<!--         <input id="inputValor1Pesq" [ngModel]="obj[properties[0]]" name="inputValor1Pesq" type="text" pInputText [disabled]="true"/> -->
        <input *ngIf="isDataField(properties[0])" id="inputValor1Pesq" [ngModel]="obj[properties[0]] | date: globalFunctions.getDataFormat()" name="inputValor1Pesq" type="text" pInputText [disabled]="true"/>
        <input *ngIf="!isDataField(properties[0])" id="inputValor1Pesq" [ngModel]="obj[properties[0]]" name="inputValor1Pesq" type="text" pInputText [disabled]="true"/>
    </div>
    <div *ngIf="qtdProperties >= 2" class="m-0 pb-0 p-0 field col-12 md:col-{{larguraCampos[2]}}">
        <label *ngIf="labels[2]" for="inputValor2Pesq">{{ constantsAero.PREFIX_FIELD + labels[2] | transloco }}</label>
        <label *ngIf="!labels[2]" for="inputValor1Pesq">&nbsp;</label>
        <!-- <input id="inputValor2Pesq" [ngModel]="obj[properties[1]]" name="inputValor2Pesq" type="text" pInputText [disabled]="true"/> -->
        <input *ngIf="isDataField(properties[1])" id="inputValor2Pesq" [ngModel]="obj[properties[1]] | date: globalFunctions.getDataFormat()" name="inputValor2Pesq" type="text" pInputText [disabled]="true"/>
        <input *ngIf="!isDataField(properties[1])" id="inputValor2Pesq" [ngModel]="obj[properties[1]]" name="inputValor2Pesq" type="text" pInputText [disabled]="true"/>
    </div>
    <div *ngIf="qtdProperties >= 3" class="m-0 pb-0 p-0 field col-12 md:col-{{larguraCampos[3]}}">
        <label *ngIf="labels[3]" for="inputValor3Pesq">{{ constantsAero.PREFIX_FIELD + labels[3] | transloco }}</label>
        <label *ngIf="!labels[3]" for="inputValor1Pesq">&nbsp;</label>
        <!-- <input id="inputValor3Pesq" [ngModel]="obj[properties[2]]" name="inputValor3Pesq" type="text" pInputText [disabled]="true"/> -->
        <input *ngIf="isDataField(properties[2])" id="inputValor3Pesq" [ngModel]="obj[properties[2]] | date: globalFunctions.getDataFormat()" name="inputValor3Pesq" type="text" pInputText [disabled]="true"/>
        <input *ngIf="!isDataField(properties[2])" id="inputValor3Pesq" [ngModel]="obj[properties[2]]" name="inputValor3Pesq" type="text" pInputText [disabled]="true"/>
    </div>
    <div *ngIf="qtdProperties >= 4" class="m-0 pb-0 p-0 field col-12 md:col-{{larguraCampos[4]}}">
        <label *ngIf="labels[4]" for="inputValor4Pesq">{{ constantsAero.PREFIX_FIELD + labels[4] | transloco }}</label>
        <label *ngIf="!labels[4]" for="inputValor1Pesq">&nbsp;</label>
        <!-- <input id="inputValor4Pesq" [ngModel]="obj[properties[3]]" name="inputValor4Pesq" type="text" pInputText [disabled]="true"/> -->
        <input *ngIf="isDataField(properties[3])" id="inputValor4Pesq" [ngModel]="obj[properties[3]] | date: globalFunctions.getDataFormat()" name="inputValor4Pesq" type="text" pInputText [disabled]="true"/>
        <input *ngIf="!isDataField(properties[3])" id="inputValor4Pesq" [ngModel]="obj[properties[3]]" name="inputValor4Pesq" type="text" pInputText [disabled]="true"/>
    </div>
</div>