import { Padrao } from '../generic/padrao';
import { Importacao } from './importacao';
import { ImportacaoStatus } from './importacaoStatus';

export class ImportacaoHistStatus extends Padrao{

    override idTab = 287;

    importacao: Importacao;
    importacaoStatus: ImportacaoStatus;
    data: Date;
    descricao: string;
    status: string;
    dataFim: Date;
}