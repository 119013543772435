import { Padrao } from '../generic/padrao';
import { EndCidade } from '../endereco/endCidade';
import { EndPais } from '../endereco/endPais';
import { Pessoa } from './pessoa';

export class  PesEndereco extends Padrao{

    override idTab = 32;

    endCidade: EndCidade = new EndCidade();
    endPais: EndPais = new EndPais();
    pessoa: Pessoa = new Pessoa();
    padrao: string;
    endereco: string;
    bairro: string;
    cidade: string;
    uf: string;
    cep: string;
    pontoRef: string;
    pais: string;
    tipo: string;
    complemento: string;
    inscEstadual: string;
    consFinal: string;
    contrib: string;
}