import { Importacao } from './../../model/importacao/importacao';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from "@ngneat/transloco";

import { ImportacaoInvoice } from 'src/app/model/importacao/importacaoInvoice';
import { GenericDuploService } from '../generics/genericDuplo.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoService extends GenericDuploService<Importacao, ImportacaoInvoice>{
  constructor(
    http: HttpClient, 
    private translocoService: TranslocoService) { 
    super(http);
    super.initResource("importacao")
  }

  findTraducao(idEmpresa: number, idImportacao: number): any {

    let pesoAwbFinal = 0;
    let percAjuste = 0;

    return this.returnValorAwbFinal(idEmpresa, idImportacao).toPromise().then((awb: any) => { 
      pesoAwbFinal = awb; 
      return this.returnValueRule(idEmpresa, 653).toPromise().then((ajuste: any) => { 
        percAjuste = ajuste;
      
        const urlLocal = `${this.url}/traducao/${idEmpresa}/${idImportacao}`;
        return this.http.get<any[]>(urlLocal).toPromise()
        .then((res: any) => {
    
          let colsExt: any = [];
          colsExt.push({ field: 'wr', header: this.translocoService.translate('value_wr'), type: 'Texto', calcRodape: ''});
          colsExt.push({ field: 'invoice', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4999'), type: 'Texto', calcRodape: 'CONT'});
          colsExt.push({ field: 'partNumber', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '1976'), type: 'Texto', calcRodape: ''});
          colsExt.push({ field: 'quantidade', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4070'), type: 'N° Real', calcRodape: ''});
          colsExt.push({ field: 'und', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4159'), type: 'Texto', calcRodape: ''});
          colsExt.push({ field: 'valorUnit', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4074'), type: 'N° Real', calcRodape: ''});
          colsExt.push({ field: 'subtotal', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4076'), type: 'N° Real', calcRodape: ''});
          colsExt.push({ field: 'traducao', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4147'), type: 'Texto', calcRodape: ''});
          colsExt.push({ field: 'constituicao', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5819'), type: 'Texto', calcRodape: ''});
          colsExt.push({ field: 'aplicacao', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5818'), type: 'Texto', calcRodape: ''});
          colsExt.push({ field: 'prefixo', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '684'), type: 'Texto', calcRodape: ''});
          colsExt.push({ field: 'fabricante', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4163'), type: 'Texto', calcRodape: ''});
          colsExt.push({ field: 'peso', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4284'), type: 'N° Real', calcRodape: ''});
          colsExt.push({ field: 'pesoTotal', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4188'), type: 'N° Real', calcRodape: 'SOMA'});
          colsExt.push({ field: 'pesoAjust', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4284') + ' Ajust.', type: 'N° Real', calcRodape: ''});
          colsExt.push({ field: 'pesoTotalAjust', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '4188') + ' Ajust.', type: 'N° Real', calcRodape: 'SOMA'});
          colsExt.push({ field: 'id', header: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '1506'), type: 'N° Inteiro', calcRodape: ''});
    
          let listExt: any[] = res;
    
          let totPeso = 0;
          listExt.forEach(e => {
            totPeso = totPeso + e.pesoTotal;
          })
          let fator = pesoAwbFinal/totPeso;
    
          let list: any[] = [];
          listExt.forEach(e => {
            list.push({wr: e.wr, 
                        invoice: e.invoice, 
                        partNumber: e.partNumber, 
                        quantidade: e.quantidade, 
                        und: e.und, 
                        valorUnit: e.valorUnit, 
                        subtotal: e.subtotal, 
                        traducao: e.traducao, 
                        constituicao: e.constituicao, 
                        aplicacao: e.aplicacao, 
                        prefixo: e.prefixo, 
                        fabricante: e.fabricante, 
                        peso: e.peso, 
                        pesoTotal: e.pesoTotal,
                        pesoAjust: e.peso * fator * (percAjuste/100), 
                        pesoTotalAjust: e.pesoTotal * fator * (percAjuste/100),
                        id: e.id});              
            })
            return [list, colsExt];
        })
        .catch
        (( error ) => {
          throw error;
        });
          
      });
    
    });
  }

  returnQtdePerguntas(idEmpresa: number, idImportacao: number): Observable<number> {
    const urlLocal = `${this.url}/perguntas/${idEmpresa}/${idImportacao}`;
    return this.http.get<number>(urlLocal);
  }

  returnQtdePerguntasNaoResp(idEmpresa: number, idImportacao: number): Observable<number> {
    const urlLocal = `${this.url}/perguntasNao/${idEmpresa}/${idImportacao}`;
    return this.http.get<number>(urlLocal);
  }

  returnQtdeStatus(idEmpresa: number): Observable<number> {
    const urlLocal = `${this.url}/status/${idEmpresa}`;
    return this.http.get<number>(urlLocal);
  }

  returnQtdeStatusPorImp(idEmpresa: number, idImportacao: number): Observable<number> {
    const urlLocal = `${this.url}/status/${idEmpresa}/${idImportacao}`;
    return this.http.get<number>(urlLocal);
  }

  returnQtdeStatusPorResp(idEmpresa: number, resp: string): Observable<number> {
    const urlLocal = `${this.url}/statusresp/${idEmpresa}/${resp}`;
    return this.http.get<number>(urlLocal);
  }

  returnQtdeStatusPorImpEResp(idEmpresa: number, resp: string, idImportacao: number): Observable<number> {
    const urlLocal = `${this.url}/statusresp/${idEmpresa}/${resp}/${idImportacao}`;
    return this.http.get<number>(urlLocal);
  }

  returnValorNfe(idEmpresa: number, idImportacao: number): Observable<number> {
    const urlLocal = `${this.url}/docs/nfe/valor/${idEmpresa}/${idImportacao}`;
    return this.http.get<number>(urlLocal);
  }

  returnCotacaoNfe(idEmpresa: number, idImportacao: number): Observable<number> {
    const urlLocal = `${this.url}/docs/nfe/cotacao/${idEmpresa}/${idImportacao}`;
    return this.http.get<number>(urlLocal);
  }

  returnValorAwbFinal(idEmpresa: number, idImportacao: number): Observable<number> {
    const urlLocal = `${this.url}/docs/awbfinal/valor/${idEmpresa}/${idImportacao}`;
    return this.http.get<number>(urlLocal);
  }

}
