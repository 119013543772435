import { Padrao } from '../generic/padrao';

export class GrupoUsuario extends Padrao{

    override idTab = 28;

    descricao: string;
    status: string;
    percDesconto: number;
    percComis: number;
    percComisGlobal: number;
}