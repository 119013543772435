import { Padrao } from '../generic/padrao';

export class Unidade extends Padrao{

    override idTab = 190;

    nome: string;
    sigla: string;
    grupo: string;
    nomeLingua: string;
    siglaLingua: string;
    fatorConv: number; 
    nomeImport: string;   
}