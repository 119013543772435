import { Moeda } from './../financeiro/moeda';
import { Padrao } from '../generic/padrao';
import { Funcionario } from '../pessoa/funcionario';

export class FatorMoeda extends Padrao{

    override idTab = 4;

    moeda: Moeda = new Moeda();
    funcionario: Funcionario = new Funcionario();
    fator: number;
    dataHora: Date;
}