import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { ErrorHandlerService } from '../system/error-handler.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private translocoService: TranslocoService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.auth.acessoTokenInvalido()) {
      //console.log('Navegação com access token inválido. Obtendo novo token...');

      return this.auth.novoAccessToken()
        .then(() => {
          if (this.auth.acessoTokenInvalido()) {
            this.errorHandler.handle(this.translocoService.translate('message_login_expired'));
            this.router.navigate(['/auth/login']);
            return false;
          }

          return true;
        });
    } /*else if (next.data.roles && !this.auth.temQualquerPermissao(next.data.roles)) {
      this.router.navigate(['/nao-autorizado']);
      return false;
    }*/

    return true;
  }

}
