import { Departamento } from './../../model/geral/departamento';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';


@Injectable({
  providedIn: 'root'
})
export class DepartamentoService extends GenericService<Departamento>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("departamento")
  }
}
