import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericService } from '../generics/generic.service';
import { Email } from 'src/app/model/email/email.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends GenericService<Email>{
  //private emailUrl = ConstantServices.urlBasic + 'email';
  constructor(http: HttpClient) {
    super(http);
    super.initResource("email")
   }

  sendEmail(to: string, subject: string, text: string, files: File[]): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('to', to);
    formData.append('subject', subject);
    formData.append('text', text);

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i], files[i].name);
      }
    }

    const urlLocal = `${this.url}`;
    return this.http.post(urlLocal, formData);
  }
  
}