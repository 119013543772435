import { ModeloEtiquetaService } from 'src/app/services/geral/modelo-etiqueta.service';
import { ModeloEtiqueta } from 'src/app/model/geral/modeloEtiqueta';
import { Component, Injector, OnInit } from '@angular/core';
import { GeralListaModule } from './gerallista.module';
import { ListaSimplesGenerica } from '../../generic/listagenerica/listasimplesgenerica';

@Component({
  selector: 'app-lista-modeloetiqueta',
  templateUrl: '../../generic/listagenerica/listasimples.component.html',
  styleUrls: [ '../../generic/listagenerica/listasimples.component.css']
})

export class ListaModeloEtiquetaComponent extends ListaSimplesGenerica<ModeloEtiqueta> implements OnInit {

  override obj = new ModeloEtiqueta();

  constructor(
    injectorObj: Injector,
    api: ModeloEtiquetaService) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override dataExtraList(res: any) {
}

  override getColorCell(valor: any) {   
  }

}
