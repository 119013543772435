import { FiscalInvoice } from "../fiscal/fiscalInvoice";
import { Padrao } from "../generic/padrao";
import { Arquivo } from "./arquivo";
import { Importacao } from "./importacao";

export class ImportacaoInvoice extends Padrao{

    override idTab = 281;

    id: number;
    importacao: Importacao = new Importacao();
    fiscalInvoice: FiscalInvoice = new FiscalInvoice();
    wrNumero: string;
    wrPeso: number = 0;
    wrValor: number = 0;
    observacao: string;

    prefixo: string = ""; // Apenas para salvar os arquivos no diretório 

    listaArquivosInv: Arquivo[] = []
}