import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { AdendoPecasAeronave } from 'src/app/model/adendo/adendoPecasAeronave';

@Injectable({
  providedIn: 'root'
})
export class AdendoPecasAeronaveService extends GenericService<AdendoPecasAeronave>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("adendopecasaeronave")
  }
}
