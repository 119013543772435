import { Type } from "@angular/core";

export const classRegistry = new Map<string, any>();

// Exportando a função para ser usada em outros lugares
export function AutoRegisterClass() {
  return function (target: Type<any>) {
    classRegistry.set(target.name, target);
  };
}
