import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './environments/environment';
import { IAppConfig } from './environments/app-config.model';

@Injectable()
export class AppConfig {

    static settings: IAppConfig;

    static  urlBasic: string;
    static  dominioPadrao: string;

    constructor(private http: HttpClient) {}
    
    load() {
        const jsonFile = `assets/config/config.${environment.name}.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response : any) => {
               AppConfig.settings = <IAppConfig>response;

               AppConfig.urlBasic = AppConfig.settings.apiServer.protocol + '://' + AppConfig.settings.apiServer.nameOrIp + ':' + AppConfig.settings.apiServer.portDefault + '/';
               AppConfig.dominioPadrao  = AppConfig.settings.apiServer.nameOrIp + ':' + AppConfig.settings.apiServer.portDefault + '/'; 

               resolve();
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}