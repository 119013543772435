import { ImportacaoDocumento } from './../../model/importacao/importacaoDocumento';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from '../generics/generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoDocumentoService extends GenericService<ImportacaoDocumento>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("importacaodocumento")
  }

  findImpDoc(idImportacao: number, rotuloTipoDoc: string): Observable<ImportacaoDocumento[]> {
    const urlLocal = `${this.url}/rotulo/${idImportacao}/${rotuloTipoDoc}`;
    return this.http.get<ImportacaoDocumento[]>(urlLocal);
  }

  findImpDocFixo(): Observable<ImportacaoDocumento[]> {
    const urlLocal = `${this.url}/fixo`;
    return this.http.get<ImportacaoDocumento[]>(urlLocal);
  }

  findImpDocPorIdTipoDoc(idImportacao: number, idTipoDoc: number): Observable<ImportacaoDocumento> {
    const urlLocal = `${this.url}/tipodoc/${idImportacao}/${idTipoDoc}`;
    return this.http.get<ImportacaoDocumento>(urlLocal);
  }
}
