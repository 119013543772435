import { FormaEnvioService } from 'src/app/services/geral/forma-envio.service';
import { FormaEnvio } from 'src/app/model/geral/formaEnvio';
import { Component, Injector, OnInit } from '@angular/core';
import { GeralListaModule } from './gerallista.module';
import { ListaSimplesGenerica } from '../../generic/listagenerica/listasimplesgenerica';

@Component({
  selector: 'app-lista-formaenvio',
  templateUrl: '../../generic/listagenerica/listasimples.component.html',
  styleUrls: [ '../../generic/listagenerica/listasimples.component.css']
})

export class ListaFormaEnvioComponent extends ListaSimplesGenerica<FormaEnvio> implements OnInit {

  override obj = new FormaEnvio();

  constructor(
    injectorObj: Injector,
    api: FormaEnvioService) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override dataExtraList(res: any) {
}

  override getColorCell(valor: any) {   
  }

}
