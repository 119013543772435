import { Padrao } from '../generic/padrao';

export class ModeloEtiqueta extends Padrao{

    override idTab = 202;

    descricao: string;
    larguraPapel: number;
    larguraEtiq: number;
    alturaEtiq: number;
    numColunas: number;
    temperatura: number;
    avancoEtiq: number;
    qualidade: string;
    modeloImpressora: string;
    tabelas: string;
    tabelasFiltro: string;
    tipoImpressao: string;
}