<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>


<div class="grid m-0 p-0 w-full font-bold border-round m-0 p-2" [ngStyle]="{'backgroundColor':auth.getCorEmpresaSel()}">
    <div class="col-1 field p-0 m-0">
        <img class="max-w-4rem max-h-4rem" src="assets/layout/images/modal/{{obj.modal.toLowerCase()}}.png" alt="Modal">
    </div>
    <div class="col-3 field pt-1 pb-0 mt-0 mb-0">
        <h4>{{constantsAero.PREFIX_MENU + '173' | transloco }} - {{obj.numero}}</h4>
    </div>
    <div class="col-2 field pt-0 pb-0 mt-0 mb-0 justify-content-center">
        <p-progressBar [value]="getStatusPercent()"></p-progressBar> 
        <span class="text-sm field">{{qtdeStatusImp}} / {{qtdeStatus}}</span>
    </div>
    <div class="col-2 field p-2 flex border-round justify-content-center" [ngStyle]="{backgroundColor:getColorCanal(obj.canal)}">
        <span>{{obj.canal}}</span>
    </div>
    <div class="col-2 field pt-0 pb-0 mt-0 mb-0 flex justify-content-end">
        <p-avatar icon="pi pi-question-circle" pBadge value="{{qtdePerguntasNaoResp}}" severity="danger" styleClass="mr-5 mt-2 cursor-pointer" (click)="showPerguntas()" pTooltip="{{'unanswered_questions' | transloco}}"></p-avatar>
        <p-avatar icon="pi pi-question-circle" pBadge value="{{qtdePerguntas}}" severity="info" styleClass="mr-5 mt-2 cursor-pointer" (click)="showPergResp()" pTooltip="{{'view_questions_and_answers' | transloco}}"></p-avatar>
    </div>
    <div class="col-2 field pt-0 pb-0 mt-0 mb-0 flex justify-content-end">
        <button pButton type="button" icon="pi pi-angle-double-left" title="{{'button_cancel' | transloco}}" class="buttonMargin" (click)="back()"></button>

        <p-tieredMenu  id="menuFunctions" #menuFunctions [popup]="true" [model]="itemsFunctions" appendTo = "body"></p-tieredMenu>
        <button type="button" pButton title="{{'button_functions' | transloco }}" icon="pi pi-fw pi-cog"   (click)="menuFunctions.toggle($event)" class="buttonMargin" style="float: right"></button>

        <p-tieredMenu  id="menuPrint"     #menuPrint     [popup]="true" [model]="itemsPrint"     appendTo = "body"></p-tieredMenu>
        <button type="button" pButton title="{{'button_print' | transloco }}"     icon="pi pi-fw pi-print" (click)="menuPrint.toggle($event)" class="buttonMargin" style="float: right"></button>
    </div>
</div>


<div class="m-1 p-0" *ngFor="let impTimeLine of listaImpTimeLine">

    <div class="grid bg-bluegray-100 font-bold border-round m-0 p-1 pb-2">
        <div class="col-9 field pt-1 pb-0 mt-0 mb-0">
            <span class="mr-4">{{ impTimeLine.rotuloResp }} : {{ impTimeLine.pessoaResponsavel?.nome}}</span>
            <span *ngIf="impTimeLine.pessoaResponsavel2" class="ml-4 mr-4">|</span>
            <span *ngIf="impTimeLine.pessoaResponsavel2" class="ml-4">{{ impTimeLine.pessoaResponsavel2?.nome}}</span>
        </div>
        <div class="col-1 field pt-1 pb-0 mt-0 mb-0 flex justify-content-end">
            <span class="text-sm">{{impTimeLine.quantHist}} / {{impTimeLine.quantHistTot}}</span>
        </div>
        <div class="col-2 field pt-1 pb-0 mt-0 mb-0">
            <p-progressBar [value]="impTimeLine.getPercent()"></p-progressBar>
        </div>
    </div>    

    
    <p-timeline [value]="impTimeLine.listaHistStatus" layout="horizontal" align="top" class="surface-300" styleClass="customized-timeline">

        <ng-template pTemplate="marker" let-hisStatus>
            <span class="custom-marker" [style.color]="getColor(hisStatus)">
                <i [ngClass]="hisStatus.importacaoStatus.icone" class="cursor-pointer" style="font-size: 2.0rem"></i>
            </span>
        </ng-template>            

        <ng-template pTemplate="content" let-hisStatus>
            
            <div class="ml-4 mr-4">
                <div class="grid p-fluid mb-0 border-round w-12rem border-400 surface-overlay border-1" style="max-height: 320px; height: 320px;">
                    <div class="m-0 p-1 field col-12 md:col-10" [style.backgroundColor]="getBkColor(hisStatus)">
                        <span class="font-bold text-base">{{ hisStatus.importacaoStatus.ordem }}.{{ hisStatus.importacaoStatus.nome }}</span>
                    </div>
                    <div class="m-0 p-1 field col-12 md:col-2 flex justify-content-end" [style.backgroundColor]="getBkColor(hisStatus)">
                        <p-tieredMenu  id="menuFunctionsStatus" #menuFunctionsStatus [popup]="true" [model]="itemsFunctionsStatus" appendTo = "body"></p-tieredMenu>
                        <i *ngIf="!hisStatus.id || hisStatus.status != 'F'" class="pi pi-bars cursor-pointer" style="font-size: 1.5rem" (click)="selectImpHistStatus(hisStatus); menuFunctionsStatus.toggle($event)"></i>
                    </div>

                    <div class="m-0 p-0 pt-1 pb-1 field col-12 md:col-12 flex justify-content-end" [style.backgroundColor]="getBkColor(hisStatus)">
                        <span class="text-sm p-1">{{ hisStatus.data | date: globalFunctions.getDataFormatShort() }}</span>
                        <span class="text-sm p-1">{{ hisStatus.dataFim | date: globalFunctions.getDataFormatShort() }}</span>
                        <i class="pi pi-question-circle cursor-pointer pl-1" style="font-size: 1.5rem" (click)="selectImpHistStatus(hisStatus); showOrie()"></i>
                        <i class="pi pi-book cursor-pointer pl-1 pr-1" style="font-size: 1.5rem" (click)="selectImpHistStatus(hisStatus); showDesc()"></i>
                    </div>

                    <div class="m-0 p-1 field col-12 md:col-12 text-sm">
                        <b>Descrição</b>
                        <p-scrollPanel [style]="{width: '100%', height: '100px'}" [styleClass]="getBkColor(hisStatus)">
                            <p>{{ hisStatus.descricao}}</p>
                        </p-scrollPanel>
                    </div>

                    <div *ngIf="hisStatus.importacaoStatus.ordem != '01' || impTimeLine.responsavel != 'Importador'" class="m-0 p-1 field col-12 md:col-12 text-sm">
                        <b>Documentos</b>
                        <p-scroller [items]="hisStatus.listaItens" [itemSize]="hisStatus.listaItens.length+1" scrollHeight="100px" 
                                    styleClass="border-1 border-400" [style]="{'width': '100%', 'height': '100px'}">
                            <ng-template let-impStatusImpTipoDoc pTemplate="item" let-options="options">

                                <div class="flex justify-content-between flex-wrap card-container" style="height: 25px;" [ngClass]="{ 'surface-200' : options.odd }">
                                    <div class="flex align-items-center justify-content-center pl-1 pr-1">
                                        <i *ngIf="!impStatusImpTipoDoc.importacaoDocumento" class="pi pi-thumbs-down" style="font-size: 1.0rem" [ngStyle]="{color: 'var(--red-500)'}"></i> <!--pi-chevron-circle-down-->
                                        <i *ngIf="impStatusImpTipoDoc.importacaoDocumento" class="pi pi-thumbs-up" style="font-size: 1.0rem" [ngStyle]="{color: 'var(--green-600)'}"></i> <!--pi-chevron-circle-down-->
                                    </div>
                                    <div class="flex align-items-center justify-content-center pl-1 pr-1">
                                        {{ impStatusImpTipoDoc.importacaoTipoDocumento.sigla }}
                                    </div>
                                    <div class="flex align-items-center justify-content-end pl-1 pr-1">
                                        <p-tieredMenu  id="menuFunctionsDoc" #menuFunctionsDoc [popup]="true" [model]="itemsFunctionsDoc" appendTo = "body"></p-tieredMenu>
                                        <i class="pi pi-chevron-circle-down cursor-pointer" style="font-size: 1.0rem" (click)="selectImpStatusImpTipoDoc(impStatusImpTipoDoc); menuFunctionsDoc.toggle($event)"></i>
                                    </div>
                                </div>
                            </ng-template>
                        </p-scroller>
                    </div>

                    <div *ngIf="hisStatus.importacaoStatus.ordem == '01' && impTimeLine.responsavel == 'Importador'" class="m-0 p-1 field col-12 md:col-12 text-sm">
                        <b>Invoices</b>
                        <p-scroller [items]="obj.listaItens" [itemSize]="obj.listaItens.length+1" scrollHeight="100px" 
                                    styleClass="border-1 border-400" [style]="{'width': '100%', 'height': '100px'}">
                            <ng-template let-impInvoice pTemplate="item" let-options="options">

                                <div class="flex justify-content-between flex-wrap card-container" style="height: 25px;" [ngClass]="{ 'surface-200' : options.odd }">
                                    <div class="flex align-items-center justify-content-center pl-1 pr-1">
                                        <i class="pi pi-check-square" style="font-size: 1.0rem" [ngStyle]="{color: 'var(--green-600)'}"></i> <!--pi-chevron-circle-down-->
                                    </div>
                                    <div class="flex align-items-center justify-content-center pl-1 pr-1">
                                        {{ impInvoice.fiscalInvoice.numTitulo }}
                                    </div>
                                    <div class="flex align-items-center justify-content-end pl-1 pr-1">
                                        <i class="pi pi-eye cursor-pointer" style="font-size: 1.0rem" (click)="showListaArquivosInvoices(impInvoice)"></i>
                                    </div>
                                </div>
                            </ng-template>
                        </p-scroller>
                    </div>

                </div>
            </div>
        </ng-template>

    </p-timeline>

</div>


<p-sidebar [(visible)]="displayOrie" position="right" styleClass="w-30rem">
    <ng-template pTemplate="header"><span class="text-xl">{{constantsAero.PREFIX_FIELD + '5856' | transloco }}</span></ng-template>
    <ng-template pTemplate="content">
        <div *ngIf="objImpHistStatusSel">
            <p-card header="{{tituloDesc}}">
            {{ objImpHistStatusSel.importacaoStatus.orientacoes }}
            </p-card>
        </div>
    </ng-template>
    <ng-template pTemplate="footer"></ng-template>    
</p-sidebar>

<p-sidebar [(visible)]="displayDesc" position="right" styleClass="w-30rem">
    <ng-template pTemplate="header"><span class="text-xl">{{constantsAero.PREFIX_FIELD + '5854' | transloco }}</span></ng-template>
    <ng-template pTemplate="content">
        <div *ngIf="objImpHistStatusSel">
            <p-card header="{{tituloDesc}}">
                {{ objImpHistStatusSel.importacaoStatus.descricao }}
            </p-card>
        </div>
    </ng-template>
    <ng-template pTemplate="footer"></ng-template>
</p-sidebar>