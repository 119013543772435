import { TipoCondPagto } from './../financeiro/tipoCondPagto';
import { Padrao } from '../generic/padrao';
import { Aeronave } from '../aeronave/aeronave';
import { Pessoa } from '../pessoa/pessoa';
import { PesEndereco } from '../pessoa/pesEndereco';
import { FatorMoeda } from '../geral/fatorMoeda';

export class FiscalInvoice extends Padrao{

    override idTab = 222;

    aeronave: Aeronave = new Aeronave();
    pessoaCli: Pessoa = new Pessoa();
    pessoaVend: Pessoa = new Pessoa();
    pessoaTransp: Pessoa = new Pessoa();
    pessoaEntrega: Pessoa = new Pessoa();
    tipoCondPagto: TipoCondPagto = new TipoCondPagto();
    pesEndereco: PesEndereco = new PesEndereco();
    endEnderecoEntrega: PesEndereco = new PesEndereco();
    fatorMoeda: FatorMoeda = new FatorMoeda();
    nome: string;
    transpFormaEnvio: string;
    transpAwb: string;
    transpVolume: string;
    transpVolumeNum: string;
    transpEnvio: Date;
    perc: number;
    dataEmissao: Date;
    dataFinal: Date;
    funFinal: number;
    status: string;
    numTitulo: string;
    tipoDesc: string;
    descAcres: number;
    totCore: number;
    total: number;
    totFaturado: number;
    descAcresMoeda: number;
    totCoreMoeda: number;
    totalMoeda: number;
    totFaturadoMoeda: number;
    descAcresGeral: number;
    descAcresGeralMoeda: number;
    tipoFrete: string;
    valorFrete: number;
    outrasDespesas: number;
    precoCusto: string;
    entregue: string;
    es: string;
    modelo: string;
    consFinal: string;
    peso: number;
    incoterms: string;
    descricao: string;
    descricaoInterna: string;
    valorFreteInter: number;
}