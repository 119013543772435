<div class="grid p-fluid ml-0 mt-2 pr-3 pl-1 pb-2">
    <div class="m-0 pb-0 pl-1 pr-1 pt-1 field col-12 md:col-4">
        <label for="inputCompany" class="fieldDisable">{{ 'field_company' | transloco }}</label><br>
        <input pInputText id="inputCompany" name="inputCompany" type="number" [disabled]="true"
            [(ngModel)]="obj.empresaCad">
    </div>
    <div class="m-0 pb-0 pl-1 pr-1 pt-1 field col-12 md:col-4">
        <label for="inputUser" class="fieldDisable">{{ 'field_default_user' | transloco }}</label><br>
        <input pInputText id="inputUser" name="inputUser" type="number" [disabled]="true" [(ngModel)]="obj.usuarioCad">
    </div>
    <div class="m-0 pb-0 pl-1 pr-1 pt-1 field col-12 md:col-4">
        <label for="inputDateIn" class="fieldDisable">{{ 'field_default_dateIn' | transloco }}</label><br>
        <p-calendar id="inputDateIn" name="inputDateIn" [disabled]="true" [(ngModel)]="obj.dataCad"
            [showTime]="true" dateFormat="{{ 'date_short_format_form' | transloco }}"></p-calendar>
    </div>
</div>