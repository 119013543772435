import { Component, Injector, OnInit } from '@angular/core';
import { FormGenerico } from '../../generic/formgenerico/formgenerico';
import { ImportacaoFormModule } from './importacaoform.module';
import { ImportacaoTipoDocumento } from 'src/app/model/importacao/importacaoTipoDocumento';
import { ImportacaoTipoDocumentoService } from 'src/app/services/importacao/importacao-tipo-documento.service';

@Component({
  selector: 'app-form-functions',
  templateUrl: '../../generic/formgenerico/formgenerico.component.html',
  styleUrls: [ '../../generic/formgenerico/formgenerico.component.css']
})

export class FormImportacaoTipoDocumentoComponent extends FormGenerico<ImportacaoTipoDocumento> implements OnInit {

  constructor(
    injectorObj: Injector,
    api: ImportacaoTipoDocumentoService) 
  {
    super(injectorObj, api);
    this.obj = new ImportacaoTipoDocumento();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  dataExtraObj(res: any) {
  }

}
