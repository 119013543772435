import { Component, Injector, OnInit } from '@angular/core';
import { ConceitoPessoa } from 'src/app/model/geral/conceitoPessoa';
import { ConceitoPessoaService } from 'src/app/services/geral/conceito-pessoa.service';
import { FormGenerico } from '../../generic/formgenerico/formgenerico';
import { PessoaFormModule } from './pessoaform.module';

@Component({
  selector: 'app-form-conceitopessoa',
  templateUrl: '../../generic/formgenerico/formgenerico.component.html',
  styleUrls: [ '../../generic/formgenerico/formgenerico.component.css']
})

export class FormConceitoPessoaComponent extends FormGenerico<ConceitoPessoa> implements OnInit {

  constructor(
    injectorObj: Injector,
    api: ConceitoPessoaService) 
  {
    super(injectorObj, api);
    this.obj = new ConceitoPessoa();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override dataExtraObj(res: any) {
  }

}
