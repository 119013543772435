import { Component, Input, OnInit, Output, EventEmitter, ViewChild, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Padrao } from 'src/app/model/generic/padrao';
import { ErrorHandlerService } from 'src/app/services/system/error-handler.service';
import { ConstantsAero } from '../constantsAero';
import { GenericModule } from '../generic.module';
import { GlobalFunctions } from '../global-functions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-form-upload',
  templateUrl: './formupload.component.html',
  styleUrls: [ '../formgenerico/formgenerico.component.css']
})
export class FormUploadComponent implements OnInit {

  @ViewChild('fileUploadGeral') fileUploadGeral: FileUpload;

  @Input() api: any;
  @Input() obj: Padrao;
  @Input() infoExtra: string[] = [];
  @Input() showUploadButton = true;

  @Output() atualizarListaPai = new EventEmitter();
  
  protected constantsAero: ConstantsAero = new ConstantsAero();
  protected globalFunctions: GlobalFunctions;

  public refDialog: DynamicDialogRef;
  public configDialog: DynamicDialogConfig;

  constructor(
    private injectorObj: Injector,
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
    ) {
  }

  ngOnInit(): void {
    if (this.fileUploadGeral) 
      this.fileUploadGeral.clear();

      try {
        this.refDialog    = this.injectorObj.get(DynamicDialogRef);
        this.configDialog = this.injectorObj.get(DynamicDialogConfig);
      } catch (error) {
      }    

      if (this.configDialog.data) {
        this.api = this.configDialog.data.api;
        this.obj = this.configDialog.data.obj;
        if (this.configDialog.data.infoExtra)
          this.infoExtra = this.configDialog.data.infoExtra;
      }
  }

  uploadFiles(api, event) {
    const mensOk = this.translocoService.translate('message_upload_sucess');
    const mensErro = this.translocoService.translate('message_upload_failure');
    if (this.obj) {
      api.uploadFiles(event, this.obj.id, this.infoExtra)
      .then
      ((res: any) => {
        if (res) {
          this.messageService.add({ severity: 'success', summary: mensOk, detail: '' });
          this.fileUploadGeral.clear();
          this.refDialog.close(true);
        }
        else {
          this.messageService.add({ severity: 'error', summary: mensErro, detail: '' });
        }
      })
      .catch((res: any) => {
        this.errorHandler.handle(res);
      });
    }
    else {
      this.messageService.add({ severity: 'error', summary: this.translocoService.translate('message_upload_obj_failure'), detail: '' });
    }
  }

  onSelect(event) {
    this.atualizarListaPai.emit(event);
  }  
}