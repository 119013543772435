import { Component, Injector, OnInit } from '@angular/core';
import { ImportacaoListaModule } from './importacaolista.module';
import { ListaSimplesGenerica } from '../../generic/listagenerica/listasimplesgenerica';
import { ImportacaoStatus } from 'src/app/model/importacao/importacaoStatus';
import { ImportacaoStatusService } from 'src/app/services/importacao/importacao-status.service';

@Component({
  selector: 'app-lista-funcao',
  templateUrl: '../../generic/listagenerica/listasimples.component.html',
  styleUrls: [ '../../generic/listagenerica/listasimples.component.css']
})

export class ListaImportacaoStatusComponent extends ListaSimplesGenerica<ImportacaoStatus> implements OnInit {

  override obj = new ImportacaoStatus();

  constructor(
    injectorObj: Injector,
    api: ImportacaoStatusService
    ) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  dataExtraList(res: any) {
  }

  override getColorCell(valor: any) {   
  }
  
}
