<p-menubar>
    <ng-template pTemplate="start">
        <label class="fieldMandatory">
            {{'message_required_fields' | transloco }}
        </label>
    </ng-template>
    <ng-template pTemplate="end">
        <div class="flex">
            <button *ngIf="acao=='I' || acao=='A'" type="button" pButton (click)="insertMult(true, acao=='I')" icon="pi pi-save"></button>
        </div>
    </ng-template>
</p-menubar>

<div class="grid p-fluid mt-2 pr-3 pl-1">
    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label class="fieldMandatory" for="inputTipoDoc"> Tipo Documento</label>
        <p-dropdown *ngIf="acao=='I' && !objImportacaoTipoDoc" id="inputTipoDoc" name="inputTipoDoc" [options]="listaImpTipoDoc" [(ngModel)]="obj.importacaoTipoDocumento" optionLabel="nome" filterBy="nome"></p-dropdown>  
        <input *ngIf="acao=='A' || objImportacaoTipoDoc" pInputText id="inputTipoDoc2" name="inputTipoDoc2" type="text" [(ngModel)]="obj.importacaoTipoDocumento.nome" [disabled]="true">
    </div>
    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label class="fieldMandatory" for="inputNumeroDoc"> {{constantsAero.PREFIX_FIELD + '5693' | transloco }} </label>
        <input pInputText id="inputNumeroDoc" name="inputNumeroDoc" type="text" [(ngModel)]="obj.numero"  maxlength="30">
    </div>
    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label class="fieldMandatory" for="inputDataDocs"> {{constantsAero.PREFIX_FIELD + '5694' | transloco }} </label>
        <p-calendar id="inputDataDocs" name="inputDataDocs" 
            [(ngModel)]="obj.dataDocs" [showIcon]="true" 
            dateFormat="{{ 'date_short_format_form' | transloco }}"></p-calendar>
    </div>
</div>
<div *ngIf="tipo=='FIN'" class="grid p-fluid mt-2 pr-3 pl-1">
    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-1">
        <label class="fieldMandatory" for="inputTipo"> {{constantsAero.PREFIX_FIELD + '5953' | transloco }} </label>
        <p-dropdown id="inputTipo"
                name="inputTipo" [(ngModel)]="obj.tipo" [options]="constantsAero.opDebitoCredito"></p-dropdown>
    </div>
    
    <div *ngIf="obj.tipo=='D'" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-9">
        <app-lista-pesquisa [api]="apiContasPagar" [obj]="objContasPagar" [qtdProperties]="3" [disabled]="acao!='I' && acao!='A'"
        [properties]="['nTitulo','valor','status']" [labels]="['1635', '1638', '1640', '1643']" [larguraCampos]="[3,3,3,3]"
        [viewChek]="false" (atualizarObjPai)="atualizarContasPagar($event)">
        </app-lista-pesquisa>
    </div>
    <div *ngIf="obj.tipo=='C'" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-9">
        <app-lista-pesquisa [api]="apiContasReceber" [obj]="objContasReceber" [qtdProperties]="3" 
        [properties]="['nTitulo','valor','status']" [labels]="['1667', '1671', '1629', '1630']" [larguraCampos]="[3,3,3,3]"
        [viewChek]="false" (atualizarObjPai)="atualizarContasReceber($event)">
        </app-lista-pesquisa>
    </div>

    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label class="fieldMandatory" for="inputValorFin"> {{constantsAero.PREFIX_FIELD + '5957' | transloco }} </label>
        <p-inputNumber id="inputValorFin" name="inputValorFin" [(ngModel)]="obj.valorFin" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}"></p-inputNumber>
    </div>

</div>
<div class="grid p-fluid mt-2 pr-3 pl-1">
    <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-12"> 
        <label for="inputDescDocs"> {{constantsAero.PREFIX_FIELD + '5860' | transloco }} </label>
        <textarea pInputTextarea id="inputDescDocs" name="inputDescDocs" [(ngModel)]="obj.descricao"
        maxlength="1024"></textarea>
    </div>
</div>

<div class="grid p-fluid mt-2 pr-3 pl-1">
    <div *ngIf="obj.importacaoTipoDocumento.rotuloCampo1 && obj.importacaoTipoDocumento.rotuloCampo1.length > 0" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label for="inputCampo1"> {{obj.importacaoTipoDocumento.rotuloCampo1}} </label>
        <input pInputText id="inputCampo1" name="inputCampo1" type="text" [(ngModel)]="obj.campo1"  maxlength="60">
    </div>
    <div *ngIf="obj.importacaoTipoDocumento.rotuloCampo2 && obj.importacaoTipoDocumento.rotuloCampo2.length > 0" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label for="inputCampo2"> {{obj.importacaoTipoDocumento.rotuloCampo2}} </label>
        <input pInputText id="inputCampo2" name="inputCampo2" type="text" [(ngModel)]="obj.campo2"  maxlength="60">
    </div>
    <div *ngIf="obj.importacaoTipoDocumento.rotuloCampo3 && obj.importacaoTipoDocumento.rotuloCampo3.length > 0" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label for="inputCampo3"> {{obj.importacaoTipoDocumento.rotuloCampo3}} </label>
        <input pInputText id="inputCampo3" name="inputCampo3" type="text" [(ngModel)]="obj.campo3"  maxlength="60">
    </div>
    <div *ngIf="obj.importacaoTipoDocumento.rotuloValor1 && obj.importacaoTipoDocumento.rotuloValor1.length > 0" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label for="inputValor1"> {{obj.importacaoTipoDocumento.rotuloValor1}} </label>
        <p-inputNumber id="inputValor1"    name="inputValor1" [(ngModel)]="obj.valor1" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}"></p-inputNumber>
    </div>
    <div *ngIf="obj.importacaoTipoDocumento.rotuloValor2 && obj.importacaoTipoDocumento.rotuloValor2.length > 0" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label for="inputValor2"> {{obj.importacaoTipoDocumento.rotuloValor2}} </label>
        <p-inputNumber id="inputValor2"    name="inputValor2" [(ngModel)]="obj.valor2" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}"></p-inputNumber>
    </div>
    <div *ngIf="obj.importacaoTipoDocumento.rotuloValor3 && obj.importacaoTipoDocumento.rotuloValor3.length > 0" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
        <label for="inputValor3"> {{obj.importacaoTipoDocumento.rotuloValor3}} </label>
        <p-inputNumber id="inputValor3"    name="inputValor3" [(ngModel)]="obj.valor3" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="constantsAero.maxDigitisFieldsNumber" locale="{{translocoService.getActiveLang()}}"></p-inputNumber>
    </div>
    <div *ngIf="acao=='I' || acao=='A'" class="m-0 mt-3 pb-0 pr-1 pt-1 field col-12 md:col-12">
        <app-form-upload [api]="api" [obj]="obj" [showUploadButton]="false" (atualizarListaPai)="atualizarListaArquivos($event)"></app-form-upload>
    </div>

    <div class="m-0 mt-3 pb-0 pr-1 pt-1 field col-12 md:col-12">
        <div class="justify-content-center border-solid border-200 border-1 border-round-lg w-full">
            <span class="m-2 mt-4 mb-4 font-semibold">{{'file_attach' | transloco }}</span>
            <app-lista-arquivos [obj]="obj" [api]="api" [numLinhas]="1" [paginacao]="false" [largMd]="12" [largLg]="2"></app-lista-arquivos>
        </div>
    </div>
<div>
