import { ConceitoPessoa } from './../../model/geral/conceitoPessoa';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class ConceitoPessoaService extends GenericService<ConceitoPessoa>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("conceitopessoa")
  }
}
