import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Padrao } from 'src/app/model/generic/padrao';
import { GenericModule } from '../../generic/generic.module';

@Component({
  selector: 'app-form-campos-padroes',
  templateUrl: './formcampospadroes.component.html',
  styleUrls: [ '../formgenerico/formgenerico.component.css']
})
export class FormCamposPadroesComponent implements OnInit {

  @Input() obj: Padrao;
  optionsYesNo: any[];  

  constructor(private translocoService: TranslocoService) {
  }

  ngOnInit(): void {
    const yes = this.translocoService.translate("yes");
    const no = this.translocoService.translate("no");
    this.optionsYesNo = [{label: yes, value: true}, {label: no, value: false}];

    //console.log(this.obj)
  }
}