import { Empresa } from '../empresa/empresa';
import { Pessoa } from '../pessoa/pessoa';
import { ImportacaoHistStatus } from './importacaoHistStatus';

export class ImportacaoTimeLine {

    constructor(responsavel: string, pesResp: Pessoa | Empresa | null, rotuloRep: string, pesResp2: Pessoa | null) {
        this.responsavel = responsavel;
        this.pessoaResponsavel = pesResp;
        this.rotuloResp = rotuloRep;
        this.pessoaResponsavel2 = pesResp2;
    }

    rotuloResp: string;
    responsavel: string;
    pessoaResponsavel: Pessoa | Empresa | null;
    pessoaResponsavel2: Pessoa | Empresa | null;
    listaHistStatus: ImportacaoHistStatus[];
    quantHist: number = 0;
    quantHistTot: number = 0;

    getPercent(): number {
        return Math.round(this.quantHist/this.quantHistTot*100);
    }

}