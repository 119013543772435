import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/security/auth.guard';
import { ListaImportacaoComponent } from './listaimportacao';
import { ListaImportacaoDocumentoComponent } from './listaimportacaodocumento';
import { ListaImportacaoInvoiceComponent } from './listaimportacaoinvoice';
import { ListaImportacaoTipoDocumentoComponent } from './listaimportacaotipodocumento';
import { ListaImportacaoStatusComponent } from './listaimportacaostatus';


@NgModule({
    imports: [RouterModule.forChild([
        { path: 'listaimportacaotipodocumento', component: ListaImportacaoTipoDocumentoComponent, canActivate: [AuthGuard] },
        { path: 'listaimportacao', component: ListaImportacaoComponent, canActivate: [AuthGuard] },
        { path: 'listaimportacaodocumento', component: ListaImportacaoDocumentoComponent, canActivate: [AuthGuard] },
        { path: 'listaimportacaoinvoice', component: ListaImportacaoInvoiceComponent, canActivate: [AuthGuard] },
        { path: 'listaimportacaostatus', component: ListaImportacaoStatusComponent, canActivate: [AuthGuard] }
    ])],
    exports: [RouterModule]
})
export class ImportacaoListaRoutingModule { }
