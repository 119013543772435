import { Padrao } from '../generic/padrao';
import { PessoaRegra } from '../pessoa/pessoaRegra';

export class ConceitoPessoa extends Padrao{

    override idTab = 237;

    pessoaRegra: PessoaRegra = new PessoaRegra();
    descricao: string;
    sistema: string;
    limiteCredVenda: number;
    limiteCredOs: number;
}