import { Aeronave } from '../aeronave/aeronave';
import { Padrao } from '../generic/padrao';
import { AdendoPecas } from './adendoPecas';

export class AdendoPecasAeronave extends Padrao{

    override idTab = 142;

    adendoPecas: AdendoPecas = new AdendoPecas();
    aeronave: Aeronave = new Aeronave();
}