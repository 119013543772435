import { AdendoPecasConstituicao } from './../../model/adendo/adendoPecasConstituicao';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class AdendoPecasConstituicaoService extends GenericService<AdendoPecasConstituicao>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("adendopecasconstituicao")
  }
}
