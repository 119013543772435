import { Padrao } from "../generic/padrao";

export class FiscalCest extends Padrao{

    override idTab = 188;

    cest: string;
    ncm: string;
    segmento: string;
    item: string;
    descricao: string;
}