import { Padrao } from '../generic/padrao';
import { EndEstado } from './endEstado';

export class EndCidade extends Padrao{

    override idTab = 168;

    endEstado: EndEstado = new EndEstado();
    nome: string;
    ibge: number;
    cepGeral: number;
    ddd: string;
}