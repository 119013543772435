import { Component, Injector, OnInit } from '@angular/core';
import { ImportacaoListaModule } from './importacaolista.module';
import { ListaSimplesGenerica } from '../../generic/listagenerica/listasimplesgenerica';
import { ImportacaoTipoDocumento } from 'src/app/model/importacao/importacaoTipoDocumento';
import { ImportacaoTipoDocumentoService } from 'src/app/services/importacao/importacao-tipo-documento.service';

@Component({
  selector: 'app-lista-funcao',
  templateUrl: '../../generic/listagenerica/listasimples.component.html',
  styleUrls: [ '../../generic/listagenerica/listasimples.component.css']
})

export class ListaImportacaoTipoDocumentoComponent extends ListaSimplesGenerica<ImportacaoTipoDocumento> implements OnInit {

  override obj = new ImportacaoTipoDocumento();

  constructor(
    injectorObj: Injector,
    api: ImportacaoTipoDocumentoService
    ) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  dataExtraList(res: any) {
  }

  override getColorCell(valor: any) {   
  }
  
}
