import { Padrao } from '../generic/padrao';
import { EndPais } from './endPais';
import { EndEstadoAliquota } from './endEstadoAliquota';

export class EndEstado extends Padrao{

    override idTab = 166;

    endEstadoAliquota: EndEstadoAliquota = new EndEstadoAliquota();
    endPais: EndPais = new EndPais();
    nome: string;
    sigla: string;
    ibge: number;
    redBase: number;
    redBaseSt: number;
    iva: number;
    aliq: number;
    permiteIsento: string;
    percFundoPobreza: number;
    ordem: number;
    retencaoSt: string;
}