import { Pessoa } from './pessoa';

export class PesDadosPf extends Pessoa {

    rg: string;
    natural: string;
    dataNasc: Date = new Date();
    sexo: string = 'M';
    pai: string;
    mae: string;
    empresa: string;
    dataCont: Date = new Date();
    estadoCivil: string;
    rgOrgao: string;
    rgEmissao: Date = new Date();
    cartHab: string;
}