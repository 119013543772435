<p-menubar>
    <ng-template pTemplate="start">
        <label class="fieldMandatory">
            {{'message_required_fields' | transloco }}
        </label>
    </ng-template>
    <ng-template pTemplate="end">
        <div class="flex">
            <app-lista-pesquisa  *ngIf="acao=='P'" [api]="api" [obj]="objImpHistAll" [qtdProperties]="0" [limpar]="false"
            [properties]="['id', 'pergunta', 'resposta']" [labels]="['5787', '5791', '5792']"  [larguraCampos]="[12]"
            [viewChek]="false" (atualizarObjPai)="atualizarHistAll($event)" [textButton]="'Pesquisar Perguntas'"
            class="mr-5">
            </app-lista-pesquisa>
    
            <button class="ml-5" type="button" pButton (click)="insertMult(true, acao=='P')" icon="pi pi-save"></button>
        </div>
    </ng-template>
</p-menubar>

<div *ngIf="acao=='P'">
    <div *ngIf="obj" class="grid p-fluid mt-2 pr-3 pl-1">
        <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
            <label class="fieldMandatory" for="inputDataPergunta"> {{constantsAero.PREFIX_FIELD + '5795' | transloco }} </label>
            <p-calendar id="inputDataPergunta" name="inputDataPergunta" 
                [(ngModel)]="obj.dataPergunta" [showIcon]="true" [disabled]="true"
                dateFormat="{{ 'date_short_format_form' | transloco }}"></p-calendar>
        </div>
        <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
            <label for="inputItemInvoice">{{constantsAero.PREFIX_FIELD + '5821' | transloco }} </label>
            <p-dropdown id="inputItemInvoice" name="inputItemInvoice"
                [options]="listaFiscalInvoiceItens" [(ngModel)]="objFiscalInvoiceItens" [showClear]="true"
                placeholder="-" optionLabel="adendoPecas.pn" [filter]="true" filterBy="adendoPecas.pn">
            </p-dropdown>
        </div>
        <div *ngIf="objFiscalInvoiceItens" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-4">
            <label>{{constantsAero.PREFIX_FIELD + '5820' | transloco }}</label>
            <input pInputText type="text" [(ngModel)]="objFiscalInvoiceItens.adendoPecas.descricao" [disabled]="true">
        </div>
        <div *ngIf="objFiscalInvoiceItens" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
            <label>{{constantsAero.PREFIX_FIELD + '4163' | transloco }}</label>
            <input pInputText type="text" [(ngModel)]="objFiscalInvoiceItens.fabricante.nome" [disabled]="true">
        </div>
        <div *ngIf="objFiscalInvoiceItens" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-1">
            <label>{{constantsAero.PREFIX_FIELD + '4070' | transloco }}</label>
            <input pInputText type="text" [(ngModel)]="objFiscalInvoiceItens.quant" [disabled]="true">
        </div>
        <div *ngIf="objFiscalInvoiceItens" class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-1">
            <label>{{constantsAero.PREFIX_FIELD + '4999' | transloco }}</label>
            <input pInputText type="text" [(ngModel)]="objFiscalInvoiceItens.fiscalInvoice.numTitulo" [disabled]="true">
        </div>
        <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-12">
            <label class="fieldMandatory" for="inputPergunta"> {{constantsAero.PREFIX_FIELD + '5791' | transloco }} </label>
            <textarea id="inputPergunta" name="inputPergunta" rows="4" cols="30" pInputTextarea [(ngModel)]="obj.pergunta"></textarea>
        </div>
    </div>
</div>

<div *ngIf="acao=='R' || acao=='A'">
    <div *ngIf="obj" class="grid p-fluid mt-2 pr-3 pl-1">
        <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-3">
            <div class="grid p-fluid mt-2 pr-3 pl-1 mb-2">
                <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                    <span>{{constantsAero.PREFIX_FIELD + '5795' | transloco }}</span>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                    <span class="font-bold">{{ obj.dataPergunta  | date: globalFunctions.getDataFormat() }}</span>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                    <span>{{constantsAero.PREFIX_FIELD + '5821' | transloco }}</span>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                    <span class="font-bold">{{ obj?.fiscalInvoiceItens?.adendoPecas?.pn}}</span>
                </div>
            </div>
        </div>
        <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-7">
            <div class="grid p-fluid mt-2 pr-3 pl-1 mb-2">
                <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                    <span>{{constantsAero.PREFIX_FIELD + '5789' | transloco }}</span>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-12">
                    <span class="font-bold">{{ obj?.pessoaPergunta?.nome}}</span>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-9">
                    <span>{{constantsAero.PREFIX_FIELD + '5820' | transloco }}</span>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-3">
                    <span>{{constantsAero.PREFIX_FIELD + '5823' | transloco }}</span>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-9">
                    <span class="font-bold">{{ obj?.fiscalInvoiceItens?.adendoPecas?.descricao }}</span>
                </div>
                <div class="m-0 pb-0 pr-1 pt-1 field col-3">
                    <span class="font-bold">{{ obj?.fiscalInvoiceItens?.adendoPecas?.fabricante?.nome }}</span>
                </div>
            </div>
        </div>
        <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-2">
            <label for="inputDataResposta"> {{constantsAero.PREFIX_FIELD + '5796' | transloco }} </label>
            <p-calendar id="inputDataResposta" name="inputDataResposta" 
                [(ngModel)]="obj.dataResposta" [showIcon]="true" [disabled]="true"
                dateFormat="{{ 'date_short_format_form' | transloco }}"></p-calendar>
        </div>
        <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-12">
            <label for="inputPergunta"> {{constantsAero.PREFIX_FIELD + '5791' | transloco }} </label>
            <textarea id="inputPergunta" name="inputPergunta" rows="4" cols="30" pInputTextarea [(ngModel)]="obj.pergunta" [disabled]="true"></textarea>
        </div>
        <div class="m-0 pb-0 pr-1 pt-1 field col-12 md:col-12">
            <label class="fieldMandatory" for="inputResposta"> {{constantsAero.PREFIX_FIELD + '5792' | transloco }} </label>
            <textarea id="inputResposta" name="inputResposta" rows="4" cols="30" pInputTextarea [(ngModel)]="obj.resposta"></textarea>
        </div>
    </div>
</div>

<div class="m-0 mt-3 pl-1 pb-0 pr-1 pt-1 field col-12 md:col-12">
    <app-form-upload [api]="api" [obj]="obj" [infoExtra]="[idImportacao.toString(), imgPergunta.toString()]" [showUploadButton]="false" (atualizarListaPai)="atualizarListaArquivos($event)"></app-form-upload>
</div>
