import { Component, Injector, OnInit } from '@angular/core';
import { FormGenerico } from '../../generic/formgenerico/formgenerico';
import { ImportacaoFormModule } from './importacaoform.module';
import { ImportacaoInvoice } from 'src/app/model/importacao/importacaoInvoice';
import { ImportacaoInvoiceService } from 'src/app/services/importacao/importacao-invoice.service';

@Component({
  selector: 'app-form-functions',
  templateUrl: '../../generic/formgenerico/formgenerico.component.html',
  styleUrls: [ '../../generic/formgenerico/formgenerico.component.css']
})

export class FormImportacaoInvoiceComponent extends FormGenerico<ImportacaoInvoice> implements OnInit {

  uploadedFiles: any[] = [];

  constructor(
    injectorObj: Injector,
    api: ImportacaoInvoiceService) 
  {
    super(injectorObj, api);
    this.obj = new ImportacaoInvoice();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  onBasicUpload(event) {
    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode' });
}

dataExtraObj(res: any) {
}

}
