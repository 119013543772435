import { Padrao } from '../generic/padrao';
import { EndPais } from '../endereco/endPais';

export class Agencia extends Padrao{

    override idTab = 206;

    endPais: EndPais = new EndPais();
    descricao: string;
    sigla: string;
}