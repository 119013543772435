import { Adendo } from './../adendo/adendo';
import { Padrao } from '../generic/padrao';
import { Manuais } from '../documento/manuais';
import { Fabricante } from '../estoque/fabricante';


export class ModeloAeronave extends Padrao{

    override idTab = 152;

    adendo: Adendo = new Adendo();
    fabricante: Fabricante = new Fabricante();
    manuais: Manuais = new Manuais();
    tipo: string;
    modelo: string;
    obs: string;
}