import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";
import { AeronaveService } from "src/app/services/aeronave/aeronave.service";
import { ModeloAeronaveService } from "src/app/services/aeronave/modelo-aeronave.service";
import { RequisicaoClassService } from "src/app/services/compra/requisicao-class.service";
import { PessoaService } from "src/app/services/pessoa/pessoa.service";
import { AuthService } from "src/app/services/security/auth.service";

export class ConstantsAero {

    readonly PREFIX_MENU = 'label_sysmenu_';
    readonly PREFIX_FIELD = 'label_sysfield_';
    readonly PREFIX_TABLE = 'label_systab_';

    static readonly DATE_FMT_BR = 'dd/MMM/yyyy';
    static readonly DATE_TIME_FMT_BR = `${ConstantsAero.DATE_FMT_BR} hh:mm:ss`;

    static readonly SEPAR_CSV = ';';
    
    readonly maxDigitisFieldsNumber=4;
    readonly maxDigitisFieldsNumberCompra=2;

    readonly opSimNao = ['SIM', 'NÃO'];
    readonly opRegTributario = ['SIMPLES NACIONAL', 'LUCRO PRESUMIDO', 'LUCRO REAL'];
    readonly opContribuinte = ['ISENTO', 'SIM', 'NÃO'];
    readonly opStatus = ['Ativo', 'Inativo'];
    readonly opSexo = ['M', 'F'];
    readonly opEstadoCivil = ['Casado', 'Divorciado', 'Separado Judicialmente', 'Solteiro', 'Viúvo'];
    readonly opTipoTelefone = ['Celular', 'Comercial', 'Fax', 'Outros', 'Recado', 'Residencial', 'Responsavel'];
    readonly opTipoConta = ['Corrente', 'Poupança', 'Investimento'];
    readonly opTipoPagamento = ['Boleto', 'Cobrança', 'Comercial', 'Correspondência', 'Entrega', 'Faturamento', 'Outro', 'Recado',];
    readonly opStatusPendente = ['OK', 'SPC', 'SERASA', 'SPC/SERASA', 'INTERNA', 'SEM CONSULTA'];
    readonly opCategoria = ['RECEITA/DESPESA', 'TRANSFERÊNCIA']
    readonly opItensStatusImpImportador = ['01 - Aberto', '02 - Definido', '03 - Def. Agentes', '04 - Tradução', '05 - Info. Canal', '06 - Nfe', '07 - Transporte', '08 - Concluído'];
    readonly opItensStatusImpCarga      = ['01 - Início', '02 - WR', '03 - AWB Inicial', '04 - AWB Final', '05 - Concluído',];
    readonly opItensStatusImpAduaneiro  = ['01 - Início', '02 - Numerário', '03 - DTA', '04 - Armazenagem', '05 - DI', '06 - Carregamento', '07 - Concluído'];
    readonly opItensCanais = ['Branco', 'Cinza', 'Amarelo', 'Verde', 'Vermelho'];
    readonly opItensStatusHistorico = ['Aberto', 'Fechado'];
    readonly opItensEndPadrao = ['S', 'N'];
    readonly opTipoPessoa = [{label: 'Jurídica', value: 'J'}, {label: 'Física', value: 'F'}, {label: 'Externo', value: 'E'}];;
    readonly opItensModal = ['Aeroviário', 'Ferroviário', 'Rodoviário', 'Aquaviário', 'Dutoviário'];
    readonly opDebitoCredito = ['D', 'C'];
    readonly opPreCompraCateg = ['DESPESA', 'TRANSFERÊNCIA'];
    readonly opPreCompraTpOrigem = ['NACIONAL', 'IMPORTAÇÃO'];
    readonly opPreCompraStatus = ['ABERTO', 'FINALIZADO', 'FATURADO', 'CANCELADO'];
    readonly opPreCompraItensCore = ['S', 'N'];
    readonly opPreEtapaCadastro = ['COMPLETO']; //Verificar com o MARCELO os demais campos
    readonly opVendedorTipo = ['FIXO', 'VARIÁVEL'];
    readonly opPedidoCompraStatus = ['ABERTO'];
    readonly opPedidoCompraPrioridade = ['NORMAL', 'URGENTE', 'AOG'];
    readonly opPedidoCompraTipo = ['NACIONAL', 'IMPORTAÇÃO'];
    /* readonly opExportArquivo = ['CSV', 'Excel', 'HTML', 'PDF', 'TEXTO', 'XML']; */
    readonly opExportArquivo = [
      { nome: 'CSV', modal: 'csv' },
      { nome: 'Excel', modal: 'excel' },
      { nome: 'HTML', modal: 'html' },
      { nome: 'PDF', modal: 'pdf' },
      { nome: 'TXT', modal: 'txt' },
      { nome: 'XML', modal: 'xml' }
    ];
    readonly opShipping = [
      { nome: 'Email', modal: 'email' },
      /* { nome: 'WhatsApp', modal: 'whatsapp' } */
    ];
    readonly oPedidoCompraItensStatus = ['Aberto', 'Cancelado']
}

export class QueryGeneric {
  
  api: any;
  auth: AuthService;

  constructor(
    private className: string, 
    protected http: HttpClient,
    private injectorObj: Injector
    ) 
  {
    this.auth = this.injectorObj.get(AuthService);
    
    if (className == 'RequisicaoClass') {
      this.api = new RequisicaoClassService(http);
    }
    else if (className == 'Aeronave') {
      this.api = new AeronaveService(http);
    }
    else if (className == 'ModeloAeronave') {
      this.api = new ModeloAeronaveService(http);
    }
    /*else if (className == 'Pessoa') {
      this.api = new PessoaService(http);
    } */
  }

  query() {
    return this.api.findAll(this.auth.empresa.id);
  }
}