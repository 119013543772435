import { Padrao } from '../generic/padrao';

export class FiscalCst extends Padrao{

    override idTab = 172;

    descricao: string;
    valor: string;
    tributacao: string;
    simples: string;
    temSt: string;
    observacao: string;
    mensagem: string;
}