<ng-template pTemplate="header">
<h5>{{ this.title }}</h5>
</ng-template>

<p-table #dt id="dt" [columns]="cols" [value]="list" [resizableColumns]="true"
    [rowHover]="true" [(selection)]="listSel" dataKey="id"
    [paginator]="true" [rows]="10"
    currentPageReportTemplate="{{'title_total_records' | transloco }} {totalRecords}."
    styleClass="p-datatable-striped p-datatable-sm"
    responsiveLayout="stack" [breakpoint]="'768px'">

    <ng-template pTemplate="caption">
        <div class="grid p-fluid">
            <!--
            <span class="p-input-icon-left mb-2">
                <i class="pi pi-search"></i>
                <input pInputText type="text" #filter (input)="onGlobalFilter(dt, $event)" placeholder=" {{'title_search' | transloco }} " class="w-full"/>
            </span>
-->

            <div class="col-12 md:col-9">
                <div class="grid m-0 p-0">
                    <div class="col-12 md:col-2 m-0 p-0 pr-1 field">
                        <p-dropdown id="inputFiltroCampo" name="inputFiltroCampo" [(ngModel)]="dadosPesquisa.campo" 
                        [options]="colsFilter" autoWidth="false" emptyMessage="">
                        </p-dropdown>
                    </div>
                    <div class="col-12 md:col-3 m-0 p-0 pr-1 field">
                        <input pInputText id="inputFiltro" type="text" [(ngModel)]="dadosPesquisa.valor" placeholder=" {{'title_search' | transloco }} "/>
                    </div>

                    <div class="col-12 md:col-2 m-0 p-0 pr-1 field" *ngIf="colsDataFilter.length > 1">
                        <p-dropdown id="inputFiltroCampoData" name="inputFiltroCampoData" [(ngModel)]="dadosPesquisa.campoData" 
                        [options]="colsDataFilter" autoWidth="false" emptyMessage="" [autoDisplayFirst]="false">
                        </p-dropdown>
                    </div>
                    <div class="col-12 md:col-4 m-0 p-0 pr-1 field" *ngIf="colsDataFilter.length > 1">
                        <div class="grid m-0 p-0">
                            <div class="col-12 md:col-6 m-0 p-0 pr-1 field">
                                <p-calendar id="inputFiltroDataIni" name="inputFiltroDataIni" [(ngModel)]="dadosPesquisa.dataInicial" dateFormat="{{ 'date_short_format_form' | transloco }}" [showIcon]="true"></p-calendar>
                            </div>
                            <div class="col-12 md:col-6 m-0 p-0 pr-1 field">
                                <p-calendar id="inputFiltroDataFim" name="inputFiltroDataFim" [(ngModel)]="dadosPesquisa.dataFinal" dateFormat="{{ 'date_short_format_form' | transloco }}" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-1 m-0 p-0">
                        <button pButton title="{{'button_search' | transloco }}" class="buttonMargin" icon="pi pi-fw pi-search" (click)="find()"></button>
                    </div>
                </div>
            </div>

            <div *ngIf="viewChek" class="col-12 md:col-3">
                <button pButton icon="pi pi-check" title="{{'button_ok' | transloco }}" class="buttonMargin"></button>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngIf="viewChek" pResizableColumn><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
            <th *ngIf="!viewChek"></th>
            <th pResizableColumn>Ord</th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn>
                {{col.header | transloco }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-iLin="rowIndex">
        <tr [pSelectableRow]="rowData">
            <td *ngIf="viewChek" style="min-width:20px; width:20px"><p-tableCheckbox [value]="rowData"></p-tableCheckbox></td>      
            <td *ngIf="!viewChek" style="min-width:15px; width:15px"><button pButton type="button" icon="pi pi-check" class="p-button-outlined p-button-success" (click)="selectedAndClose(rowData)"></button></td>
            <td style="min-width:30px; width:30px">{{iLin+1 | number:'3.0-0'}}</td>
            <td *ngFor="let col of columns; let iCol = index" style="width: col.widthList px">
                <span *ngIf="col.type == 'N° Inteiro' && col.field == 'id'" style="float: center">{{ globalFunctions.getIdFormat(rowData[col.field]) }}</span>                        
                <span *ngIf="col.type == 'N° Real'" style="float: right">{{ globalFunctions.getFormatNumber(rowData[col.field]) }}</span>
                <span *ngIf="col.type == 'Data'" style="float: center">{{ rowData[col.field] | date: globalFunctions.getDataFormat() }}</span>                        
                <span *ngIf="col.type == 'DataHora'" style="float: center">{{ rowData[col.field] | date: globalFunctions.getDataHoraFormat() }}</span> 
                <span *ngIf="col.type == 'Obj'">{{rowData[col.field][col.fieldView]}}</span>                        
                <span *ngIf="col.field != 'id' && col.type != 'N° Real' && col.type != 'N° Inteiro' && col.type != 'Data' && col.type != 'DataHora' && col.type != 'Obj'">
                    {{rowData[col.field]}} 
                </span>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
        <tr>
            <td>{{ 'title_total_records' | transloco }} {{ globalFunctions.getTotalRecords(list) }}</td>
            <td></td>
            <td *ngFor="let col of columns">
                <span *ngIf="col.type == 'N° Real'" style="float: right">{{ globalFunctions.getTotalColuna(col.field, list, undefined) }}</span>
            </td>
        </tr>
    </ng-template>        

    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8" style="text-align:left">{{'title_no_records' | transloco }}</td>
        </tr>
    </ng-template>
</p-table>