import { ContasPagar } from './../../model/financeiro/contasPagar';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { CtaPagarCentroCusto } from 'src/app/model/financeiro/CtaPagarCentroCusto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContasPagarService extends GenericService<ContasPagar>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("contaspagar")
  }

  finalizarCompra(idEmpresa: number, payload: { contasPagarList: ContasPagar[]; centrosDeCustoList: CtaPagarCentroCusto[] }): Observable<any> {
    const url = `${this.url}/${idEmpresa}/finalizar`;
    return this.http.post<any>(url, payload); // Enviando o objeto payload
  }

  deleteContaPagar(idEmpresa: number, cprId: number): Observable<void> {
    const url = `${this.url}/${idEmpresa}/delete/${cprId}`;
    return this.http.delete<void>(url);
  }
  
}
