import { MenuItem } from "primeng/api/menuitem";
import { MessageService, ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Injector, ViewChild, OnInit, Injectable, ElementRef, OnDestroy } from '@angular/core';
import { Table } from "primeng/table";
import { SysCampoService } from "src/app/services/system/sys-campo.service";
import { SysTabelaService } from "src/app/services/system/sys-tabela.service";
import { GenericService } from "src/app/services/generics/generic.service";
import { TranslocoService } from "@ngneat/transloco";
import * as FileSaver from 'file-saver';
import { ErrorHandlerService } from "src/app/services/system/error-handler.service";
import { AuthService } from "src/app/services/security/auth.service";
import { Padrao } from "src/app/model/generic/padrao";
import { AcessosGrupo } from "src/app/model/security/acessosGrupo";
import { AcessosGrupoService } from "src/app/services/security/acessos-grupo.service";
import { SysCampo } from "src/app/model/system/sysCampo";
import { SysTabela } from "src/app/model/system/sysTabela";
import { DadosPesquisa } from "src/app/model/system/dadosPesquisa";
import { ConstantsAero } from "../constantsAero";
import { GlobalFunctions } from "../global-functions";
import { GeralListaModule } from "../../geral/listas/gerallista.module";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Injectable()
export abstract class ListaSimplesGenerica<T extends Padrao> implements OnInit, OnDestroy {
/*   ngAfterViewInit() {
    throw new Error('Method not implemented.');
  } */

  title: string;
  linkForm: string;
  obj: T;
  objSel: T;
  indiceSel: number = 0;
  list: T[] = [];
  listSel: T[];
  multCheck = false;

  //Lista Externa
  listExt: any[];
  colsExt: any[];
  titleExt: string;
  
  itemsPrint: MenuItem[];
  itemsFunctions: MenuItem[];
  itemsFunctionsItems: MenuItem[] = [];
  itemsFunctionsSubItems : MenuItem[] = [];
  itemsPesqFiltro: MenuItem[];

  cols: any[];
  colsExport: any[];

  colsFilter: any[] = [];
  colsDataFilter: any[] = [];
  filtrosExtra: any[] = []; // tipo combo
  filtroExtraSel: any[] = [];

  dadosPesquisa = new DadosPesquisa();

  viewFiltro: boolean = false;
    
  listSysCampo: SysCampo[] = [];
  objSysTabela: SysTabela;
  acessosGrupo: AcessosGrupo = new AcessosGrupo();
  
  @ViewChild('dt') dt: Table;
  @ViewChild('filter') filter!: ElementRef;

  messageService: MessageService;
  confirmationService: ConfirmationService;
  translocoService: TranslocoService;

  router: Router;
  activatedRoute: ActivatedRoute;

  apiSysFiels: SysCampoService;
  apiSysTabela: SysTabelaService;
  apiAcessosGrupo: AcessosGrupoService;

  errorHandler: ErrorHandlerService;

  auth: AuthService;

  //Dialog
  ref: DynamicDialogRef;
  dialogService: DialogService;

  titleDialog: string = '';
  componentDialog: any = null;

  abstract dataExtraList(res: any);
  abstract getColorCell(valor: any);

  protected constantsAero: ConstantsAero = new ConstantsAero();
  protected globalFunctions: GlobalFunctions;

  public viewportWidth: number = 0;
  public viewportHeight: number = 0;

  constructor(
    protected injectorObj: Injector,
    protected api: GenericService<T>
    ) 
  {
    this.messageService = this.injectorObj.get(MessageService);
    this.confirmationService = this.injectorObj.get(ConfirmationService);
    this.translocoService = this.injectorObj.get(TranslocoService);

    this.router = this.injectorObj.get(Router);
    this.activatedRoute = this.injectorObj.get(ActivatedRoute);

    this.apiSysFiels = this.injectorObj.get(SysCampoService);
    this.apiSysTabela = this.injectorObj.get(SysTabelaService);
    this.apiAcessosGrupo = this.injectorObj.get(AcessosGrupoService);
    
    this.dialogService = this.injectorObj.get(DialogService);

    this.errorHandler = this.injectorObj.get(ErrorHandlerService);

    this.auth = this.injectorObj.get(AuthService);

    this.viewportWidth = window.innerWidth;
    this.viewportHeight = window.innerHeight;        
  }

  ngOnInit(): void {

    this.globalFunctions = new GlobalFunctions(this.translocoService, this.injectorObj);

    this.findTable();

    if ((this.auth.dadosPesquisa) /* && (this.auth.dadosPesquisa.idTab == this.obj.idTab) */) {
      this.dadosPesquisa = this.auth.dadosPesquisa;
    }

    this.createItemsButtonPrint();
    this.createItemsButtonFuctions();
    this.createItemsButtonFuctionsItems();
    this.createItemsButtonPesqFiltro();
    this.findAccess();
  }

  createItemsButtonPrint() {
    this.itemsPrint = [
      { label: this.translocoService.translate('menu_print_list_default'), command: () => { this.exportPdf(); } }
    ];
  }

  createItemsButtonFuctions() {

    if (this.auth.menuItemSelecionado.filtrar) {
      const expCsv = this.translocoService.translate('menu_function_export_csv');
      const expExc = this.translocoService.translate('menu_function_export_spreadsheet');
      const mult = this.translocoService.translate('menu_function_export_mult_check');

      this.itemsFunctions = [
        { separator: true },
/*         { label: mult, command: () => { this.changeTypeSelection() } },
         { separator: true }, */
/*        { label: expCsv, command: () => { this.exportCSVLocal(this.dt); } },
        { label: expExc, command: () => { this.exportExcel(); } } */
      ];
    }
  }

  createItemsButtonFuctionsItems() {
    // Verifica se os itens já foram adicionados
    if (this.itemsFunctionsItems.length > 0) {
        return;
    }

    if (this.auth.menuItemSelecionado.filtrar) {
        const visual = this.translocoService.translate('hint_button_view');
        const alterar = this.translocoService.translate('hint_button_update');
        const excluir = this.translocoService.translate('hint_button_delete');

        // Atribui diretamente os itens ao array, substituindo qualquer conteúdo anterior
        this.itemsFunctionsItems = [
            { separator: true },
            { label: visual, command: () => { this.openForm(this.objSel, 'view') } },
            { 
                label: alterar, 
                command: () => { 
                    // Validação da empresa antes de abrir o formulário de alteração
                    if (this.objSel && this.auth.empresa.id !== this.objSel.empresaCad) {
                        this.messageService.add({
                            severity: 'warn',
                            summary: this.translocoService.translate('mens_action_not_allowed'),
                            detail: this.translocoService.translate('mens_action_not_allowed_detail', { 
                              moduleTitle: this.title, 
                              empresaLogada: this.auth.empresa.nome,
                              codigoEmpresaLogada: this.auth.empresa.id,
                              empresaAssociada: this.objSel.empresaCad,
                          })
                        });
                    } else {
                        this.openForm(this.objSel, 'update'); 
                    }
                } 
            },
            { separator: true },
            { label: excluir, command: () => { this.delete(this.objSel); } },
            { separator: true }
        ];
    }
  }

/*   createItemsButtonFuctionsItems() {

    if (this.auth.menuItemSelecionado.filtrar) {
      const visual = this.translocoService.translate('hint_button_view');
      const alterar = this.translocoService.translate('hint_button_update');
      const excluir = this.translocoService.translate('hint_button_delete');

      if (this.acessosGrupo.visualizar == 'Sim') {
        this.itemsFunctionsItems.push({ label: visual, command: () => { this.openForm(this.objSel, 'view') } });
      }
      if (this.acessosGrupo.alterar == 'Sim') {
        this.itemsFunctionsItems.push({ label: alterar, command: () => { this.openForm(this.objSel, 'update'); } });
      }
      if (this.acessosGrupo.excluir == 'Sim') {
        this.itemsFunctionsItems.push({ separator: true });
        this.itemsFunctionsItems.push({ label: excluir, command: () => { this.delete(this.objSel); } });
      }
      this.itemsFunctionsItems.push({ separator: true });
    }
  } */

  createItemsButtonPesqFiltro() {
    this.itemsPesqFiltro = [
      { label: this.translocoService.translate('button_search_filter'), command: () => { this.viewFiltro = !this.viewFiltro } }
    ];
  }

  find() {
    //console.log(this.dadosPesquisa);
    //if ((this.dadosPesquisa.valor.trim().length > 0) || (this.dadosPesquisa.campoData.trim().length > 0)) {
    if (true) {
      if (this.dadosPesquisa.campo == 'id') {
        let cod: number = parseInt(this.dadosPesquisa.valor);
        if (Number.isInteger(cod)) {
          this.findFilterAll();
        }
        else {
          this.errorHandler.handle(this.translocoService.translate('message_enter_only_numbers_integer'));
        }
      }
      else {
        this.findFilterAll();
      }
    }
    else {
      this.api.findAll(this.auth.empresa.id)
        .toPromise()
        .then
        ((res: any) => {
          this.list = res;
          this.dataExtraList(res)
        })
        .catch
        (( error ) => {
          this.errorHandler.handle(error);
        }
        );
      }
  }

  findFilterAll() {
    this.dadosPesquisa.camposFiltro = [];
    this.dadosPesquisa.valoresFiltro = [];
    this.filtrosExtra.map((obj: SysCampo, idx: number) => {
      if ((this.filtroExtraSel[idx]) && (this.filtroExtraSel[idx].length > 0)) {
        this.dadosPesquisa.camposFiltro.push(obj.nomeProp);
        this.dadosPesquisa.valoresFiltro.push(this.filtroExtraSel[idx].toString());
      }
    });
  
    //console.log(this.dadosPesquisa);
    //this.dadosPesquisa.idTab = this.obj.idTab;
    this.auth.dadosPesquisa = this.dadosPesquisa;
  
    this.api.findFilterAll(this.auth.empresa.id, this.dadosPesquisa, false)
      .toPromise()
      .then((res: any) => {
        //console.log("Dados recebidos do servidor:", res); // Adiciona um console.log aqui para verificar os dados
        this.list = res;
        this.dataExtraList(res);
      })
      .catch((error) => {
        this.errorHandler.handle(error);
      });
  }  

  findById(id: number) {
    this.api.findById(this.auth.empresa.id, id)
      .toPromise()
      .then
      ((res: any) => {
        this.obj = res;
        this.obj.dataCad = new Date(res.dataCad);
      })
  }

  findByIdList(id: number) {
    this.api.findById(this.auth.empresa.id, id)
      .toPromise()
      .then((res: any) => {
        //console.log("Dados recebidos do servidor no findByIdList:", res); // Adiciona um console.log aqui para verificar os dados
        this.list = [];
        if (res) {
          this.obj = res;
          this.list.push(this.obj);
          this.dataExtraList(res);
        }
      });
  }
  

  findTable() {
    this.apiSysTabela.findListById(this.obj.idTab)
      .toPromise()
      .then
      ((res: any) => {
        this.objSysTabela = res;
        const t = this.constantsAero.PREFIX_TABLE + this.objSysTabela.id;
        this.title = this.translocoService.translate(t);

        const recurso = this.objSysTabela.nomeObj.toLowerCase();
        //console.log(this.objSysTabela);
        this.linkForm = `/${this.auth.menuItemSelecionado.menuAcesso.moduloWeb}/forms/form${recurso}`;
        //console.log(this.linkForm);
        
        this.findFields();
      });
  }

  findAccess() {
    //console.log(this.auth.empresa.id + ' - '+ this.auth.usuarioInfo.grupoId + '-' +  this.auth.menuItemSelecionado.id);
    //console.log(this.auth.usuarioInfo);
    this.apiAcessosGrupo.findByAcessosGrupoIdMenu(this.auth.empresa.id, this.auth.usuarioInfo.grupoId, this.auth.menuItemSelecionado.menuAcesso.id)
      .toPromise()
      .then
      ((res: any) => {
        this.acessosGrupo = res;
        this.createItemsButtonFuctionsItems();
        //console.log(this.acessosGrupo);
      });
  }

  findFields() {
    this.apiSysFiels.findListDefaultByIdTable(this.obj.idTab)
      .toPromise()
      .then
      ((res: any) => {
        this.listSysCampo = res;

        this.cols = this.convertFieldsToColsTable(this.listSysCampo);
        this.convertFieldsToColsFilter();

        this.filtroExtraSel = [];
        this.filtrosExtra.map((c: SysCampo) => {
          if ((c.prefixo) && (c.prefixo != 'CALCULADO') && (c.opcoes)) {
            c.listaItens = c.opcoes.split(';');
            this.filtroExtraSel.push();
          }
        });

        if (this.objSysTabela.pesqAuto == 'SIM') {
            this.find();
        }
      });
  }

  convertFieldsToColsTable(listSysCampoTemp: SysCampo[]) {
    return listSysCampoTemp.map(c => {
        const idField = c.id;
        let label = this.constantsAero.PREFIX_FIELD + idField;
        let fieldName = c.nomeProp;  // Nome padrão é `nomeProp`

        // Verifica se o tipo é 'Obj' ou 'Rel', e então usa `nomePropVisao`
        if (c.tipoWeb === 'Obj' || c.tipoWeb === 'Rel') {
            fieldName = c.nomePropVisao ? c.nomePropVisao : c.nomeProp;
        }

        if (c.nomeProp === 'dataCad') {
            label = this.translocoService.translate('field_default_dateIn');
        }
        if (c.nomeProp === 'usuarioCad') {
            label = this.translocoService.translate('field_default_user');
        }
        if (c.nomeProp === 'empresaCad') {
            label = this.translocoService.translate('field_company');
        }

        return {
            field: fieldName,  // Usa o nome do campo ajustado
            header: label,
            width: c.tamHorzWeb + '%',
            type: c.tipoWeb,
            fieldId: c.nomePropId,
            fieldView: c.nomePropVisao,
            widthList: c.tamHorz
        };
    });
  }

  convertFieldsToColsFilter() {
    let i = 0;    
    this.colsFilter = [];
    this.colsDataFilter = [];
    this.filtrosExtra = [];

    let label = this.translocoService.translate('all_items');
    this.colsFilter.push({ 'value': '*', 'label': label});
    
    label = this.translocoService.translate('bar_code');
    this.colsFilter.push({ 'value': 'id', 'label': label});

    this.colsDataFilter.push({ 'value': '', 'label': '-'});

    return this.listSysCampo.map(c => {
      const idField = c.id;
      label = this.translocoService.translate(this.constantsAero.PREFIX_FIELD + idField);
      if (c.prefixo != 'CALCULADO') {
        if (c.tipoWeb == 'Data') {
          if (c.nomeProp == 'dataCad') {
            label = this.translocoService.translate('field_default_dateIn');
          }
          this.colsDataFilter.push({ 'value': c.nomeProp, 'label': label});
          i++;
          if (i == 1) {
            this.dadosPesquisa.dataInicial.setDate(new Date().getDate() - c.intervaloDataInicio);
            this.dadosPesquisa.dataFinal.setDate(new Date().getDate() + c.intervaloDataTermino);
            this.dadosPesquisa.campoData = c.nomeProp;
          }
        }
        else {
          if (c.nomeProp == 'usuarioCad') {
            label = this.translocoService.translate('field_default_user');
          }
          if (c.nomeProp == 'empresaCad') {
            label = this.translocoService.translate('field_company');
          }
          this.colsFilter.push({ 'value': c.nomeProp, 'label': label});
        }
      }
      if ((c.tipoWeb != 'Data') && (c.tipoWeb != 'Data') && ((c.tipoWeb == 'ComboFixo') /*|| (c.prefixo == 'CALCULADO')*/)) {
        this.filtrosExtra.push(c);
      }
    })    
  }

  delete(obj: T) {
    const mens = this.translocoService.translate('mens_delete_pre_confirm') + this.title;
    const header = this.translocoService.translate('mens_confirm');
    const btYes = this.translocoService.translate('yes');
    const btNo = this.translocoService.translate('no');
    const mensConfirm = this.title + this.translocoService.translate('mens_delete_pos_confirm');

    this.confirmationService.confirm({
      message: mens,
      header: header,
      icon: 'pi pi-question-circle',
      acceptLabel: btYes,
      rejectLabel: btNo,
      accept: () => {
        this.objSel = obj;
        this.api.delete(this.auth.empresa.id, this.objSel.id)
          .toPromise()
          .then((res: any) => {
            this.find();
            this.messageService.add({ severity: 'success', summary: mensConfirm, detail: '' });
          })
          .catch((res: any) => {
            //console.log(res)
            if ((!res) || (res.status === 400)) {
              this.errorHandler.handle(this.translocoService.translate('message_delete_failure') + ' ' + this.title);
            }
            else {
              this.errorHandler.handle(res);      
            }
          })
      },
      reject: () => {
      }
    });
  }

  updateParcial(propriedade: string, valor: string, idRegra: number) {
    const mens = this.translocoService.translate('mens_update_pre_confirm') + this.title;
    const header = this.translocoService.translate('mens_confirm');
    const btYes = this.translocoService.translate('yes');
    const btNo = this.translocoService.translate('no');
  
    if (this.verifySelRecord(false)) {
      this.confirmationService.confirm({
        message: mens,
        header: header,
        icon: 'pi pi-question-circle',
        acceptLabel: btYes,
        rejectLabel: btNo,
        accept: () => {
          // Chama o serviço para fazer a atualização parcial
          this.api.updateParcial(this.auth.empresa.id, this.objSel.id, propriedade, valor, idRegra)
            .toPromise()  // Converte Observable para Promise
            .then(res => {
              const message = this.translocoService.translate('message_update_sucess');
              this.messageService.add({ severity: 'success', summary: `${this.title} ${message}`, detail: '' });
              if (this.list.length > 0) {
                this.find(); // Atualiza a lista após a alteração
              }
            })
            .catch(error => {
              if ((!error) || (error.status === 400)) {
                this.errorHandler.handle(this.translocoService.translate('message_update_failure') + ' ' + this.title);
              } else {
                this.errorHandler.handle(error);
              }
            });
        },
        reject: () => {
          // Caso o usuário rejeite a confirmação
        }
      });
    }
  }
  

  verifySelRecord(mult: boolean): boolean {
    if (mult) {
      if ((this.listSel) && (this.listSel.length > 0)) {
        return true;
      } else {
        this.errorHandler.handle(this.translocoService.translate('message_list_sel_failure'));
        return false;
      }
    }
    else {
      if (this.multCheck) {
        this.errorHandler.handle(this.translocoService.translate('message_list_sel_1_only'));
        return false;
      }
      else {
        if ((this.objSel == undefined) || (this.objSel.id == 0)) {
          this.errorHandler.handle(this.translocoService.translate('message_list_sel_1_failure'));
          return false;
        } else {
          return true;
        }
      }
    }
  }

  exportPdf() {
    /*
    const doc = new jsPDF();
    this.colsExport = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    doc.autoTable(this.colsExport, this.list);
    doc.save(this.translocoService.translate('menu_function_export_title_file') + this.title + '.pdf');*/
    this.api.generateReport(this.auth.empresa.id)
    .then((relatorio: any) => {
      const url = window.URL.createObjectURL(relatorio);
      window.open(url);
    });
  }

  exportCSVLocal(dataTable) {
    this.colsExport = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    //this.grid.exportFilename = 'teste'//this.translocoService.translate('menu_function_export_title_file') + this.title;
    //this.grid.csvSeparator = Constants.SEPAR_CSV; // Constat Global
    this.dt.exportCSV();
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.list);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.title);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  openNewForm() {
    //console.log([`${this.linkForm}`]);
    this.router.navigate([`${this.linkForm}`]);
  }

  openForm(obj: T, action: string) {
    this.objSel = obj;
    //console.log(this.linkForm);
    this.router.navigate([`${this.linkForm}/`], { queryParams: { id: this.objSel.id, action: action } });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clear(table: Table) {
      table.clear();
      this.filter.nativeElement.value = '';
  }

  getStyleBagde(valor) {
    if (valor == 0) {
      return 'success' 
    }
    else {
      return 'danger' 
    }
  }

  onRowSelectObj(event) {
    if (!this.multCheck && event?.data) { // Verifica se event e data existem
        this.objSel = event.data;
    }
  }

  selectObj(rowData: any) {
    if (rowData) {  // Verifica se rowData não é null ou undefined
        this.objSel = rowData;
        this.listSel = [rowData];
    }
  }

getNestedPropertyValue(obj: any, path: string): any {
    if (!obj || !path) return undefined;

    if (obj.hasOwnProperty(path)) {
        return obj[path];
    }

    const parts = path.split('.');
    let result = obj;

    for (let i = 0; i < parts.length; i++) {
        if (result[parts[i]] === undefined) {
            const keys = Object.keys(result).filter(key => key.endsWith('.' + parts[i]));
            if (keys.length > 0) {
                result = result[keys[0].split('.')[0]];
            } else {
                return undefined;
            }
        } else {
            result = result[parts[i]];
        }

        if (result === undefined) {
            return undefined;
        }
    }

    return result;
}


  changeTypeSelection() {
    this.multCheck = !this.multCheck;
    if ((this.multCheck) && (!Array.isArray(this.listSel))){
      this.listSel = [];
    }
    if ((!this.multCheck) && (this.objSel != undefined)) {
      this.objSel.id = 0;
    }
  }

  showDialogDef(component: any, data: any, titulo: string, fn: any, width ='100%', height ='100%') {
    this.ref = this.dialogService.open(component, {
        header: titulo,
        //style: {width: '85vw'},
        width: width,
        height: height,
        contentStyle: { overflow: 'auto' },
        //baseZIndex: 1000,
        maximizable: true,
        draggable: true,
        modal: true,
        data: data
      });
    
      this.ref.onClose.subscribe((ok: boolean) => {
        if ((fn) && ok) {
          fn();
        }
        //this.find();
      });
  }  

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  keyInputFilter(event) {
    //console.log("You entered: ", event.target.value);
  }  

  action1() {
    //console.log('Inicial');
  }

  getGenericColor(valor: any): string | undefined {
    if (valor === 'FATURADO' || valor === 'Faturado') return '#3e9dc1';
    if (valor === 'FINALIZADO' || valor === 'Finalizado') return '#bbff9d';
    if (valor === 'COMPRADO' || valor === 'Comprado') return '#bbff9d';
    if (valor === 'PRONTO' || valor === 'Pronto') return '#0a8c0a';
    if (valor === 'AUTORIZADO' || valor === 'Autorizado') return '#ffff80';
    if (valor === 'ESTOQUE' || valor === 'Estoque') return '#ced7ff';
    if (valor === 'ENTREGUE' || valor === 'Entregue') return '#ced7ff';
    if (valor === 'CANCELADO' || valor === 'Cancelado') return '#fec58b';
    if (valor === 'IMPORTAÇÃO' || valor === 'Importação') return '#8080c0';
    if (valor === 'PARCIAL' || valor === 'Parcial') return '#8080c0';
    if (valor === 'BACKORDERS' || valor === 'BackOrders') return '#8eb0d7';
    if (valor === 'DESPESA' || valor === 'Despesa') return '#fec58b';
    if (valor === 'AOG' || valor === 'Despesa') return '#fec58b';
    if (valor === 'TRANSFERÊNCIA' || valor === 'Transferência') return '#3e9dc1';
    if (valor === 'SEPARACAO' || valor === 'Separacao') return '#a6a6a6';
    if (valor === 'SEPARACAO PARCIAL' || valor === 'Separacao Parcial') return '#b0c8e5';
    if (valor === 'SEPARACAO TOTAL' || valor === 'Separacao Total') return '#c0d693';
    if (valor === 'S' || valor === 's') return '#bbff9d';
    if (valor === 'C' || valor === 'c') return '#fec58b';
    return undefined;
  }
}