import { Component, Injector, OnInit } from '@angular/core';
import { Departamento } from 'src/app/model/geral/departamento';
import { DepartamentoService } from 'src/app/services/geral/departamento.service';
import { GeralListaModule } from './gerallista.module';
import { ListaSimplesGenerica } from '../../generic/listagenerica/listasimplesgenerica';

@Component({
  selector: 'app-lista-departamento',
  templateUrl: '../../generic/listagenerica/listasimples.component.html',
  styleUrls: [ '../../generic/listagenerica/listasimples.component.css']
})

export class ListaDepartamentoComponent extends ListaSimplesGenerica<Departamento> implements OnInit {

  override obj = new Departamento();

  constructor(
    injectorObj: Injector,
    api: DepartamentoService) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override dataExtraList(res: any) {
}

  override getColorCell(valor: any) {   
  }

}
