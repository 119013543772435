import { Empresa } from './../../model/empresa/empresa';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends GenericService<Empresa>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("empresa")
  }

  // Método para listar as empresas com base no id da empresa
  listarEmpresasPorEmpresa(idEmpresa: number): Observable<Empresa[]> {
    const url = `${this.url}/todas/${idEmpresa}`;
    return this.http.get<Empresa[]>(url);
  }
}
