import { FiscalInvoice } from './../../model/fiscal/fiscalInvoice';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FiscalInvoiceService extends GenericService<FiscalInvoice>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("fiscalinvoice")
  }

  findFilterStatus(idCompany: number, status: string): Observable<FiscalInvoice[]> {
    const urlLocal = `${this.url}/status/${idCompany}/${status}`;
    return this.http.get<FiscalInvoice[]>(urlLocal);
  }

  updateFreight(id: number, obj: FiscalInvoice): Observable<FiscalInvoice> {
    const urlLocal = `${this.url}/freightawb/${id}`;
    return this.http.patch<FiscalInvoice>(urlLocal, obj, this.httpOptions);
  }
}
