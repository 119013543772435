import { Departamento } from '../geral/departamento';
import { PesDadosPf } from './pesDadosPf';

export class Funcionario extends PesDadosPf {

    departamento: Departamento = new Departamento();
    cntp: string;
    pis: string;
    cnh: string;
    tituloEleitor: string;
    cartReserv: string;
    dataAdm: Date;
    dataDem: Date;
    foto: string;
    salario: number;
    percComis: number;
    percComisGlobal: number;
    tipoComissao: string;
    percDesconto: string;
    comissionado: string;
    statusFunc: string;
    codAnac: string;
    licAnac: string;
    crea: string;
    emailServSmtp: string;
    emailPortaSmtp: number;
    emailSsl: string;
    emailSenha: string;
    funcaoInspecao: string;
    corpoTecnico: string;
    assinatura: string;
    cft: string;
    apelido: string;
    emailAss: string;
    percAcrescimo: number;
}